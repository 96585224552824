import createPdf from "./createPdf.js"
//import ImageWithBasePath from "../../core/img/imagewithbasebath.jsx"

const generateTicket = async (output) => {
    const content = [
        //Datos de la empresa
        // {
        //     //Colocar imagen predeterminada de la pape, actualmente es una por defecto de la libreria
        //     //image:'./../../public/assets/img/login-images/lapape-logo.png',
        //     fit: [141.73, 56.692],
        //     alignment: 'center', 
        // },
        { text:'THORO ENTERPRISES DE MÉXICO', style: 'header', margin: [0, 10, 0, 0]},
        { text:'LA PAPE', style: 'header'},
        { text: 'Av. México Japón 412, Novopark Bodega 18', style: 'header' },
        { text: 'EIN 11603314323', style: 'header' },

        //Tipo y numero de documento
        // { text: 'FACTURA ELECTRÓNICA', style: 'header', margin: [0, 10, 0, 2.25] },
        // { text: 'F001-000001', style: 'header', margin: [0, 2.25, 0, 0] },

        //Datos de cabecera y facturacion 
        {
            margin: [0, 10, 0, 0],
            table: {
              widths: ['25%', '35%', '15%', '25%'],
              body: [
                [
                  { text: 'FECHA:', style: 'tHeaderLabel' },
                  { text: '(fecha)', style: 'tHeaderValue' },
                  { text: 'HORA:', style: 'tHeaderLabel' },
                  { text: '(hora)', style: 'tHeaderValue' },
                ],
                [
                  { text: 'PEDIDO:', style: 'tHeaderLabel' },
                  { text: 'V001-000001', style: 'tHeaderValue', colSpan: 3 },
                  {},
                  {},
                ],
                [
                  { text: 'CAJERO:', style: 'tHeaderLabel' },
                  { text: 'THPOS-01', style: 'tHeaderValue', colSpan: 3 },
                  {},
                  {},
                ],
                [
                  { text: 'VENDEDOR:', style: 'tHeaderLabel' },
                  { text: 'Nombre Apellidos', style: 'tHeaderValue', colSpan: 3 },
                  {},
                  {},
                ],
              ],
            },
            layout: 'noBorders',
          },
        //Tabla de productos
        {
            margin: [0, 10, 0, 0],
            table: {
                widths:['20%', '20%', '30%','30%'],
                headerRows: 2,
                body: [
                    [
                        {text: 'Código - Descripción',
                         colSpan: 4,
                         style: 'tProductsHeader',
                        },
                        {},
                        {},
                        {},
                    ],
                    [
                        { text: 'CANT.', style: 'tProductsHeader' },
                        { text: 'UM', style: 'tProductsHeader', alignment: 'center' },
                        { text: 'PRECIO', style: 'tProductsHeader', alignment: 'right' },
                        { text: 'TOTAL', style: 'tProductsHeader', alignment: 'right' },
                    ],
                    [
                        {
                            text: 'PLK180024 - Pelikano Mel',
                            style: 'tProductsBody',
                            colSpan: 4,
                        },
                        {},
                        {},
                        {},
                    ],
                    [
                        { text: '0.50', style: 'tProductsBody', alignment: 'center' },
                        { text: 'UND', style: 'tProductsBody', alignment: 'center' },
                        { text: '295.00', style: 'tProductsBody', alignment: 'right' },
                        { text: '147.50', style: 'tProductsBody', alignment: 'right' },
                    ],
                    [
                        {
                          text: 'CANTOBELLOT01 - Canto Bellota',
                          style: 'tProductsBody',
                          colSpan: 4,
                        },
                        {},
                        {},
                        {},
                      ],
                      [
                        { text: '40', style: 'tProductsBody', alignment: 'center' },
                        { text: 'UND', style: 'tProductsBody', alignment: 'center' },
                        { text: '0.90', style: 'tProductsBody', alignment: 'right' },
                        { text: '36.00', style: 'tProductsBody', alignment: 'right' },
                      ],
                      [
                        {
                          text: 'CANTOBELLOT01 - Canto Bellota',
                          style: 'tProductsBody',
                          colSpan: 4,
                        },
                        {},
                        {},
                        {},
                      ],
                      [
                        { text: '40', style: 'tProductsBody', alignment: 'center' },
                        { text: 'UND', style: 'tProductsBody', alignment: 'center' },
                        { text: '0.90', style: 'tProductsBody', alignment: 'right' },
                        { text: '36.00', style: 'tProductsBody', alignment: 'right' },
                      ],
                ],
            },
            layout: {
                hLineWidth: function (i) {
                    return i === 2 ? 0.5 : 0
                },
                vLineWidth: function () {
                    return 0;
                },
                hLineColor: function () {
                    return '#f2f0f0';
                },
                paddingTop: function (i) {
                    return i % 2 === 0 ? 10 : 1;
                },
            },
        },
        {
            margin: [0, 10, 0, 0],
            table: {
              widths: ['25%', '35%', '15%', '25%'],
              body: [
                //TOTALES
                [
                  { text: 'SUBTOTAL: S/', style: 'tTotals', colSpan: 2 },
                  {},
                  { text: '538.14', style: 'tTotals', colSpan: 2 },
                  {},
                ],
                [
                  { text: 'I.G.V: S/', style: 'tTotals', colSpan: 2 },
                  {},
                  { text: '96.86', style: 'tTotals', colSpan: 2 },
                  {},
                ],
                [
                  { text: 'TOTAL: S/', style: 'tTotals', colSpan: 2 },
                  {},
                  { text: '635.00', style: 'tTotals', colSpan: 2 },
                  {},
                ],
                //TOTAL IMPORTE EN LETRAS
                [
                  {
                    text: 'IMPORTE EN LETRAS:',
                    style: 'tTotals',
                    alignment: 'left',
                    colSpan: 4,
                    margin: [0, 4, 0, 0],
                  },
                  {},
                  {},
                  {},
                ],
                [
                  {
                    text: 'SON: SEISCIENTOS TREINTA MIL QUINIENTOS CINCO Y CINCO',
                    style: 'tProductsBody',
                    colSpan: 4,
                  },
                  {},
                  {},
                  {},
                ],
                //FORMAS PAGO
                [
                  {
                    text: 'FORMA DE PAGO:',
                    style: 'tTotals',
                    alignment: 'left',
                    colSpan: 4,
                    margin: [0, 4, 0, 0],
                  },
                  {},
                  {},
                  {},
                ],
                [{ text: 'CONTADO', style: 'tProductsBody', colSpan: 4 }, {}, {}, {}],
                [
                  { text: 'EFECTIVO: S/', style: 'tTotals', colSpan: 2 },
                  {},
                  { text: '635.00', style: 'tTotals', colSpan: 2 },
                  {},
                ],
                [
                  { text: 'VISA: S/', style: 'tTotals', colSpan: 2 },
                  {},
                  { text: '635.00', style: 'tTotals', colSpan: 2 },
                  {},
                ],
              ],
            },
            layout: 'noBorders',
          },
        //NOTA DE PIE
    {
        text: 'ESTIMADO CLIENTE, TIENE COMO PLAZO MAXIMO DE 5 DIAS HABILES EN RECOGER SU MERCADERÍA, DICHO ESTO SE LE COBRARÍA PENALIDAD DE ALMACEN POR EL MONTO DE S/20.00 POR DIA, GRACIAS.',
        style: 'text',
        alignment: 'justify',
        margin: [0, 5],
      },
      // //QR FACTURA
      // {
      //   stack: [
      //     {
      //       qr: '20603831404|03|B002|000131|724.94|4,752.30|30/09/2023|1|70477554|v2Ez4sKStje4NiqcXiuTcmTtPwgbrqgnXpWPltJKEhk=|',
      //       fit: 115,
      //       alignment: 'center',
      //       eccLevel: 'Q',
      //       margin: [0, 10, 0, 3],
      //     },
      //     {
      //       text: 'Representación impresa del comprobante original. Consulta tu comprobante aquí:',
      //       style: 'text',
      //     },
      //     {
      //       text: 'https://x.microsoft.pse.pe/cpe/ace72300-0dfb-42d2-9ed7-0ba6e3cee01f',
      //       link: 'https://x.microsoft.pse.pe/cpe/ace72300-0dfb-42d2-9ed7-0ba6e3cee01f',
      //       style: 'link',
      //     },
      //   ],
      // },
    ]

    const response = await createPdf({ content }, output)
    return response
}

export default generateTicket