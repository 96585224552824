import React from 'react'
import { Link } from 'react-router-dom'
import ImageWithBasePath from '../../../core/img/imagewithbasebath'
import { apiUrl } from '../../../utils/var'

const columns = (
	handleDecrease,
	handleIncrease,
	handleRemoveItem
	/* handleQuantity */
	/* handleBlur */
) => [
	{
		title: '#',
		dataIndex: 'LineNum', // Ya no es necesario
		sorter: (a, b) => a.index - b.index, // Ahora usarás el índice
		render: (
			text,
			record,
			index // index es el índice de la fila
		) => (
			<span>{index + 1}</span> // Para obtener un número consecutivo (1, 2, 3, ...)
		),
	},
	{
		title: 'Imagen',
		dataIndex: '',
		render: (_, record) => (
			<ImageWithBasePath
				url={`${apiUrl}/proxy/empleados/imagenes/ITEMS/${record.PicturName}`}
				width={450}
				alt='product'
			/>
		),
	},
	{
		title: 'Numero de parte',
		dataIndex: 'ItemCode',
		render: (text) => <span className='badge badge-linesuccess'>{text}</span>,
		sorter: (a, b) => a.ItemCode.localeCompare(b.ItemCode),
	},
	{
		title: 'Descripcion',
		dataIndex: 'ItemName',
		sorter: (a, b) => a.ItemName.localeCompare(b.ItemName),
		render: (text) => (
			<div>
				<span>{text}</span>
			</div>
		),
	},
	{
		title: 'Codigo de barras',
		dataIndex: 'CodeBars',
		sorter: (a, b) => a.CodeBars.localeCompare(b.CodeBars),
	},
	{
		title: 'Cantidad',
		dataIndex: 'quantity',
		sorter: (a, b) => a.Quantity - b.Quantity,
		render: (_, record) => (
			<div style={{ display: 'flex', alignItems: 'center', width: '10rem' }}>
				<button
					type='button'
					onClick={() => handleDecrease(record)}
					className='btn btn-secondary me-2'>
					-
				</button>

				<input
					type='number'
					min='0'
					value={record.quantity}
					/* onChange={(e) => handleQuantity(e.target.value, record)} */
					style={{
						width: '50px',
						textAlign: 'center',
						marginRight: '5px',
					}}
				/>

				{/* <input
					type='text'
					value={record.quantity}
					onBlur={() => {
						handleBlur(record.quantity, record.ItemCode)
					}}
					onChange={(event) => {
						handleQuantity(event.target.value, record)
					}}
					onInput={(event) => {
						const value = event.target.value
						if (!/^\d*$/.test(value)) {
							event.target.value = value.slice(0, -1)
						}
					}}
					className='fw-bold text-center'
					style={{
						width: '5rem',
						WebkitAppearance: 'none',
						MozAppearance: 'textfield',
						appearance: 'none',
						padding: '0',
						border: 'none',
						fontSize: 20 + 'px',
					}}
				/> */}

				<button
					type='button'
					onClick={() => handleIncrease(record)}
					className='btn btn-primary me-2'>
					+
				</button>
			</div>
		),
	},
	{
		title: 'Precio unitario',
		dataIndex: 'unitPrice',
		key: 'unitPrice',
		render: (_, record) => {
			const prices = record.prices

			if (prices.priceWithDiscount) {
				if (prices.priceLiquidation) {
					return (
						<div className='d-flex flex-column gap-2'>
							<span className='text-decoration-line-through'>
								{prices.priceLiquidation}
							</span>
							<span>{prices.priceWithDiscount}</span>
						</div>
					)
				} else {
					return (
						<div className='d-flex flex-column gap-2'>
							<span className='text-decoration-line-through'>
								{prices.price}
							</span>
							<span>{prices.priceWithDiscount}</span>
						</div>
					)
				}
			} else if (prices.priceLiquidation) {
				return (
					<div className='d-flex flex-column gap-2'>
						<span className='text-decoration-line-through'>{prices.price}</span>
						<span>{prices.priceLiquidation}</span>
					</div>
				)
			} else {
				return <span>{prices.price}</span>
			}
		},
	},
	{
		title: 'Total',
		dataIndex: 'totalByProduct',
		key: 'totalByProduct',
		sorter: (a, b) => a.LineTotal - b.LineTotal,
	},
	{
		title: 'Acciones',
		dataIndex: '',
		render: (_, record) => (
			<div className='edit-delete-action'>
				<Link
					className='confirm-text p-2'
					to='#'
					onClick={() => handleRemoveItem(record)} // Cambié LineNum por ItemCode
				>
					<i data-feather='trash-2' className='feather-trash-2' />
				</Link>
			</div>
		),
	},
]

export default columns
