import React from 'react'
import columns from '../utils/TableSaleModal'
import { formatCurrency } from '../../../utils/functions/money'
import { selectItems } from '../redux/selectors/new-sale'
import { useDispatch, useSelector } from 'react-redux'
import {
	addItem,
	decreaseItem,
	removeItem,
	setNumericValue,
} from '../redux/new-sale'
import { Table } from 'antd'

export default function SaleProducts() {
	const dispatch = useDispatch()
	const items = useSelector(selectItems)

	const handleIncrease = (record) => {
		dispatch(addItem(record.ItemCode))
	}

	const handleDecrease = (record) => {
		dispatch(decreaseItem(record.ItemCode))
	}

	const handleRemoveItem = (record) => [dispatch(removeItem(record.ItemCode))]

	const handleQuantity = (value, record) => {
		console.log(value)

		const parsedValue = value === '' ? '' : parseInt(value, 10)

		if (parsedValue > 0 && parsedValue <= record.OnHand) {
			dispatch(setNumericValue({ ItemCode: record.ItemCode, quantity: value }))
		}
	}

	const handleBlur = (quantity, itemCode) => {
		if (quantity === '') {
			dispatch(removeItem(itemCode))
		}
	}

	return (
		<div className='col-lg-12'>
			<div className='modal-body-table'>
				<div className='table-responsive'>
					<Table
						bordered={true}
						columns={columns(
							handleDecrease,
							handleIncrease,
							handleRemoveItem,
							handleQuantity,
							handleBlur
						)}
						dataSource={items.map((item) => {
							return {
								...item,
								prices: {
									priceLiquidation: item.AvgPriceLiquidation
										? formatCurrency(item.AvgPriceLiquidation)
										: null,
									priceWithDiscount: item.couponPrice
										? formatCurrency(item.couponPrice)
										: null,
									price: formatCurrency(item.AvgPrice),
								},
								totalByProduct: formatCurrency(
									item.quantity *
										(item.couponPrice
											? item.couponPrice
											: item.AvgPriceLiquidation
											? item.AvgPriceLiquidation
											: item.AvgPrice)
								),
							}
						})} // Mostrar los datos en la tabla
						loading={false}
						rowKey={'ItemCode'}
						pagination={false}
					/>
				</div>
			</div>
		</div>
	)
}
