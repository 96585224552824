import React from 'react'
import { PropTypes } from 'prop-types'
import CashCountInfoSales from './CashCountInfoSales'
import { useDispatch, useSelector } from 'react-redux'
import { getNextWithdraw } from '../../../redux/withdraw'
import { selectIsLoading } from '../../../redux/selectors/withdraw'

export default function CashCountInfo({
	nameManager,
	nameEmployee,
	date,
	time,
	id,
}) {
	const isLoadingNextWithdraw = useSelector(selectIsLoading)
	const dispatch = useDispatch()

	return (
		<>
			<div className='col-xl-3'>
				<div className='card'>
					<div className='card-header justify-content-between'>
						<div className='card-title'>Corte: #{id}</div>
					</div>
					<div className='card-body'>
						<dl className='row mb-0'>
							<dt className='col-sm-4'>Supervisor</dt>
							<dd className='col-sm-8'>{nameManager}</dd>
							<dt className='col-sm-4'>Cajero</dt>
							<dd className='col-sm-8'>
								<p>{nameEmployee}</p>
							</dd>
							<dt className='col-sm-4'>Hora de apertura</dt>
							<dd className='col-sm-8'>{`${date} ${time}`}</dd>
							<dt
								className='col-sm-4 mt-2'
								style={{
									hyphens: 'auto',
									wordWrap: 'break-word',
									overflowWrap: 'break-word',
								}}
								lang='es'>
								Dispositivo
							</dt>
							<dd className='col-sm-8'>
								{JSON.parse(localStorage.getItem('device')).label}
							</dd>
						</dl>
					</div>
					<div
						className='d-flex align-items-center justify-content-end'
						style={{ marginBottom: '10px', marginRight: '10px' }}>
						<button
							className='btn btn-primary add-em-payroll'
							type='button'
							disabled={isLoadingNextWithdraw}
							onClick={() => {
								dispatch(getNextWithdraw())
							}}>
							Realizar corte de caja
							{isLoadingNextWithdraw && (
								<div
									className='spinner-border spinner-border-sm ms-2'
									role='status'>
									<span className='sr-only'>Loading...</span>
								</div>
							)}
						</button>
					</div>
				</div>
			</div>
			<CashCountInfoSales id={id}/>
		</>
	)
}

CashCountInfo.propTypes = {
	nameManager: PropTypes.string,
	nameEmployee: PropTypes.string,
	date: PropTypes.string,
	time: PropTypes.string,
	id: PropTypes.string,
}
