import React, { useEffect, useState } from 'react'
import { Form, Col } from 'react-bootstrap'
import { Select } from 'antd'
import { apiUrl } from '../../../../utils/var'
import { idDevice } from '../../../../utils/var'
import { PropTypes } from 'prop-types'

SelectDevice.propTypes = {
	changeDevice: PropTypes.func,
	changeDevices: PropTypes.func,
}

export default function SelectDevice({ changeDevice, changeDevices }) {
	const [device, setDevice] = useState(idDevice())
	const [devices, setDevices] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState(null)

	const getDevices = async () => {
		setIsLoading(true)
		const getdevicesRequest = await fetch(`${apiUrl}/api/devices`, {
			method: 'GET',
			credentials: 'include',
			headers: {
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
			},
		})
		const getDevicesResponse = await getdevicesRequest.json()

		if (!getdevicesRequest.ok) {
			setError(getDevicesResponse.message)
		} else {
			setDevices(getDevicesResponse.data)
			setError(null)
		}
		setIsLoading(false)
	}

	const handleChangeDevice = (value) => {
		setDevice(value)
	}

	useEffect(() => {
		getDevices()
	}, [])

	useEffect(() => {
		changeDevice(device)
	}, [device])

	useEffect(() => {
		if (changeDevices) {
			changeDevices(devices)
		}
	}, [devices])

	return (
		<Form.Group as={Col}>
			<Form.Label className='form-label pe-3'>
				<span>*</span> Dispositivo:
			</Form.Label>
			{isLoading ? (
				<p>Cargando...</p>
			) : error ? (
				<p>
					Un error ha ocurrido de nuevo, recargue la pagina o contacte a
					soporte. {error}
				</p>
			) : devices && devices.length > 0 ? (
				<Select
					showSearch
					placeholder='Selecciona un dispositivo'
					optionFilterProp='label'
					options={devices}
					style={{ width: '40%' }}
					value={device}
					onChange={handleChangeDevice}
					key={'SELECT_DEVICE'}
				/>
			) : (
				<></>
			)}
			<Form.Control.Feedback type='invalid'>
				Campo obligatorio
			</Form.Control.Feedback>
		</Form.Group>
	)
}
