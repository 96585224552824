import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { DatePicker, Space } from 'antd';
const { RangePicker } = DatePicker;
import { Filter as FilterRC, } from "react-feather";
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

const Filter = () => {
    const fromDate = dayjs().subtract(15, 'day').format('YYYY-MM-DD')
    const todayDate = dayjs().format('YYYY-MM-DD');
    const [dateRange, setDateRange] = useState([dayjs(fromDate), dayjs(todayDate)]);
    const searchDatable = async () => {
        console.log(dateRange[0].format('YYYY-MM-DD'), dateRange[1].format('YYYY-MM-DD'))
    }

    const onChange = (dates) => {
        setDateRange(dates); // Guardamos el valor en el estado
    };

    return (
        <div className="table-top">
            <div className="search-set">
                <div className="search-input">
                    <input
                        type="text"
                        placeholder="Search"
                        className="form-control form-control-sm formsearch"
                        aria-controls="DataTables_Table_0"
                    />
                    <Link to className="btn btn-searchset">
                        <i data-feather="search" className="feather-search" />
                    </Link>
                </div>
            </div>
            <div className="search-path" onClick={searchDatable}>
                <Link
                    className={`btn btn-filter`}
                    id="filter_search"
                >
                    <FilterRC
                        className="filter-icon"
                    />
                </Link>
            </div>
            <div className="form-sort stylewidth" style={{ width: 280 }}>
                <Space direction="vertical" size={12}>
                    <RangePicker
                        defaultValue={[dayjs(fromDate, 'YYYY-MM-DD'), dayjs(todayDate, 'YYYY-MM-DD')]}
                        allowClear={false}
                        onChange={onChange}
                    />
                </Space>
            </div>
        </div>
    );
};

Filter.propTypes = {

};

export default Filter;