import React from 'react'
import { useSelector } from 'react-redux'
import { selectCart } from '../../../redux/selectors/cart'
import { List } from 'antd'
import OrderListClientProduct from '../components/OrderListClientProduct'

export default function OrderListClient() {
	const cart = useSelector(selectCart)

	return (
		<div className='w-100'>
			<List
				dataSource={cart}
				renderItem={(product) => <OrderListClientProduct product={product} />}
			/>
		</div>
	)
}
