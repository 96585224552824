import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import {
	selectOrderError,
	selectOrderIsLoadingOrderClientView,
	selectOrderIsShowModalOrderClientView,
	selectOrderStatus,
	selectOrderIsShowModalOrderInformation,
	selectOrderCash,
	selectOrderExchange,
} from '../../../redux/selectors/order'
import { useSelector, useDispatch } from 'react-redux'
import {
	AlertOctagon,
	Check,
	Info,
} from 'feather-icons-react/build/IconComponents'
import { hideModalOrderClientView } from '../../../redux/order'
import { formatCurrency } from '../../../utils/functions/money'
import { changeCash, changeExchange } from '../../../redux/order'

export default function ModalOrderClient() {
	const dispatch = useDispatch()
	const cash = useSelector(selectOrderCash)
	const exchange = useSelector(selectOrderExchange)
	const orderStatus = useSelector(selectOrderStatus)
	const error = useSelector(selectOrderError)
	const isLoadingOrderClientView = useSelector(
		selectOrderIsLoadingOrderClientView
	)
	const isShowModalOrderClientView = useSelector(
		selectOrderIsShowModalOrderClientView
	)
	const isShowModalOrderInformation = useSelector(
		selectOrderIsShowModalOrderInformation
	)

	useEffect(() => {
		if (
			orderStatus === 'cancelled' ||
			(!isShowModalOrderInformation &&
				(orderStatus === 'error' || orderStatus === 'complete'))
		) {
			dispatch(hideModalOrderClientView())
			dispatch(changeCash(''))
			dispatch(changeExchange(''))
		}
	}, [orderStatus, isShowModalOrderInformation])

	return (
		<Modal size='sm' centered show={isShowModalOrderClientView}>
			<Modal.Header>
				{isLoadingOrderClientView ? (
					<Info />
				) : error ? (
					<AlertOctagon />
				) : (
					<Check />
				)}
				<Modal.Title>
					{isLoadingOrderClientView
						? 'Estado de orden'
						: error
						? 'Error'
						: 'Correcto'}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{isLoadingOrderClientView ? (
					<p className='d-flex align-items-center'>
						{' '}
						Procesando orden
						<div
							className='spinner-border spinner-border-sm ms-2'
							role='status'>
							<span className='sr-only'>Loading...</span>
						</div>
					</p>
				) : error ? (
					'Ah ocurrido un error al procesar la orden, espere un momento.'
				) : (
					<div className='d-flex flex-column gap-2'>
						<p className='d-flex align-items-center'>
							Orden correctamente procesada
						</p>
						<p>Cantidad de efectivo entregada: {`${formatCurrency(cash)}`}</p>
						<p>Cambio a entregar: {`${formatCurrency(exchange)}`}</p>
					</div>
				)}
			</Modal.Body>
		</Modal>
	)
}
