import { createSelector } from '@reduxjs/toolkit'
import { selectCartTotal } from './cart'

export const selectOrderIsShowModalOrder = (state) =>
	state.order.isShowModalOrder
export const selectOrderOrder = (state) => state.order.order
export const selectPayMethod = (state) => state.order.method
export const selectOrderIsOrdering = (state) => state.order.isOrdering
export const selectOrderIsLoadingOrder = (state) => state.order.isLoadingOrder
export const selectOrderError = (state) => state.order.error
export const selectOrderStatus = (state) => state.order.status
export const selectOrderIsPaying = (state) => state.order.isPaying
export const selectOrderCash = (state) => state.order.cash
export const selectFolio = (state) => state.order.folio
export const selectOrderIsLoadingOrderClientView = (state) =>
	state.order.isLoadingOrderClientView
export const selectOrderIsShowModalOrderClientView = (state) =>
	state.order.isShowModalOrderClientView
export const selectOrderIsShowModalOrderInformation = (state) =>
	state.order.isShowModalOrderInformation
export const selectOrderIsShowModalOrderFolioCardPay = (state) =>
	state.order.isShowModalOrderFolioCardPay
export const selectOrderExchange = createSelector(
	[selectCartTotal, selectOrderCash],
	(total, cash) => {
		const exchange = cash - total
		return exchange < 0 ? 0 : exchange
	}
)
export const selectIsShowModalTicket = (state) => state.order.isShowModalTicket
export const selectIsLoadingNextOrderId = (state) =>
	state.order.selectIsLoadingNextOrderId
export const selectOrderId = (state) => state.order.orderId
export const selectErrorNextOrderId = (state) => state.order.errorNextOrderId
export const selectIsLoadingTerminalPay = (state) =>
	state.order.isLoadingTerminalPay
export const selectOrderPayData = (state) => state.order.payData
export const selectOrderVoucherClient = (state) => state.order.voucherClient
export const selectOrderVoucherBusiness = (state) => state.order.voucherBusiness
