import React, { useContext, useEffect, /* useEffect, */ useState } from 'react'
import DashboardLayout from '../../../layouts/DashboardLayout'
import PageTitle from '../../../layouts/PagesTitle'
import { PinpadContext } from '../../../provider/Pinpad'
import { Button, DatePicker, Flex, Input, Table } from 'antd'
import { tablePinpadConsultations } from '../../../utils/datatablesVar'
import dayjs from 'dayjs'
import { formatDate } from '../../../utils/functions/date'

export default function PinpadMovements() {
	const [referenceSearched, setReferenceSearched] = useState('')
	const [dateSearched, setDateSearched] = useState(
		dayjs(formatDate(new Date()), 'YYYY/MM/DD')
	)
	const { isLoadingMovements, consultation, consultations, getVoucher } =
		useContext(PinpadContext)

	useEffect(() => {
		console.log(isLoadingMovements)
	}, [isLoadingMovements])

	return (
		<DashboardLayout>
			<div className='d-flex justify-content-between align-items-center'>
				<PageTitle
					maintitle='Movimientos en Pinpad'
					subtitle='Registro de todos los movimientos en la pinpad'
				/>
			</div>
			<div className='card table-list-card'>
				<div className='card-body'>
					<div className='table-responsive'>
						<Flex justify='end' gap={20}>
							<Button
								className='me-auto'
								size='middle'
								onClick={() => {
									consultation(new Date(dateSearched), referenceSearched)
								}}>
								Consultar
							</Button>
							<Input
								placeholder='Referencia'
								className='mb-4 w-25'
								value={referenceSearched}
								onChange={(e) => setReferenceSearched(e.target.value)}
							/>
							<DatePicker
								placeholder='Fecha de consulta'
								className='mb-4 w-25'
								onChange={(date) => setDateSearched(date)}
								value={dateSearched}
							/>
						</Flex>
						<Table
							columns={tablePinpadConsultations(getVoucher)}
							dataSource={consultations}
							loading={isLoadingMovements}
							rowKey={(record) => record.nb_referencia}
						/>
					</div>
				</div>
			</div>
		</DashboardLayout>
	)
}
