export const selectCatalog = (state) => state.catalog.contents.items
export const selectIsShowCatalog = (state) =>
	state.catalog.contents.isShowCatalog
export const selectIsShowWarning = (state) =>
	state.catalog.contents.isShowWarning
export const selectIsShowScanedProduct = (state) =>
	state.catalog.contents.isShowScanedProduct
export const selectIsCatalogLoading = (state) => state.catalog.isLoading
export const selectCatalogError = (state) => state.catalog.error
export const selectScannedProduct = (state) =>
	state.catalog.contents.ScannedProduct
export const selectScanCount = (state) => state.catalog.contents.scanCount
export const selectCategories = (state) =>
	state.catalog.contents.categories.contents.items
export const selectCategoriesLoading = (state) =>
	state.catalog.contents.categories.isLoading
export const selectCategoriesError = (state) =>
	state.catalog.contents.categories.error
export const selectFilters = (state) => state.catalog.contents.filter
export const selectCategory = (state) => state.catalog.contents.filter.category
export const selectSorting = (state) => state.catalog.contents.sorting
export const selectSearch = (state) => state.catalog.contents.search
export const selectPagination = (state) => state.catalog.contents.pagination
export const selectIsShowMenu = (state) => state.catalog.contents.isShowMenu
