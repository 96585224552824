import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { PropTypes } from 'prop-types'
import { useSelector } from 'react-redux'
import { selectAuthenticationUser } from '../redux/selectors/authentication'
import { ROUTES } from '../utils/var'
import { verifyUserRole } from '../utils/functions/authorization'

AuthorizationRoute.propTypes = {
	requiredRoles: PropTypes.array,
}

export default function AuthorizationRoute({ requiredRoles }) {
	const user = useSelector(selectAuthenticationUser)

	return verifyUserRole(user, requiredRoles) ? (
		<Outlet />
	) : (
		<Navigate to={ROUTES.LOGIN} replace />
	)
}
