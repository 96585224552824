import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../../../core/img/imagewithbasebath";
import { Link } from "react-router-dom";
import { Filter, Sliders, Box, } from "react-feather";
import Select from "react-select";
import DashboardLayout from "../../../../layouts/DashboardLayout";
import PageTtle from "../../../../layouts/PagesTitle";
import { apiUrl } from '../../../../utils/var'
import { Eye, Trash2 } from "feather-icons-react/build/IconComponents";
import { Table } from "antd";
import Error404 from "../../../../feature-module/pages/errorpages/error404";
import { formatCurrency } from "../../../../utils/functions/money";
import { convertMilitaryTimeToStandardTimeFormat } from "../../../../utils/functions/date";
import ModalDetails from "../../../../components/modalSales";


const OrdersReports = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)
    const [items, setItems] = useState({ items: [] })
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const handleShowModal = (record) => {
        console.log(record)
        setSelectedRecord(record);
        setModalVisible(true);
    };

    const handleCloseModal = () => {
        setModalVisible(false);
        setSelectedRecord(null);
    };
    const getItems = async () => {
        setIsLoading(true)

        const requestItems = await fetch(`${apiUrl}/api/Orders/`, {
            method: 'GET',
            credentials: "include",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            }
        })

        const responseItems = await requestItems.json();
        if (!requestItems.ok) {
            setError(responseItems.message)
        } else {
            setError(null)
            setItems(responseItems)
        }
        setIsLoading(false)
    }

    useEffect(() => {
        getItems()
    }, [])

    console.log(error)

    const [searchText, setSearchText] = useState("");
    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const toggleFilterVisibility = () => {
        setIsFilterVisible((prevVisibility) => !prevVisibility);
    };

    const options = [
        { value: "sortByDate", label: "Sort by Date" },
        { value: "140923", label: "14 09 23" },
        { value: "110923", label: "11 09 23" },
    ];

    const productOptions = [
        { value: "chooseProduct", label: "Choose Product" },
        { value: "boldV3.2", label: "Bold V3.2" },
        { value: "nikeJordan", label: "Nike Jordan" },
    ];

    const columns = [
        {
            title: "Corte",
            dataIndex: 'DocEntryCorte',
            // render: (text, record) => (
            //     <span className="productimgname">

            //         <Link to="#">{record.U_CSM_IdOrden}</Link>
            //     </span>
            // ),
            sorter: (a, b) => a.ItemName.localeCompare(b.ItemName),
        },
        {
            title: "DocNum",
            dataIndex: 'U_CSM_IdOrden',
            // render: (text, record) => (
            //     <span className="productimgname">

            //         <Link to="#">{record.U_CSM_IdOrden}</Link>
            //     </span>
            // ),
            sorter: (a, b) => a.ItemName.localeCompare(b.ItemName),
        },
        {
            title: 'Estatus',
            dataIndex: 'CANCELED',
            render: (canceled, record) => {
                if (canceled === 'Y') {
                    return <span className="badge badge-linepape">Cancelado</span>;
                }
                else if (record.DocStatus === 'O') {
                    return <span className="badge badge-lineinfo">Abierto</span>;
                } else if (record.DocStatus === 'C') {
                    return <span className="badge badge-linesuccess">Cerrado</span>;
                }
            },

        },
        {
            title: "Fecha de creación",
            dataIndex: "DocDate",
            sorter: (a, b) => a.SubLineCode.localeCompare(b.SubLineCode),
            render: (text) => (
                <div>
                    <span>{(text ?? '').substring(0, 10)}</span>
                </div>
            ),
        },
        {
            title: "Hora de creacion",
            dataIndex: "DocTime",
            sorter: (a, b) => a.WhsCode.localeCompare(b.WhsCode),
            render: (record) => convertMilitaryTimeToStandardTimeFormat(record)
        },
        {
            title: "Cajero",
            dataIndex: "empleado",
            sorter: (a, b) => a.OnHand.localeCompare(b.OnHand),
        },
        {
            title: "Metodo de pago",
            dataIndex: "PeyMethod",
            render: (text) => (
                <div>
                    {text === "01" && (
                        <span className="badge badge-linesuccess">Efectivo</span>
                    )}
                    {text === "99" && (
                        <span className="badge badge-lineinfo">Tarjeta</span>
                    )}
                </div>
            ),
            sorter: (a, b) => a.OnHand.localeCompare(b.OnHand),
        },
        {
            title: "Total",
            dataIndex: "DocTotal",
            sorter: (a, b) => a.ItemCode.localeCompare(b.ItemCode),
            render: (record) => formatCurrency(record)
        },
        {
            title: "Acciones",
            dataIndex: "DocTotal",
            render: (_, record) => (
                <div className="action-table-data">
                    <div className="edit-delete-action">
                        <div className="input-block add-lists"></div>

                        <Link className="me-2 p-2" to="#"
                            onClick={() => handleShowModal(record)} >
                            <Eye className="feather-view" />
                        </Link>
                        <Link
                            className="confirm-text p-2"
                            to="#"
                        // onClick={showConfirmationAlert}
                        >
                            <Trash2 className="feather-trash-2" />
                        </Link>
                    </div>
                </div>
            ),
            sorter: (a, b) => a.ItemCode.localeCompare(b.ItemCode),
        },

    ];
    return (
        <DashboardLayout>
            <PageTtle
                maintitle="Ordenes"
                subtitle="Consulta todas ventas realizadas en La Pape"
            />

            {/* /product list */}
            <div className="card table-list-card">
                <div className="card-body">
                    <div className="table-top">
                        <div className="search-set">
                            <div className="search-input">
                                <input
                                    type="text"
                                    placeholder="Search"
                                    className="form-control form-control-sm formsearch"
                                    aria-controls="DataTables_Table_0"
                                    value={searchText}
                                    onChange={handleSearch}
                                />
                                <Link to className="btn btn-searchset">
                                    <i data-feather="search" className="feather-search" />
                                </Link>
                            </div>
                        </div>
                        <div className="search-path">
                            <Link
                                className={`btn btn-filter ${isFilterVisible ? "setclose" : ""
                                    }`}
                                id="filter_search"
                            >
                                <Filter
                                    className="filter-icon"
                                    onClick={toggleFilterVisibility}
                                />
                                <span onClick={toggleFilterVisibility}>
                                    <ImageWithBasePath
                                        src="assets/img/icons/closes.svg"
                                        alt="img"
                                    />
                                </span>
                            </Link>
                        </div>
                        <div className="form-sort stylewidth">
                            <Sliders className="info-img" />

                            <Select
                                classNamePrefix="react-select"
                                className="img-select"
                                options={options}
                                placeholder="Sort by Date"
                            />
                        </div>

                    </div>
                    {/* /Filter */}
                    <div
                        className={`card${isFilterVisible ? " visible" : ""}`}
                        id="filter_inputs"
                        style={{ display: isFilterVisible ? "block" : "none" }}
                    >
                        <div className="card-body pb-0">
                            <div className="row">
                                <div className="col-lg-3">
                                    <div className="input-blocks">
                                        <Box className="info-img" />
                                        <Select
                                            className="img-select"
                                            classNamePrefix="react-select"
                                            options={productOptions}
                                        />
                                    </div>
                                </div>
                                {/* <div className="col-lg-3">
                                    <div className="input-blocks">
                                        <Zap className="info-img" />
                                        <Select
                                            className="img-select"
                                            classNamePrefix="react-select"
                                            options={categoryOptions}
                                        />
                                    </div>
                                </div> */}
                                <div className="col-lg-6 col-sm-6 col-12">
                                    <div className="input-blocks">
                                        <Link className="btn btn-filters ms-auto">
                                            {" "}
                                            <i
                                                data-feather="search"
                                                className="feather-search"
                                            />{" "}
                                            Search{" "}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* /Filter */}
                    <div className="table-responsive">
                        {(!error) ? <Table
                            columns={columns}
                            loading={isLoading}
                            dataSource={items.orders}
                            rowKey={(record) => record.U_CSM_IdOrden}
                        /> :

                            <Error404 />}

                    </div>
                </div>
            </div>
            <ModalDetails visible={modalVisible} onClose={handleCloseModal} record={selectedRecord} />

        </DashboardLayout>

    );
};

export default OrdersReports;
