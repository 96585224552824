import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
	selectFilters,
	selectCategories,
	selectCategoriesLoading,
	selectCategoriesError,
	selectCategory,
} from '../../../redux/selectors/catalog'
import { changeFilter } from '../../../redux/catalog'
import { Form, Dropdown } from 'react-bootstrap'

export default function Category() {
	const category = useSelector(selectCategory)
	const categories = useSelector(selectCategories)
	const categoriesLoading = useSelector(selectCategoriesLoading)
	const categoriesError = useSelector(selectCategoriesError)
	const filters = useSelector(selectFilters)
	const dispatch = useDispatch()
	const [searchTerm, setSearchTerm] = useState('')
	const [selectedOption, setSelectedOption] = useState(null)
	const [options, setOptions] = useState([])

	const handleChange = (e) => {
		setSearchTerm(e.target.value)
	}

	useEffect(() => {
		const resultadosFiltrados = categories.filter((category) =>
			category.LineName.toLowerCase().includes(searchTerm.toLowerCase())
		)
		setOptions(resultadosFiltrados)
	}, [searchTerm])

	useEffect(() => {
		setOptions(categories)
	}, [categories])

	useEffect(() => {
		if (selectedOption) {
			dispatch(
				changeFilter({
					...filters,
					category: selectedOption.LineCode,
					subcategory: '',
				})
			)
		}
	}, [selectedOption])

	return (
		<>
			<div className='d-flex flex-column gap-2'>
				<h5>Categoría</h5>

				{categoriesError ? (
					<span className='text-danger'>{categoriesError}</span>
				) : categories.length > 0 ? (
					<Dropdown>
						<Dropdown.Toggle
							id='dropdown-basic'
							disabled={categoriesLoading}
							style={{
								width: '150px',
								whiteSpace: 'nowrap',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
							}}>
							{categoriesLoading
								? 'Cargando...'
								: !selectedOption || !category
								? 'Seleccionar'
								: selectedOption.LineName}
						</Dropdown.Toggle>

						<Dropdown.Menu style={{ height: '300px', overflowY: 'scroll' }}>
							<Form.Control
								autoFocus
								className='mx-3 my-2 w-auto'
								placeholder='Buscar...'
								onChange={(e) => handleChange(e)}
								value={searchTerm}
							/>
							{options.map((option) => (
								<Dropdown.Item
									key={option.LineCode}
									onClick={() => setSelectedOption(option)}>
										{option.LineName}
								</Dropdown.Item>
							))}
						</Dropdown.Menu>
					</Dropdown>
				) : (
					<span className='text-danger'>No hay categorias disponibles</span>
				)}
			</div>
		</>
	)
}
