import React from "react";
import PropTypes from "prop-types";
import { Select, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const InputBlock = ({
    label,
    type,
    placeholder,
    className,
    isDisabled,
    isSearchable,
    options,
    enablePrefix,
    value, // Agregamos `value`
    onChange, // Agregamos `onChange`
    onKeyPress, // Agregamos `onKeyPress`
    isLoading
}) => {
    return (
        <div className={`input-blocks ${className}`}>
            {label && <label>{label}</label>}
            {type === "select" ? (
                <Select
                    // classNamePrefix="react-select"
                    disabled={isDisabled}
                    options={options}
                    style={{ minWidth: "100%" }}
                    loading={isLoading}
                />
            ) : (
                <div className={`search-form ${isSearchable ? "mb-3" : ""}`}>
                    <Input
                        placeholder={placeholder}
                        disabled={isDisabled}
                        prefix={enablePrefix ? <SearchOutlined /> : null}
                        value={value} // Propagamos `value`
                        onChange={onChange} // Propagamos `onChange`
                        onKeyPress={onKeyPress} // Propagamos `onKeyPress`
                        
                    />
                </div>
            )}
        </div>
    );
};

InputBlock.propTypes = {
    label: PropTypes.string,
    type: PropTypes.oneOf(["text", "select", "number", "email"]),
    placeholder: PropTypes.string,
    className: PropTypes.string,
    isDisabled: PropTypes.bool,
    isSearchable: PropTypes.bool,
    enablePrefix: PropTypes.bool,
    value: PropTypes.string, // Agregamos la validación de `value`
    onChange: PropTypes.func, // Agregamos la validación de `onChange`
    onKeyPress: PropTypes.func, // Agregamos la validación de `onKeyPress`
    isLoading: PropTypes.bool,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string,
        })
    ),
};

export default InputBlock;
