import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import { useContext } from 'react'
import { OrdersCashCountContext } from '../provider/OrdersCashCount'

export default function FeedbackCancelOrder() {
	const {
		isShowFeedbackCancelOrder,
		updateIsShowFeedbackCancelOrder,
		feedbackCancelOrder,
		errorCancelOrder,
	} = useContext(OrdersCashCountContext)

	return (
		<Modal centered size='sm' show={isShowFeedbackCancelOrder}>
			<Modal.Header>{errorCancelOrder ? 'Error' : 'Correcto'}</Modal.Header>
			<Modal.Body>
				<p>{feedbackCancelOrder}</p>
			</Modal.Body>
			<Modal.Footer>
				<Button
					onClick={() => updateIsShowFeedbackCancelOrder(false)}
					variant='pape_cancel'>
					Cerrar
				</Button>
			</Modal.Footer>
		</Modal>
	)
}
