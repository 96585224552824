import React, { useContext, useState } from 'react'
import DashboardLayout from '../../../layouts/DashboardLayout'
import PageTitle from '../../../layouts/PagesTitle'
import { Mail, Tool } from 'feather-icons-react/build/IconComponents'
import { Link } from 'react-router-dom'

import { PinpadProvider } from '../provider/PinpadContext'

import Swal from 'sweetalert2'
import { PinpadContext } from '../../../provider/Pinpad'
import ModalConfigPinpad from '../layouts/ModalConfigPinpad'

const FingerPrintContent = () => {
	const {
		isPinpadConfigured,
		portSelected,
		clearPinpad,
		getSerialPortSelected,
		getIsPinPadConfigured,
	} = useContext(PinpadContext)
	const [modalVisible, setModalVisible] = useState(false)

	const handleShowModal = () => {
		setModalVisible(true)
	}

	const handleCloseModal = () => {
		setModalVisible(false)
	}

	const handleClearPinpad = () => {
		if (isPinpadConfigured && portSelected) {
			handleDeleteConfirmation()
		}
	}

	const handleDeleteConfirmation = async () => {
		const result = await Swal.fire({
			title: '¿Estás seguro?',
			text: 'Esta acción eliminará la configuración del pinpad.',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Sí, eliminar!',
			cancelButtonText: 'Cancelar',
		})

		if (result.isConfirmed) {
			clearPinpad()
			Swal.fire(
				'Eliminado!',
				'La configuración del pinpad ha sido eliminada.',
				'success'
			)
		}
	}

	const isPinpadEnabled =
		getSerialPortSelected() && getIsPinPadConfigured() && portSelected

	const selectedRecord = {}

	return (
		<>
			<PageTitle
				maintitle='Configuración de los métodos de pago'
				subtitle='Este es un subtítulo'
			/>
			<div className='row'>
				<div className='col-xl-12'>
					<div className='settings-wrapper d-flex'>
						<div className='settings-page-wrap'>
							<div className='page-header text-end justify-content-end'>
								<Link to='#' className='btn-added btn-primary'>
									<Mail className='me-2' />
									Ejecutar cobro de prueba
								</Link>
							</div>
							<br />
							<div className='row'>
								<div className='col-xxl-4 col-xl-6 col-lg-12 col-md-6 d-flex'>
									<div className='connected-app-card email-setting d-flex w-100'>
										<ul className='w-100'>
											<li className='flex-column align-items-start'>
												<div className='d-flex align-items-center justify-content-between w-100 mb-2'>
													<div className='security-type d-flex align-items-center'>
														<div className='security-title'>
															<h5>Pinpad Inbursa</h5>
														</div>
													</div>
												</div>
												<p>
													Integración de la PinpadWebApp para giros tipo Retail.
													<br />
													PinPadWeb RETAIL ver. 2.5.0
												</p>
											</li>
											<li>
												<div className='integration-btn'>
													<Link onClick={handleShowModal}>
														<Tool className='me-2' />
														{isPinpadEnabled ? 'Volver a conectar' : 'Conectar'}
													</Link>
												</div>
												<div className='status-toggle modal-status d-flex justify-content-between align-items-center ms-2'>
													{isPinpadEnabled ? (
														<span className='badges-connected'>Connected</span>
													) : (
														'Inactivo'
													)}
													<input
														type='checkbox'
														id='user1'
														className='check'
														checked={isPinpadConfigured && portSelected}
														onChange={handleClearPinpad}
													/>
													&nbsp;&nbsp;
													<label htmlFor='user1' className='checktoggle'>
														{' '}
													</label>
												</div>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ModalConfigPinpad
				visible={modalVisible}
				onClose={handleCloseModal}
				record={selectedRecord}
			/>
		</>
	)
}

const FingerPrint = () => (
	<DashboardLayout>
		<PinpadProvider>
			<FingerPrintContent />
		</PinpadProvider>
	</DashboardLayout>
)

export default FingerPrint
