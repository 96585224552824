import { createSlice } from '@reduxjs/toolkit'

/**
 * Slice para manejar el estado del carrito de compras.
 */
export const cartSlice = createSlice({
	name: 'cart',
	initialState: {
		items: [],
		isShowDeleteCartModal: false,
	},
	reducers: {
		/**
		 * Añade un item al carrito o incrementa su cantidad si ya existe.
		 * @param {Object} state - El estado actual del carrito.
		 * @param {Object} action - La acción con el payload del item a añadir.
		 */
		addItem: (state, action) => {
			const itemToAdd = action.payload
			const existingItem = state.items.find(
				(item) => item.ItemCode === itemToAdd.ItemCode
			)

			if (existingItem) {
				if (existingItem.quantity < itemToAdd.OnHand) {
					existingItem.quantity += 1
				}
			} else {
				state.items.push({ ...itemToAdd, quantity: 1 })
			}
		},

		/**
		 * Elimina un item del carrito.
		 * @param {Object} state - El estado actual del carrito.
		 * @param {Object} action - La acción con el índice del item a eliminar.
		 */
		removeItem: (state, action) => {
			state.items.splice(action.payload, 1)
		},

		/**
		 * Reduce la cantidad de un item en el carrito o lo elimina si la cantidad llega a cero.
		 * @param {Object} state - El estado actual del carrito.
		 * @param {Object} action - La acción con el payload del item a reducir.
		 */
		removeOneItem: (state, action) => {
			const itemToRemove = state.items.find(
				(item) => item.ItemCode === action.payload.ItemCode
			)
			if (itemToRemove) {
				itemToRemove.quantity > 1
					? itemToRemove.quantity--
					: (state.items = state.items.filter((item) => item.ItemCode !== itemToRemove.ItemCode))
			}
		},

		/**
		 * Modifica la cantidad de un item en el carrito.
		 * @param {Object} state - El estado actual del carrito.
		 * @param {Object} action - La acción con el payload conteniendo el itemCode y la nueva cantidad.
		 */
		modifyQuantity: (state, action) => {
			const { itemCode, quantity } = action.payload
			const item = state.items.find((item) => item.ItemCode === itemCode)
			if (item) {
				if (quantity > 0 && quantity <= item.OnHand) {
					item.quantity = quantity
				} else if (quantity <= 0) {
					state.items = state.items.filter((item) => item.ItemCode !== itemCode)
				}
			}
		},

		/**
		 * Limpia todos los items del carrito.
		 * @param {Object} state - El estado actual del carrito.
		 */
		clean: (state) => {
			state.items = []
		},

		/**
		 * Cambia el estado de visibilidad del modal de eliminación del carrito.
		 * @param {Object} state - El estado actual del carrito.
		 * @param {Object} action - La acción con el nuevo estado de visibilidad.
		 */
		toggleOpenModalDeleteCart: (state, action) => {
			state.isShowDeleteCartModal = action.payload
		},
		modifyPricesIfCouponApplicated: (state, action) => {
			state.items = state.items.map((item) => {
				const itemToGetCouponPrice = action.payload.find(
					(itemFinded) => itemFinded.itemCode === item.ItemCode
				)

				if (itemToGetCouponPrice) {
					return {
						...item,
						couponPrice: itemToGetCouponPrice.priceAfterCoupon,
						couponDiscountPercentage: itemToGetCouponPrice.percentage,
						couponAmountDiscounted: itemToGetCouponPrice.amountDiscounted,
					}
				} else {
					return {
						...item,
					}
				}
			})
		},
		removeDiscountPricesFromCart: (state) => {
			state.items = state.items.map((item) => {
				if (item.couponPrice) {
					delete item.couponPrice
					delete item.couponDiscountPercentage
					delete item.couponAmountDiscounted
				}

				return item
			})
		},
	},
})

export const {
	addItem,
	removeOneItem,
	removeItem,
	clean,
	toggleOpenModalDeleteCart,
	modifyQuantity,
	modifyPricesIfCouponApplicated,
	removeDiscountPricesFromCart,
} = cartSlice.actions

export default cartSlice.reducer
