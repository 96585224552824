import { Avatar, List } from 'antd'
import React from 'react'
import { formatCurrency } from '../../../utils/functions/money'
import { PropTypes } from 'prop-types'

OrderListClientProduct.propTypes = {
	product: PropTypes.object,
}

export default function OrderListClientProduct({ product }) {
	const prices = {
		priceLiquidation: product.AvgPriceLiquidation
			? formatCurrency(product.AvgPriceLiquidation)
			: null,
		priceWithDiscount: product.couponPrice
			? formatCurrency(product.couponPrice)
			: null,
		price: formatCurrency(product.AvgPrice),
	}
	let description

	if (prices.priceWithDiscount) {
		if (prices.priceLiquidation) {
			description = (
				<div className='d-flex gap-2'>
					<span className='text-decoration-line-through'>
						{prices.priceLiquidation}
					</span>
					<span>{prices.priceWithDiscount}</span>
				</div>
			)
		} else {
			description = (
				<div className='d-flex gap-2'>
					<span className='text-decoration-line-through'>{prices.price}</span>
					<span>{prices.priceWithDiscount}</span>
				</div>
			)
		}
	} else if (prices.priceLiquidation) {
		description = (
			<div className='d-flex gap-2'>
				<span className='text-decoration-line-through'>{prices.price}</span>
				<span>{prices.priceLiquidation}</span>
			</div>
		)
	} else {
		description = <span>{prices.price}</span>
	}

	return (
		<List.Item key={product.ItemCode}>
			<List.Item.Meta
				avatar={
					<Avatar
						src={`http://thoro-ent.dyndns.info/empleados/imagenes/ITEMS/${product.PicturName}`}
					/>
				}
				title={product.ItemName}
				description={
					<div className='d-flex gap-1'>
						{`${product.quantity} x `}
						{description}
					</div>
				}
			/>
			<div>
				{formatCurrency(
					product.quantity *
						(product.couponPrice
							? product.couponPrice
							: product.AvgPriceLiquidation
							? product.AvgPriceLiquidation
							: product.AvgPrice)
				)}
			</div>
		</List.Item>
	)
}
