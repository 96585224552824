import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import PropTypes from 'prop-types'
import SaleDataSection from './SaleDataSection'
import StoreDataSection from './StoreDataSelection'
import { useDispatch, useSelector } from 'react-redux'
import { ConfigProvider, Steps } from 'antd'
import { changeStatus, resetSale, transactOrder } from '../redux/new-sale'
import {
	selectIsLoading,
	selectItemsCount,
	selectStatus,
} from '../redux/selectors/new-sale'
import SaleResume from '../layout/SaleResume'
import SaleFinished from '../layout/SaleFinished'

const steps = [
	{
		title: 'Datos de la Tienda',
		content: <StoreDataSection />,
	},
	{
		title: 'Datos de la Venta',
		content: <SaleDataSection />,
	},
	{
		title: 'Resumen',
		content: <SaleResume />,
	},
	{
		title: 'Estado de la Venta',
		content: <SaleFinished />,
	},
]

const NewSaleModal = ({ visible, onClose }) => {
	const dispatch = useDispatch()
	const itemsCount = useSelector(selectItemsCount)
	const status = useSelector(selectStatus)
	const { isNotRegisteredOrder, error, isDataSaleComplete } = useSelector(
		(state) => state.sale
	)
	const isLoadingOrder = useSelector(selectIsLoading)
	const [current, setCurrent] = useState(0)

    const next = () => {
        setCurrent(current + 1)
    }

    const prev = () => {
        setCurrent(current - 1)
    }

	const onProcess = () => {
		dispatch(transactOrder())
	}

	const items = steps.map((item) => ({
		key: item.title,
		title: item.title,
	}))

	useEffect(() => {
		switch (current) {
			case 0:
				dispatch(changeStatus('preliminar'))
				break
			case 1:
				dispatch(changeStatus('items'))
				break
			case 2:
				dispatch(changeStatus('execute'))
				break
		}
	}, [current])

	useEffect(() => {
		console.log(current, status)

		if (current === 2 && (status === 'completed' || status === 'error')) {
			next()
		}
	}, [status])

    return (
        <Modal
            show={visible}
            onHide={() => {
                onClose()
                dispatch(resetSale())
                setCurrent(0)
            }}
            backdrop='static'
            keyboard={false}
            size='xl'
            aria-labelledby='contained-modal-title-vcenter'
            centered>

            <Modal.Header closeButton>
                <Modal.Title>Venta</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ConfigProvider
                    theme={{
                        token: {
                            colorPrimary: '#e51885',
                        },
                    }}>
                    <div className='page-wrapper-new p-0'>
                        <div className='content'>
                            <div className='modal-header border-0 custom-modal-header'>
                                <div className='page-title'>
                                    <h4>Nueva venta</h4>
                                </div>
                            </div>
                            <div className='modal-body custom-modal-body'>
                                <div className='row'>
                                    <span>
                                        ¿Existe una orden con ese folio?:{' '}
                                        {isNotRegisteredOrder ? 'Sí' : 'No'}
                                    </span>
                                    <span>Error: {error}</span>
                                    <span>
                                        DataSaleComplete: {isDataSaleComplete ? 'Sí' : 'No'}
                                    </span>
                                </div>
                                <Steps
                                    current={current}
                                    items={items}
                                    style={{ marginBottom: 24 }}
                                />
                                <div>{steps[current].content}</div>
                            </div>
                        </div>
                    </div>
                </ConfigProvider>
            </Modal.Body>
            <Modal.Footer>
                {((current > 0 && current < 3) ||
					(current === 3 && status === 'error')) && (
                    <button
                        style={{ marginRight: 8 }}
                        onClick={prev}
                        type='secondary'
                        className='btn btn-outline-secondary'>
                        Anterior
                    </button>
                )}

				<Button
					type='primary'
					disabled={
						!isDataSaleComplete ||
						(current === 1 && itemsCount === 0) ||
						(current === 2 && isLoadingOrder)
					}
					onClick={() => {
						if (current < 2) {
							next()
						} else if (current === 2) {
							onProcess()
						} else if (current === 3) {
							dispatch(resetSale())
							onClose()
							setCurrent(0)
						}
					}}>
					{current < 2
						? 'Siguiente'
						: current === 2
						? 'Procesar orden'
						: 'Cerrar'}
					{current === 2 && isLoadingOrder && (
						<div
							className='spinner-border spinner-border-sm ms-2'
							role='status'>
							<span className='sr-only'>Loading...</span>
						</div>
					)}
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

NewSaleModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
}

export default NewSaleModal
