import React from 'react'
import { Button, Modal} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Impresora from './Impresora'
import { selectIsShowModalTicket } from '../../../redux/selectors/order'
import { toggleShowModalTicket } from '../../../redux/order'


export default function ModalTicket(){
    const dispatch = useDispatch()
    const isShowModalTicket = useSelector(selectIsShowModalTicket)

    const handleClose = () => {
        dispatch(toggleShowModalTicket(false))
    }
    
    return(
        <>
            <Modal size='lg' centered show={isShowModalTicket}>
                <Modal.Header>
                    <Modal.Title as='h2'>Desea descargar el ticket?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Impresora/>
                        <Button
                        className='mt-4 py-3 px-4'
                        onClick={handleClose}
                        >
                        Salir    
                        </Button>
                </Modal.Body>
            </Modal>
        </>
    )
}