import React from 'react';
import { Button, Select } from 'antd';
import { PropTypes } from 'prop-types'

const SettingSelect = ({ label, description, options, value, onChange, placeholder, required = false, disableSelect ,showButton = false, loadingButton= false, funcion, disableButton = false  }) => {
    return (
        <div className="row align-items-center mb-sm-4">
            <div className="col-sm-4">
                <div className="logo-info me-0 mb-3 mb-md-0">
                    <h6>{required ? `*${label}` : label}</h6>
                    <p>{description}</p>
                </div>
            </div>
            <div className="col-sm-6">
                <Select
                    options={options}
                    placeholder={placeholder || "Seleccionar"}
                    value={value}
                    onChange={onChange}
                    required={required}
                    style={{ minWidth: '30%' }}
                    disabled={disableSelect}
                />
                {showButton ?
                    <Button  style={{ marginLeft: '10px', }} type="primary" loading={loadingButton} iconPosition={'end'} onClick={() => funcion()} disabled={disableButton}>
                        Probar
                    </Button>
                    : ''
                }
            </div>


        </div>
    );
};

SettingSelect.propTypes = {
    label: PropTypes.string.isRequired,
    description: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            label: PropTypes.string.isRequired
        })
    ).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    disableSelect: PropTypes.bool,
    showButton: PropTypes.bool,
    funcion: PropTypes.func,
    loadingButton: PropTypes.bool,
    disableButton: PropTypes.bool
};

export default SettingSelect;
