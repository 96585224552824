// PinpadContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const PinpadContext = createContext();

export const usePinpad = () => useContext(PinpadContext);

export const PinpadProvider = ({ children }) => {
    const [isConfiguredPinpad, setConfiguredPinpad] = useState(false);

    useEffect(() => {
        const datosUsuario = localStorage.getItem('datosusuario');
        const datosPinPad = localStorage.getItem('datosPinPad');
        const user = localStorage.getItem('user')

        setConfiguredPinpad((datosUsuario !== null) && (datosPinPad !== null) && (user !== null));
    }, []);

    const configurePinpad = (configurado) => {
        setConfiguredPinpad(configurado);
    };

    const clearPinpad = () => {
        setConfiguredPinpad(false);
        localStorage.removeItem('datosusuario'); // Elimina la información de localStorage
        localStorage.removeItem('datosPinPad')
        localStorage.removeItem('user')
    };

    return (
        <PinpadContext.Provider value={{ isConfiguredPinpad, configurePinpad, clearPinpad }}>
            {children}
        </PinpadContext.Provider>
    );
};

PinpadProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
