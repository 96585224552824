import React, {  } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { convertMilitaryTimeToStandardTimeFormat } from '../../../utils/functions/date'
import { formatCurrency } from '../../../utils/functions/money'
import { changeStatus } from '../../../redux/withdraw'
import { useDispatch, useSelector } from 'react-redux'
import {
	selectDataCashCount,
	selectErrorCashCount,
	selectFeedbackCashCount,
	selectIsShowFeedbackCashCount,
} from '../redux/selectors/cash-count'
import { updateIsShowFeedbackCashCount } from '../redux/cash-count'

export default function FeedbackExecuteCashCount() {
	const dispatch = useDispatch()
	const isShowFeedbackCashCount = useSelector(selectIsShowFeedbackCashCount)
	const feedbackCashCount = useSelector(selectFeedbackCashCount)
	const errorCashCount = useSelector(selectErrorCashCount)
	const dataCashCount = useSelector(selectDataCashCount)



	return (
		<Modal centered size='md' show={isShowFeedbackCashCount}>
			<Modal.Header>{errorCashCount ? 'Error' : 'Correcto'}</Modal.Header>
			<Modal.Body>
				<p>{feedbackCashCount}</p>
				{dataCashCount && (
					<ul>
						{dataCashCount.date_created && dataCashCount.time_created && (
							<div className='d-flex gap-2 align-items-center'>
								<span>
									<b>Apertura: </b>
								</span>
								<span>{`${
									dataCashCount.date_created
								} ${convertMilitaryTimeToStandardTimeFormat(
									dataCashCount.time_created
								)}`}</span>
							</div>
						)}
						{dataCashCount.date_closed && dataCashCount.time_closed && (
							<div className='d-flex gap-2 align-items-center'>
								<span>
									<b>Cierre: </b>
								</span>
								<span>{`${
									dataCashCount.date_closed
								} ${convertMilitaryTimeToStandardTimeFormat(
									dataCashCount.time_closed
								)}`}</span>
							</div>
						)}
						{dataCashCount.user && (
							<div className='d-flex gap-2 align-items-center'>
								<span>
									<b>Usuario: </b>
								</span>
								<span>{dataCashCount.user}</span>
							</div>
						)}
						{dataCashCount.device && (
							<div className='d-flex gap-2 align-items-center'>
								<span>
									<b>Dispositivo: </b>
								</span>
								<span>{dataCashCount.device}</span>
							</div>
						)}
						{dataCashCount.total_due && (
							<div className='d-flex gap-2 align-items-center'>
								<span>
									<b>Total Ventas: </b>
								</span>
								<span>{formatCurrency(dataCashCount.total_due)}</span>
							</div>
						)}
						{dataCashCount?.total_surrendered ? (
							<div className='d-flex gap-2 align-items-center'>
								<span>
									<b>Total Entregado: </b>
								</span>
								<span>{formatCurrency(dataCashCount?.total_surrendered)}</span>
							</div>
						) : (
							<></>
						)}
						{dataCashCount.difference && (
							<div className='d-flex gap-2 align-items-center'>
								<span>
									<b>
										{dataCashCount.difference > -1 ? 'Faltante' : 'Sobrante'}:{' '}
									</b>
								</span>
								<span>
									{formatCurrency(
										dataCashCount.difference > -1
											? dataCashCount.difference
											: Math.abs(dataCashCount.difference)
									)}
								</span>
							</div>
						)}
						{dataCashCount.fund && (
							<div className='d-flex gap-2 align-items-center'>
								<span>
									<b>Fondo de Caja: </b>
								</span>
								<span>{formatCurrency(dataCashCount.fund)}</span>
							</div>
						)}
					</ul>
				)}
			</Modal.Body>
			<Modal.Footer>
				<Button
					onClick={() => {
						dispatch(updateIsShowFeedbackCashCount(false))
						dispatch(changeStatus('noinit'))
					}}
					variant='pape_cancel'>
					Cerrar
				</Button>
			</Modal.Footer>
		</Modal>
	)
}
