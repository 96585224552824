import React from 'react'
import CouponsProvider from '../provider/Coupons'
import DashboardLayout from '../layouts/DashboardLayout'
import CouponsTable from '../layouts/CouponsTable'
import CouponsForm from '../layouts/CouponsForm'
import CouponsHeader from '../layouts/CouponsHeader'
import CouponDetail from '../layouts/CouponDetail'

const Coupons = () => {
	return (
		<DashboardLayout>
			<CouponsProvider>
				<CouponsHeader />
				<CouponsTable />
				<CouponsForm />
				<CouponDetail />
			</CouponsProvider>
		</DashboardLayout>
	)
}

export default Coupons
