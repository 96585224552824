import React, { useContext } from 'react'
import PageTitle from './PagesTitle'
import { Button } from 'antd'
import { CouponsContext } from '../provider/Coupons'

const CouponsHeader = () => {
	const { updateIsShowModalForm } = useContext(CouponsContext)

	return (
		<div className='d-flex flex-row justify-content-between'>
			<PageTitle maintitle='Cupones' subtitle='Administracion de cupones' />
			<Button onClick={() => updateIsShowModalForm(true)}>
				Agregar nuevo cupon
			</Button>
		</div>
	)
}

export default CouponsHeader
