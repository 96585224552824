import React from 'react'
import { PropTypes } from 'prop-types'
import { formatCurrency } from '../../../utils/functions/money'

ItemOfert.propTypes = {
	ItemCode: PropTypes.string,
	ItemName: PropTypes.string,
	AvgPrice: PropTypes.string,
	AvgPriceLiquidation: PropTypes.string,
	key: PropTypes.string,
}

export default function ItemOfert({
	ItemCode,
	ItemName,
	AvgPrice,
	AvgPriceLiquidation,
	key,
}) {
	return (
		<div key={key} className='liquidacion d-flex flex-row justify-content-between'>
			<div className='titulo-liquidacion d-flex flex-column align-items-center justify-content-center'>
				<span>¡¡¡PRODUCTOS EN</span>
				<span>LIQUIDACIÓN!!!</span>
			</div>
			<div className='separador d-flex flex-column'>
				<span>|</span>
				<span>|</span>
				<span>|</span>
				<span>|</span>
				<span>|</span>
				<span>|</span>
				<span>|</span>
				<span>|</span>
				<span>|</span>
			</div>
			<div
				className='info d-flex flex-column align-items-start'
				style={{ padding: 1 + '%', width: 60 + '%' }}>
				<div className='d-flex flex-row'>
					<h4
						style={{
							paddingTop: 10 + 'px',
							paddingLeft: 1 + '%',
							color: 'white',
						}}>
						{ItemName}
					</h4>
				</div>
				<span
					style={{
						color: 'white',
						background: '#08b9e4',
						padding: '5px 10px',
						borderRadius: 10 + 'px',
						fontWeight: 'bold' 
					}}>
					{ItemCode}
				</span>
				<div className='d-flex flex-column align-items-start justify-content-evenly'>
					<div className='d-flex flex-row'>
						<h3 style={{ color: 'white' }}>Nuevo precio:&nbsp;</h3>
						<h3 style={{color: 'white'}}>{formatCurrency(AvgPriceLiquidation)}&nbsp;</h3>
						<h6 style={{color: 'white', marginTop:'7px'}}>(incluye IVA)</h6>
					</div>
					<div className='d-flex flex-row'>
						<h4 style={{ color: 'white' }}>Precio anterior:&nbsp;</h4>
						<h4 style={{color: 'white',textDecoration: 'line-through',}}>{formatCurrency(AvgPrice)}</h4>
					</div>
				</div>
			</div>
		</div>
	)
}
