import React from 'react';
import ImageWithBasePath from '../../../core/img/imagewithbasebath'
import { RotateCcw } from 'react-feather';
import { Link } from 'react-router-dom'

const Toolbar = () => {
    return (
        <ul className="table-top-head">
            <li>
                <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <RotateCcw />
                </Link>
            </li>
            <li>
                <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Pdf"
                >
                    <ImageWithBasePath
                        src="assets/img/icons/pdf.svg"
                        alt="img"
                    />
                </Link>
            </li>
            <li>
                <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Excel"
                >
                    <ImageWithBasePath
                        src="assets/img/icons/xls.svg"
                        alt="img"
                    />
                </Link>
            </li>
            <li>
                <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Print"
                >
                    <ImageWithBasePath
                        src="assets/img/icons/excel.svg"
                        alt="img"
                    />
                </Link>
            </li>
        </ul>
    );
};



export default Toolbar;