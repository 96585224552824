import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiUrl, idDevice } from '../../../utils/var'
import { Print } from '../../../utils/functions/printTicket'

const initialState = {
	idEmployee: null,
	idManager: null,
	idDevice: null,
	dateOpening: null,
	timeOpening: null,
	openingBalance: null,
	isOpen: false,
	isLoading: false,
	isOpenModal: false,
	error: null,
	cash: null,
	isShowConfirm: false,
	isShowFeedbackCashCount: false,
	isLoadingExecuteCashCount: false,
	errorCashCount: null,
	feedbackCashCount: null,
	dataCashCount: null,
	device: idDevice(),
}

export const openCashCount = createAsyncThunk(
	'cashCount/open',
	async (data) => {
		try {
			const urlOpenCashCount = `${apiUrl}/api/cash-count/open/`

			const openCashCountRequest = await fetch(urlOpenCashCount, {
				method: 'POST',
				body: JSON.stringify({
					U_CSM_IdEmp: data.idEmployee,
					U_CSM_IdGerente: data.idManager,
					U_CSM_IdEquipo: data.idDevice,
					U_CSM_SaldoInicial: data.cashDrawerFund,
				}),
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
				},
				credentials: 'include',
			})

			const bodyOpenCashCountRequest = await openCashCountRequest.json()

			if (!openCashCountRequest.ok) {
				let errorMessage
				let errors
				try {
					errorMessage = bodyOpenCashCountRequest.message || 'Error desconocido'
					errors = bodyOpenCashCountRequest.errors || 'error'
				} catch {
					errorMessage =
						'Ocurrió un error abrir el turno, intentelo de nuevo o contacte a soporte.'
				}

				const errorInfo = {
					code: openCashCountRequest.status,
					message: errorMessage,
					url: urlOpenCashCount,
					errors: errors,
				}

				throw new Error(JSON.stringify(errorInfo))
			}

			return {
				idEmployee: data.idEmployee,
				idManager: data.idManager,
				idDevice: data.idDevice,
			}
		} catch (error) {
			console.error(error)
			let errorMessage

			try {
				const errorInfo = JSON.parse(error.message)
				errorMessage = errorInfo.message
			} catch {
				errorMessage =
					'Ocurrió un error abrir el turno, intentelo de nuevo o contacte a soporte.'
			}
			throw new Error(errorMessage)
		}
	}
)

export const executeCashCount = createAsyncThunk(
	'cashCount/execute',
	async (_, { getState }) => {
		try {
			const urlExecuteCashCount = `${apiUrl}/api/cash-count/execute`

			const requestExecuteCashCount = await fetch(urlExecuteCashCount, {
				method: 'PATCH',
				body: JSON.stringify({
					idDevice: getState()['cash-count'].device,
				}),
				credentials: 'include',
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
				},
			})

			const bodyExecuteCashCount = await requestExecuteCashCount.json()
			if (!requestExecuteCashCount.ok) {
				let errorMessage
				let errors
				try {
					errorMessage = bodyExecuteCashCount.message || 'Error desconocido'
					errors = bodyExecuteCashCount.errors || 'error'
				} catch {
					errorMessage =
						'Ocurrió un error abrir el turno, intentelo de nuevo o contacte a soporte.'
				}

				const errorInfo = {
					code: bodyExecuteCashCount.status,
					message: errorMessage,
					url: urlExecuteCashCount,
					errors: errors,
				}

				throw new Error(JSON.stringify(errorInfo))
			}

			await handlePrintCashCount(
				bodyExecuteCashCount.data.idDevice,
				bodyExecuteCashCount.data.idCashCount
			)

			return bodyExecuteCashCount.data
		} catch (error) {
			console.error(error)
			let errorMessage

			try {
				const errorInfo = JSON.parse(error.message)
				errorMessage = errorInfo.message
			} catch {
				errorMessage =
					'Ocurrió un error abrir el turno, intentelo de nuevo o contacte a soporte.'
			}
			throw new Error(errorMessage)
		}
	}
)

const handlePrintCashCount = async (idDevice, idCashCount) => {
	try {
		const responsePrint = await Print(
			{ Type_XML: 'CASH_COUNT' },
			`${apiUrl}/api/print/cashCount/${idDevice}/${idCashCount}`,
			'POST'
		)

		if (responsePrint.statusCode !== 200) {
			throw new Error(
				`Error al imprimir el ticket: ${responsePrint.message} - ${
					responsePrint.errors ?? ''
				}`
			)
		}
		console.log(responsePrint)
	} catch (error) {
		console.error('Error en catch:', error)
	} finally {
		console.log('loadingPrinting: ', false)
	}
}

const cashCountSlice = createSlice({
	name: 'cash-count',
	initialState,
	reducers: {
		clean: () => {
			return initialState
		},
		toggleModal: (state, action) => {
			state.isOpenModal = action.payload
			if (!action.payload) {
				state.error = null
			}
		},
		updateCash: (state, action) => {
			state.cash = action.payload
		},
		updateIsShowConfirm: (state, action) => {
			state.isShowConfirm = action.payload
		},
		updateIsShowFeedbackCashCount: (state, action) => {
			state.isShowFeedbackCashCount = action.payload
		},
		updateErrorCashCount: (state, action) => {
			state.errorCashCount = action.payload
		},
		updateFeedbackCashCount: (state, action) => {
			state.feedbackCashCount = action.payload
		},
		updateDataCashCount: (state, action) => {
			state.dataCashCount = action.payload
		},
		updateDevice: (state, action) => {
			state.device = action.payload
		},
	},
	extraReducers: (builder) => {
		builder.addCase(openCashCount.pending, (state) => {
			state.isLoading = true
		})
		builder.addCase(openCashCount.fulfilled, (state, action) => {
			state.idEmployee = action.payload.idEmployee
			state.idManager = action.payload.idManager
			state.idDevice = action.payload.idDevice
			state.isOpenModal = false
			state.isLoading = false
			state.isOpen = true
			state.error = null
		})
		builder.addCase(openCashCount.rejected, (state, action) => {
			state.isLoading = false
			state.error =
				action.error.message || 'Ocurrió un error, intentelo de nuevo.'
		})

		builder.addCase(executeCashCount.pending, (state) => {
			state.isLoadingExecuteCashCount = true
		})
		builder.addCase(executeCashCount.fulfilled, (state, action) => {
			state.feedbackCashCount = 'Arqueo de caja correcto'
			state.dataCashCount = action.payload
			state.errorCashCount = null

			state.isLoadingExecuteCashCount = false
			state.isShowConfirm = false
			state.isShowFeedbackCashCount = true
		})
		builder.addCase(executeCashCount.rejected, (state, action) => {
			console.error(action.error)
		})
	},
})

export const {
	clean,
	toggleModal,
	updateCash,
	updateIsShowConfirm,
	updateIsShowFeedbackCashCount,
	updateErrorCashCount,
	updateFeedbackCashCount,
	updateDataCashCount,
	updateDevice,
} = cashCountSlice.actions

export default cashCountSlice.reducer
