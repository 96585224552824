import React from 'react'
import { PropTypes } from 'prop-types'
import { Link } from 'react-router-dom'
import { formatCurrency } from '../../../utils/functions/money'

ProductItem.propTypes = {
	ItemCode: PropTypes.string,
	ItemName: PropTypes.string,
	AvgPrice: PropTypes.string,
	PicturName: PropTypes.string,
	OnHand: PropTypes.string,
	AvgPriceLiquidation: PropTypes.string,
}

export default function ProductItem({
	ItemCode,
	ItemName,
	AvgPrice,
	PicturName,
	OnHand,
	AvgPriceLiquidation,
}) {
	return (
		<>
			<div
				style={{ background: 'white', paddingRight: 5 + '%' }}
				className='product-list d-flex align-items-center justify-content-between'>
				<div
					className='d-flex align-items-center product-info'
					data-bs-toggle='modal'
					data-bs-target='#products'>
					<Link to='#' className='img-bg' style={{}}>
						<img
							src={`http://thoro-ent.dyndns.info/empleados/imagenes/ITEMS/${PicturName}`}
							alt='Productos'
						/>
					</Link>
				</div>
				<div className='info'>
					<span
						style={{
							color: 'white',
							background: '#e71f85',
							display: 'inline-block',
							padding: '5px 10px',
							borderRadius: 10 + 'px',
						}}>
						{ItemCode}
					</span>
					<h3 style={{ paddingTop: 10 + 'px' }}>
						<Link to='#'>{ItemName}</Link>
					</h3>
					<h3 style={{ paddingTop: 10 + 'px', color: '#e71f85' }}>
						{AvgPriceLiquidation ? (
							<div>
								Precio{' '}
								<span className='text-decoration-line-through'>
									{formatCurrency(AvgPrice)}
								</span>{' '}
								<span>{formatCurrency(AvgPriceLiquidation)}</span>
							</div>
						) : (
							<span>Precio {formatCurrency(AvgPrice)}</span>
						)}
					</h3>
					<h3 style={{ paddingTop: 10 + 'px', color: '#17a2b8' }}>
						Stock disponible: {parseInt(OnHand)}
					</h3>
					<h5 style={{ paddingTop: 20 + 'px', color: 'black' }}>
						Este se encuentra ubicado en:
					</h5>
					<h5 style={{ paddingTop: 5 + 'px', color: 'black' }}>
						Ejemp. Pasillo 1 rack 2
					</h5>
				</div>
			</div>
		</>
	)
}
