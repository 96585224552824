import React, { useEffect, useState } from 'react'
import { sortOptions } from '../../../utils/var'
import { useDispatch } from 'react-redux'
import { changeSorting } from '../../../redux/catalog'
import { Dropdown } from 'react-bootstrap'

export default function OrderBy() {
	const dispatch = useDispatch()
	const [query, setQuery] = useState(null)

	useEffect(() => {
		if (query) {
			dispatch(changeSorting(query.query))
		}
	}, [query])

	return (
		<div className='d-flex flex-column gap-2'>
			<h5>Ordenar por</h5>

			<Dropdown>
				<Dropdown.Toggle id='dropdown-basic'>
					{query ? query.text : 'Seleccionar'}
				</Dropdown.Toggle>

				<Dropdown.Menu>
					{sortOptions.map((option) => (
						<Dropdown.Item key={option.text} onClick={() => setQuery(option)}>
							{option.text}
						</Dropdown.Item>
					))}
				</Dropdown.Menu>
			</Dropdown>
		</div>
	)
}
