import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { couponConfig } from '../utils/var'
import { apiUrl } from '../utils/var'
import { modifyPricesIfCouponApplicated } from '../features/pos/redux/cart' 

export const verifyCartWithCoupon = createAsyncThunk(
	'coupon/verifyCartWithCoupon',
	async (code, { getState, dispatch }) => {
		const { items } = getState().cart

		const requestBody = {
			Items: items.map((item) => {
				return {
					ItemCode: item.ItemCode,
					Quantity: item.quantity,
					price: item.AvgPriceLiquidation
						? item.AvgPriceLiquidation
						: item.AvgPrice,
				}
			}),
			Coupons: [{ Coupon: code }],
		}

		try {
			const url = `${apiUrl}/api/coupons/validate`
			const response = await fetch(url, {
				method: 'POST',
				body: JSON.stringify(requestBody),
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
				},
				credentials: 'include',
			})

			if (!response.ok) {
				let errorMessage
				try {
					const errorBody = await response.json()
					errorMessage = errorBody.message || 'Error desconocido'
				} catch {
					errorMessage =
						'Ocurrió un error al validar el cupón, recargue el punto de venta o contacte a un encargado.'
				}

				const errorInfo = {
					code: response.status,
					message: errorMessage,
					url: url,
				}

				throw new Error(JSON.stringify(errorInfo))
			}

			const responseBody = await response.json()

			const responseBodyDetails = responseBody.data.couponDetails[0]
			const responseBodyItems = responseBody.data.items

			if (responseBodyItems) {
				const couponInformation = {
					code: responseBodyDetails.U_code,
					type: responseBodyDetails.U_discount_type === 'P' ? 'per' : 'mon',
					amount: responseBodyDetails.U_discount_value,
				}

				dispatch(updateCoupon(couponInformation))
				dispatch(modifyPricesIfCouponApplicated(responseBodyItems))
			}

			return responseBody
		} catch (error) {
			let errorMessage
			console.error(error)

			try {
				const errorInfo = JSON.parse(error.message)
				errorMessage = errorInfo.message
			} catch {
				errorMessage =
					'Ocurrió un error al validar el cupón, recargue el punto de venta o contacte a un encargado.'
			}
			throw new Error(errorMessage)
		}
	}
)

/**
 * Slice para manejar el estado de los cupones en la aplicación.
 *
 * Este slice gestiona la información relacionada con los cupones
 * aplicados en el carrito, permitiendo actualizarlos o restablecerlos.
 */
export const couponSlice = createSlice({
	name: 'coupon',
	initialState: couponConfig,

	reducers: {
		updateCouponCode: (state, action) => {
			return {
				...state,
				code: action.payload,
			}
		},
		/**
		 * Actualiza el estado del cupón con la información proporcionada en el payload.
		 *
		 * @param {Object} state - El estado actual del cupón.
		 * @param {Object} action - La acción despachada que contiene el nuevo estado del cupón.
		 */
		updateCoupon: (state, action) => {
			// Asigna todas las propiedades del payload al estado actual del cupón
			Object.assign(state, action.payload)
		},

		/**
		 * Restablece el estado del cupón a su configuración inicial.
		 *
		 * @returns {Object} - El estado inicial del cupón definido en couponConfig.
		 */
		eraseCoupon: () => {
			// Retorna el estado inicial del cupón
			return couponConfig
		},
	},
	extraReducers: (builder) => {
		builder.addCase(verifyCartWithCoupon.pending, (state) => {
			state.isLoadingValidate = true
			state.errorValidate = null
		})
		builder.addCase(verifyCartWithCoupon.fulfilled, (state) => {
			state.isLoadingValidate = false
			state.errorValidate = null
		})
		builder.addCase(verifyCartWithCoupon.rejected, (state, action) => {
			state.isLoadingValidate = false
			state.errorValidate =
				action.error.message ||
				'Ocurrió un error, recargue la página por favor.'
		})
	},
})

// Exporta las acciones generadas por el slice para que puedan ser despachadas desde los componentes
export const { updateCouponCode, updateCoupon, eraseCoupon } =
	couponSlice.actions

// Exporta el reducer para que pueda ser incluido en el store
export default couponSlice.reducer
