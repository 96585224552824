import React from 'react'
import { ListGroup, Image, Badge, Modal, Button } from 'react-bootstrap'
import { formatCurrency } from '../../../utils/functions/money'
import { useContext } from 'react'
import { OrdersCashCountContext } from '../provider/OrdersCashCount'

export default function OrderItems() {
	const { itemsModal, isItemsShow, updateIsItemsShow } = useContext(
		OrdersCashCountContext
	)

	return (
		<Modal centered size='lg' show={isItemsShow}>
			<Modal.Body>
				<ListGroup
					className='overflow-auto'
					style={{
						maxHeight: '75vh',
					}}>
					{itemsModal.map((item) => (
						<ListGroup.Item
							key={item.ItemCode + Math.random()}
							as='li'
							className='d-flex justify-content-between align-items-start py-2'>
							<Image
								src={`http://thoro-ent.dyndns.info/empleados/imagenes/ITEMS/${item.PicturName}`}
								height={40}
								width={40}
								roundedCircle
							/>
							<div className='mx-2 me-auto d-flex flex-column'>
								<span className='fw-bold fs-6'>{item.Dscription}</span>
								<span>{formatCurrency(item.Price)}</span>
							</div>
							<Badge bg='primary' pill className='ms-3 fs-6'>
								{item.Quantity}
							</Badge>
						</ListGroup.Item>
					))}
				</ListGroup>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={() => updateIsItemsShow(false)}>Cerrar</Button>
			</Modal.Footer>
		</Modal>
	)
}
