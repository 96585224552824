import React, { createContext, useState } from 'react'
import { apiUrl } from '../utils/var'
import { formatCurrency } from '../utils/functions/money'
import { formatDataCreateCoupon } from '../utils/functions/formatData'
/* import { formatDataCreateCoupon } from '../utils/functions/formatData' */
/* import {
	convertDateToMilitaryTimeFormat,
	formatHHmmToDateObjectJs,
} from '../utils/functions/date' */

export const CouponsContext = createContext(undefined)

// eslint-disable-next-line react/prop-types
const CouponsProvider = ({ children }) => {
	const apiEndpoint = `${apiUrl}/api`
	const [brands, setBrands] = useState([])
	const [isLoadingBrands, setIsLoadingBrands] = useState(false)
	const [dataApplicabilityBrands, setDataApplicabilityBrands] = useState([])
	const [applicabilityBrands, setApplicabilityBrands] = useState([])
	const [isApplicableBrands, setIsApplicableBrands] = useState(true)
	const [errorBrands, setErrorBrands] = useState(null)

	const [items, setItems] = useState([])
	const [isLoadingItems, setIsLoadingItems] = useState(false)
	const [dataApplicabilityItems, setDataApplicabilityItems] = useState([])
	const [applicabilityItems, setApplicabilityItems] = useState([])
	const [isApplicableItems, setIsApplicableItems] = useState(true)
	const [errorItems, setErrorItems] = useState(null)

	const [lines, setLines] = useState([])
	const [isLoadingLinesAndSublines, setIsLoadingLinesAndSublines] =
		useState(false)
	const [errorLines, setErrorLines] = useState(null)
	const [
		dataApplicabilityLinesAndSublines,
		setDataApplicabilityLinesAndSublines,
	] = useState([])
	const [applicabilitiesLinesAndSublines, setApplicabilitiesLinesAndSublines] =
		useState([])
	const [isApplicableLinesAndSublines, setIsApplicableLinesAndSublines] =
		useState(true)

	const [activeCoupon, setActiveCoupon] = useState(null)
	const [dataActiveCoupon, setDataActiveCoupon] = useState(null)
	const [dataDetailCoupon, setDataDetailCoupon] = useState(null)
	const [isLoadingShowCoupon, setIsLoadingShowCoupon] = useState(null)
	const [isShowDetailModal, setIsShowDetailModal] = useState(false)
	const [errorActiveCoupon, setErrorActiveCoupon] = useState(null)

	const [dataUpdateCoupon, setDataUpdateCoupon] = useState(null)
	const [dataUpdateFormatCoupon, setDataUpdateFormatCoupon] = useState(null)
	const [isLoadingUpdateCoupon, setIsLoadingUpdateCoupon] = useState(false)
	const [errorUpdateCoupon, setErrorUpdateCoupon] = useState(false)

	const [data, setData] = useState({})
	const [isAllItems, setIsAllItems] = useState(true)
	const [searchCoupon, setSearchCoupon] = useState('')
	const [coupons, setCoupons] = useState([])
	const [isShowModalForm, setIsShowModalForm] = useState(false)
	const [isCreate, setIsCreate] = useState(true)
	const [isLoading, setIsLoading] = useState(false)
	const [isLoadingCreate, setIsLoadingCreate] = useState(false)
	const [error, setError] = useState(null)
	const [feedback, setFeedback] = useState(null)
	const [applicabilities, setApplicabilities] = useState({
		brands: false,
		linesAndSublines: false,
		items: false,
	})

	const getBrands = async () => {
		setIsLoadingBrands(true)

		try {
			const requestGetBrands = await fetch(`${apiEndpoint}/brands`, {
				method: 'GET',
				credentials: 'include',
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
				},
			})

			const responseGetBrands = await requestGetBrands.json()
			if (!requestGetBrands.ok) {
				console.error(responseGetBrands.message)

				setErrorBrands({
					status: requestGetBrands.status,
					message: responseGetBrands.message,
				})
			} else {
				setBrands(responseGetBrands.data)
				setErrorBrands(null)
				setIsLoadingBrands(false)
			}
		} catch (error) {
			console.error(error)

			setErrorBrands({
				status: 'Network error',
				message: error.message,
			})
			setIsLoadingBrands(false)
		} finally {
			setIsLoadingBrands(false)
		}
	}

	const getItems = async () => {
		setIsLoadingItems(true)
		try {
			const requestGetItems = await fetch(`${apiEndpoint}/items`, {
				method: 'GET',
				credentials: 'include',
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
				},
			})

			const responseGetItems = await requestGetItems.json()

			if (!requestGetItems.ok) {
				console.error(responseGetItems.message)

				setErrorItems({
					status: requestGetItems.status,
					message: responseGetItems.message,
				})
			} else {
				setItems(responseGetItems.data)
				setErrorItems(null)
				setIsLoadingItems(false)
			}
		} catch (error) {
			console.error(error)

			setErrorItems({
				status: 'Network error',
				message: error.message,
			})
			setIsLoadingItems(false)
		} finally {
			setIsLoadingItems(false)
		}
	}

	const getLines = async () => {
		setIsLoadingLinesAndSublines(true)
		try {
			const requestGetLines = await fetch(
				`${apiEndpoint}/items/getCategories`,
				{
					method: 'GET',
					credentials: 'include',
					headers: {
						'Content-Type': 'application/json',
						'Access-Control-Allow-Origin': '*',
					},
				}
			)

			const responseGetLines = await requestGetLines.json()

			if (!requestGetLines.ok) {
				console.error(responseGetLines.message)

				setErrorLines({
					status: requestGetLines.status,
					message: responseGetLines.message,
				})
			} else {
				setLines(responseGetLines)
				setErrorLines(null)
				setIsLoadingLinesAndSublines(false)
			}
		} catch (error) {
			console.error(error)

			setErrorLines({
				status: 'Network error',
				message: error.message,
			})
			setIsLoadingLinesAndSublines(false)
		} finally {
			setIsLoadingLinesAndSublines(false)
		}
	}

	const getCoupons = async () => {
		setError(null)
		setIsLoading(true)
		try {
			const requestCoupons = await fetch(`${apiEndpoint}/coupons`, {
				method: 'GET',
				credentials: 'include',
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
				},
			})

			const responseCoupons = await requestCoupons.json()

			if (!requestCoupons.ok) {
				console.error(responseCoupons.message)

				setError({
					status: requestCoupons.status,
					message: responseCoupons.message,
				})
			} else {
				const selectCouponData = responseCoupons.data.map((coupon) => {
					return {
						idCoupon: coupon.DocEntry,
						U_code: coupon.U_code,
						U_discount_type: coupon.U_discount_type,
						U_discount_value: `${coupon.U_discount_type === 'M'
								? formatCurrency(coupon.U_discount_value)
								: `${coupon.U_discount_value}%`
							}`,
						U_start_date: coupon.U_start_date,
						U_end_date: coupon.U_end_date,
						U_start_time: coupon.U_start_time,
						U_end_time: coupon.U_end_time,
						Uso: coupon.Uso,
						U_max_usage: coupon.U_max_usage,
						U_description: coupon.U_description,
						U_status: coupon.U_status === 'A' ? 'Activo' : 'Inactivo',
					}
				})
				setCoupons(selectCouponData)
				setError(null)
				setIsLoading(false)
			}
		} catch (error) {
			console.error(error)

			setError({
				status: 'Network error',
				message: error.message,
			})
			setIsLoading(false)
		} finally {
			setIsLoading(false)
		}
	}

	const getCoupon = async () => {
		if (activeCoupon && activeCoupon > 0) {
			setErrorActiveCoupon(null)
			setIsLoadingShowCoupon(true)

			try {
				const requestCoupon = await fetch(
					`${apiEndpoint}/coupons?docentry=${activeCoupon}`,
					{
						method: 'GET',
						credentials: 'include',
						headers: {
							'Content-Type': 'application/json',
							'Access-Control-Allow-Origin': '+',
						},
					}
				)

				const responseCoupon = await requestCoupon.json()

				if (!requestCoupon.ok) {
					console.error(responseCoupon.message)

					setErrorActiveCoupon({
						status: requestCoupon.status,
						message: responseCoupon.message,
					})
				} else {
					setDataActiveCoupon(responseCoupon.data)
					setIsLoadingShowCoupon(false)
					setErrorActiveCoupon(null)
				}
			} catch (error) {
				console.error(error)

				setErrorActiveCoupon({
					status: 'Network Error',
					message: error.message,
				})
				setIsLoadingShowCoupon(false)
			} finally {
				setIsLoadingShowCoupon(false)
			}
		}
	}

	const prepareDataCreateCoupon = async () => {
		setIsLoadingCreate(true)
		console.log(data)

		try {
			if (!activeCoupon) {
				await createCoupon(formatDataCreateCoupon(data))
			} else {
				await modifyCoupon(formatDataCreateCoupon(data))
			}
		} catch (error) {
			console.error(error)
			setFeedback('error')
			setError('Error en la ejecución')
			setIsLoadingCreate(false)
		} finally {
			setIsLoadingCreate(false)
		}
	}

	const updateSearchCoupon = (event) => {
		setSearchCoupon(event.target.value)
	}

	const updateCoupons = (value) => {
		setCoupons(value)
	}

	const updateIsShowModalForm = (value) => {
		setIsShowModalForm(value)
	}

	const updateIsCreate = (value) => {
		setIsCreate(value)
	}

	const updateIsLoading = (value) => {
		setIsLoading(value)
	}

	const updateError = (value) => {
		setError(value)
	}
	const updateApplicabilities = (key, value) => {
		setApplicabilities((prev) => ({
			...prev,
			[key]: value,
		}))
	}

	const updateBrands = (value) => {
		setBrands(value)
	}

	const updateIsApplicableBrands = (value) => {
		setIsApplicableBrands(value)
	}

	const updateDataApplicabilityBrands = (value) => {
		setDataApplicabilityBrands(value)
	}

	const updateApplicabilityBrands = (value) => {
		setApplicabilityBrands(value)
	}

	const updateErrorBrands = (value) => {
		setErrorBrands(value)
	}

	const updateIsLoadingBrands = (value) => {
		setIsLoadingBrands(value)
	}

	const updateItems = (value) => {
		setItems(value)
	}

	const updateIsApplicableItems = (value) => {
		setIsApplicableItems(value)
	}

	const updateDataApplicabilityItems = (value) => {
		setDataApplicabilityItems(value)
	}

	const updateApplicabilityItems = (value) => {
		setApplicabilityItems(value)
	}

	const updateErrorItems = (value) => {
		setErrorItems(value)
	}

	const updateIsLoadingItems = (value) => {
		setIsLoadingItems(value)
	}

	const updateIsApplicableLinesAndSublines = (value) => {
		setIsApplicableLinesAndSublines(value)
	}

	const updateDataApplicabilityLinesAndSublines = (value) => {
		setDataApplicabilityLinesAndSublines(value)
	}

	const updateApplicabilitiesLinesAndSublines = (value) => {
		setApplicabilitiesLinesAndSublines(value)
	}
	const updateIsLoadingLinesAndSublines = (value) => {
		setIsLoadingLinesAndSublines(value)
	}

	const updateData = (values) => {
		setData(values)
	}

	const updateFeedback = (value) => {
		setFeedback(value)
	}

	const updateIsAllItems = (value) => {
		setIsAllItems(value)
	}

	const updateActiveCoupon = (value) => {
		setActiveCoupon(value)
	}

	const updateIsLoadingShowCoupon = (value) => {
		setIsLoadingShowCoupon(value)
	}

	const updateIsShowDetailModal = (value) => {
		setIsShowDetailModal(value)
	}

	const updateDataActiveCoupon = (value) => {
		setDataActiveCoupon(value)
	}

	const updateErrorActiveCoupon = (value) => {
		setErrorActiveCoupon(value)
	}

	const updateIsLoadingUpdateCoupon = (value) => {
		setIsLoadingUpdateCoupon(value)
	}

	const updateErrorUpdateCoupon = (value) => {
		setErrorUpdateCoupon(value)
	}

	const updateDataDetailCoupon = (value) => {
		setDataDetailCoupon(value)
	}

	const updateDataUpdateCoupon = (value) => {
		setDataUpdateCoupon(value)
	}

	const updateIsLoadingCreate = (value) => {
		setIsLoadingCreate(value)
	}

	const updateDataUpdateFormatCoupon = (value) => {
		setDataUpdateFormatCoupon(value)
	}

	const resetApplicabilities = () => {
		updateApplicabilities('brands', false)
		updateApplicabilities('linesAndSublines', false)
		updateApplicabilities('items', false)
	}

	const createCoupon = async (data) => {
		try {
			const requestCreateCoupon = await fetch(`${apiEndpoint}/coupons`, {
				method: 'POST',
				body: JSON.stringify(data),
				credentials: 'include',
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
				},
			})

			const responseCreateCoupon = await requestCreateCoupon.json()
			if (!requestCreateCoupon.ok) {
				console.error(responseCreateCoupon.message)

				setError({
					status: requestCreateCoupon.status,
					message: responseCreateCoupon.message,
				})
			} else {
				setFeedback('Cupón creado correctamente.')
				setError(null)
			}
		} catch (error) {
			console.error(error)

			setError({
				status: 'Network error',
				message: error.message,
			})
			setIsLoadingCreate(false)
		} finally {
			setIsLoadingCreate(false)
		}
	}

	const modifyCoupon = async (data) => {
		try {
			const requestUpdateCoupon = await fetch(
				`${apiEndpoint}/coupons/${activeCoupon}`,
				{
					method: 'PUT',
					body: JSON.stringify(data),
					credentials: 'include',
					headers: {
						'Content-Type': 'application/json',
						'Access-Control-Allow-Origin': '*',
					},
				}
			)

			const responseUpdateCoupon = await requestUpdateCoupon.json()
			if (!requestUpdateCoupon.ok) {
				console.error(responseUpdateCoupon.message)

				setError({
					status: requestUpdateCoupon.status,
					message: responseUpdateCoupon.message,
				})
			} else {
				setFeedback('Cupón actualizado correctamente.')
				setError(null)
			}
		} catch (error) {
			console.error(error)

			setError({
				status: 'Network error',
				message: error.message,
			})
			setIsLoadingCreate(false)
		} finally {
			setIsLoadingCreate(false)
		}
	}

	return (
		<CouponsContext.Provider
			value={{
				searchCoupon,
				coupons,
				feedback,
				isShowModalForm,
				isCreate,
				isLoading,
				error,
				applicabilities,
				brands,
				applicabilityBrands,
				dataApplicabilityBrands,
				isApplicableBrands,
				errorBrands,
				isLoadingBrands,
				isAllItems,
				items,
				dataApplicabilityItems,
				applicabilityItems,
				isApplicableItems,
				errorItems,
				isLoadingItems,
				lines,
				isApplicableLinesAndSublines,
				dataApplicabilityLinesAndSublines,
				applicabilitiesLinesAndSublines,
				errorLines,
				isLoadingLinesAndSublines,
				data,
				activeCoupon,
				isLoadingShowCoupon,
				isShowDetailModal,
				isLoadingUpdateCoupon,
				isLoadingCreate,
				dataActiveCoupon,
				errorActiveCoupon,
				errorUpdateCoupon,
				dataDetailCoupon,
				dataUpdateCoupon,
				dataUpdateFormatCoupon,
				setIsLoadingLinesAndSublines,
				getCoupons,
				getBrands,
				getItems,
				getLines,
				prepareDataCreateCoupon,
				updateSearchCoupon,
				updateCoupons,
				updateIsShowModalForm,
				updateIsCreate,
				updateIsLoading,
				updateErrorBrands,
				updateErrorItems,
				updateError,
				updateApplicabilities,
				updateBrands,
				updateApplicabilityBrands,
				updateDataApplicabilityBrands,
				updateIsApplicableBrands,
				updateIsLoadingBrands,
				updateItems,
				updateApplicabilityItems,
				updateDataApplicabilityItems,
				updateIsApplicableItems,
				updateIsLoadingItems,
				updateIsApplicableLinesAndSublines,
				updateDataApplicabilityLinesAndSublines,
				updateApplicabilitiesLinesAndSublines,
				updateIsLoadingLinesAndSublines,
				updateData,
				updateIsAllItems,
				updateFeedback,
				updateActiveCoupon,
				updateIsLoadingShowCoupon,
				updateIsShowDetailModal,
				updateDataActiveCoupon,
				updateErrorActiveCoupon,
				updateIsLoadingUpdateCoupon,
				updateErrorUpdateCoupon,
				updateDataDetailCoupon,
				updateDataUpdateCoupon,
				updateIsLoadingCreate,
				updateDataUpdateFormatCoupon,
				getCoupon,
				resetApplicabilities,
			}}>
			{children}
		</CouponsContext.Provider>
	)
}

export default CouponsProvider
