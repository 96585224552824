import React, { useState } from "react";
import generateTicket from "../../../utils/functions/ticket";

export default function Impresora() {
    const [base64, setBase64] = useState('');
    const [message, setMessage] = useState('');

  
    const onGenerateTicket = async (output) => {
      setBase64('');
      setMessage('');
  
      const response = await generateTicket(output);
  
      if (!response?.success) {
        alert(response?.message);
        return;
      }
  
      if (output === 'b64') {
        setBase64(response?.content ?? '');
      }
  
      setMessage(response?.message);
  
      setTimeout(() => {
        setMessage('');
      }, 2000);
    };
  
    return (
      <div className="mx-main-container">
        <div className="mx-btn-container">
          <button
            className="mx-btn-primary"
            onClick={() => onGenerateTicket('b64')}
          >
            GENERAR TICKET
          </button>
          <button
            className="mx-btn-secondary"
            onClick={() => onGenerateTicket('print')}
          >
            IMPRIMIR TICKET
          </button>
        </div>
        {message && <p className="mx-alert-info">{message}</p>}
        {base64 && (
          <div style={{width:500+'px', height:500+'px'}}>
            <iframe
              src={`data:application/pdf;base64,${base64}`}
              className="mx-iframe"
              style={{width:500+'px', height:500+'px'}}
            />
          </div>
        )}
      </div>
    );
  }