import React, { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import { useDispatch } from 'react-redux'
import { removeItem, modifyQuantity } from '../redux/cart'
import { Minus, Plus, Trash2 } from 'react-feather'
import { addItem, removeOneItem } from '../redux/cart'
import { formatCurrency } from '../../../utils/functions/money'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

CartItem.propTypes = {
	Index: PropTypes.number,
	ItemCode: PropTypes.string,
	ItemName: PropTypes.string,
	AvgPrice: PropTypes.number,
	AvgPriceLiquidation: PropTypes.number,
	ItemQuantity: PropTypes.number,
	PictureName: PropTypes.string,
	item: PropTypes.object,
}

export default function CartItem({
	Index,
	ItemCode,
	ItemName,
	AvgPrice,
	AvgPriceLiquidation,
	ItemQuantity,
	PictureName,
	item,
}) {
	const [quantity, setQuantity] = useState(ItemQuantity)
	const dispatch = useDispatch()

	const handleQuantity = (event) => {
		const value =
			event.target.value === '' ? '' : parseInt(event.target.value, 10)

		if (value === '') {
			setQuantity('')
		} else if (value > 0 && value <= item.OnHand) {
			setQuantity(value)
			dispatch(modifyQuantity({ itemCode: item.ItemCode, quantity: value }))
		}
	}

	const handleBlur = () => {
		if (quantity === '') {
			dispatch(removeItem(item.ItemCode))
		}
	}

	useEffect(() => {
		setQuantity(ItemQuantity)
	}, [ItemQuantity])

	return (
		<>
			<div className='d-flex align-items-center justify-content-between w-100 py-3 pe-3 overflow-hidden position-relative'>
				{AvgPriceLiquidation && (
					<div className='ribbone ribbone-top-left text-danger'>
						<span className='bg-danger'>Liquidación</span>
					</div>
				)}
				<img
					src={
						PictureName
							? `http://thoro-ent.dyndns.info/empleados/imagenes/ITEMS/${PictureName}`
							: 'assets/img/media/Lapy.jpg'
					}
					alt='Productos'
					className='w-25 object-fit-content'
				/>
				<div className='d-flex flex-column gap-2'>
					<span
						className='text-uppercase'
						style={{
							backgroundColor: '#e71f85',
							borderRadius: 5 + 'px',
							color: 'white',
							width: 20 + '%',
							paddingLeft: 1.5 + '%',
						}}>
						{ItemCode}
					</span>
					<h6 className='w-75 overflow-hidden'>
						<OverlayTrigger
							placement='top'
							overlay={<Tooltip id='tooltip-dark'>{ItemName}</Tooltip>}
							offset={[0, 15]}>
							<p
								data-bs-toggle='tooltip'
								data-bs-custom-class='tooltip-primary'
								style={{
									width: 100 + '%',
									overflow: 'hidden',
									display: '-webkit-box',
									WebkitBoxOrient: 'vertical',
									WebkitLineClamp: '2',
								}}>
								{ItemName}
							</p>
						</OverlayTrigger>
					</h6>
					<div className='d-flex' style={{ width: '300px' }}>
						{item.AvgPriceLiquidation ? (
							<div className='d-flex flex-column justify-content-end align-items-end'>
								<p>
									c/u: {formatCurrency(item.AvgPriceLiquidation)} x{' '}
									{ItemQuantity} pzas.
								</p>
							</div>
						) : (
							<p>
								c/u: {formatCurrency(AvgPrice)} x {ItemQuantity} pzas.
							</p>
						)}
						<p
							className='text-black fw-bold'
							style={{
								paddingLeft: '10%',
							}}>
							Total:{' '}
							{formatCurrency(
								(item.AvgPriceLiquidation
									? item.AvgPriceLiquidation
									: item.AvgPrice) * ItemQuantity
							)}
						</p>
					</div>
				</div>
				<div className='d-flex flex-column justify-content-center align-items-end gap-4'>
					<div
						style={{ display: 'inline-flex' }}
						className='h-10 align-items-center gap-2'>
						<button
							style={{ borderRadius: 6 + 'px' }}
							onClick={() => dispatch(removeOneItem(item))}
							className='bg-info border-0 p-1'>
							<Minus />
						</button>
						<input
							type='text'
							value={quantity}
							onBlur={(event) => handleBlur(event)}
							onChange={(event) => handleQuantity(event)}
							className='fw-bold text-center'
							style={{
								width: '2rem',
								WebkitAppearance: 'none',
								MozAppearance: 'textfield',
								appearance: 'none',
								padding: '0',
								border: 'none',
								fontSize: 20 + 'px',
							}}
							onInput={(event) => {
								// Asegúrate de que el valor ingresado sea un número
								const value = event.target.value
								if (!/^\d*$/.test(value)) {
									event.target.value = value.slice(0, -1)
								}
							}}
						/>
						<button
							style={{ borderRadius: '6px' }}
							onClick={() => dispatch(addItem(item))}
							className='bg-info border-0 p-1'>
							<Plus />
						</button>
					</div>
					<button
						style={{
							borderRadius: '15px',
							width: '35px',
							height: '35px',
							marginTop: '5px',
						}}
						onClick={() => dispatch(removeItem(Index))}
						className='text-white bg-primary border-0 p-0 h6'>
						<Trash2 />
					</button>
				</div>
			</div>
		</>
	)
}
