export const selectWithdrawIsShowModalWithdraw = (state) =>
	state.withdraw.isShowModalWithdraw
export const selectWithdrawIsInWithdraw = (state) => state.withdraw.isInWithdraw
export const selectSaldoAcumulado = (state) => state.withdraw.amountCashDrawer
export const selectSaldoRetirado = (state) => state.withdraw.withDrawalAmount
export const selectGerente = (state) => state.withdraw.gerente
export const selectHora = (state) => state.withdraw.hora
export const selectFecha = (state) => state.withdraw.fecha
export const selectIsLoading = (state) => state.withdraw.isLoading
export const selectError = (state) => state.withdraw.error
export const selectStatus = (state) => state.withdraw.status
export const selectPlace = (state) => state.withdraw.place
