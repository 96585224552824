import React, { useEffect, useState } from 'react'
import InputBlock from './InputBlock'
import { useDispatch, useSelector } from 'react-redux'
import { searchFolio, setDataSaleStatus } from '../redux/new-sale'
import { Form } from 'react-bootstrap'
import { apiUrl } from '../../../utils/var'
import { Button } from 'antd'
import Swal from 'sweetalert2'
// import Swal from "sweetalert2";

const StoreDataSection = () => {
	const dispatch = useDispatch()
	const [input1, setInput1] = useState('')
	const [input2, setInput2] = useState('')
	const [errorValidation, setErrorValidation] = useState(false)
	const [isLoadingEmployees, setIsLoadingEmployees] = useState(false)
	const [errorEmployees, setErrorEmployees] = useState(null)
	const [cashiers, setCashiers] = useState(null)

	const {
		isNotRegisteredOrder,
		loadingFolioVenta,
		error,
		folioVenta,
		isDataSaleComplete,
	} = useSelector((state) => state.sale)

	const handleConfirmSaleData = async () => {
		console.log('tamos dentro mi gente')
		dispatch(setDataSaleStatus({ status: true }))
		const initialData = {
			folioVenta: folioVenta,
			cashier: {
				id: 12,
				name: 'Juan',
			},
		}
		console.log(initialData)
	}

	const handleSearch = () => {
		if (input1.trim()) {
			/* dispatch(setFolioVenta({ folioVenta: input1 })) */
			dispatch(searchFolio(input1))
		}
	}

	const showErrorAlert = async (error) => {
		await Swal.fire({
			title: 'Error',
			text: error || 'Error desconocido',
			icon: 'error',
			footer: '<a href="#">¿Por qué tengo este problema?</a>',
			showConfirmButton: true,
		})
	}

	const handleInput1Change = (e) => {
		setInput1(e.target.value)
		if (input2 !== '' && e.target.value !== input2) {
			setErrorValidation(true)
		} else {
			setErrorValidation(false)
		}
	}

	const handleInput2Change = (e) => {
		setInput2(e.target.value)
		if (input1 !== '' && e.target.value !== input1) {
			setErrorValidation(true)
		} else {
			setErrorValidation(false)
		}
	}

	const getEmployees = async () => {
		setIsLoadingEmployees(true)
		const requestGetEmployees = await fetch(
			`${apiUrl}/api/cash-count/get-cashiers`,
			{
				method: 'GET',
				credentials: 'include',
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
				},
			}
		)

		setIsLoadingEmployees(false)
		const responseGetEmployees = await requestGetEmployees.json()

		if (!requestGetEmployees.ok) {
			console.error(responseGetEmployees.message)

			setErrorEmployees({
				status: responseGetEmployees.status,
				message: responseGetEmployees.message,
			})
		} else {
			console.log(responseGetEmployees.data)
			setCashiers(responseGetEmployees.data)
		}
	}

	useEffect(() => {
		getEmployees()
	}, [])

	useEffect(() => {
		console.log(folioVenta)
	}, [folioVenta])

	useEffect(() => {
		if (error) {
			showErrorAlert(error) // Mostrar alerta cuando haya un error
		}
	}, [error])

	useEffect(() => {
		console.log(errorValidation)
		console.log(errorEmployees)
	}, [errorValidation, errorEmployees])

	return (
		<div className='barcode-content-list' style={{ marginBottom: '15px' }}>
			<span>isNotRegisteredOrder: {isNotRegisteredOrder ? 'Si' : 'No'}</span>
			<br />
			<span>errorValidation: {errorValidation ? 'Si' : 'No'}</span>
			<br />
			<span>loadingFolioVenta: {loadingFolioVenta ? 'Si' : 'No'}</span>
			<br />
			<span>folioVenta: {folioVenta}</span>

			<div className='page-title'>
				<h4>BUSCAR FOLIO</h4>
			</div>
			<div className='col-lg-12'>
				<div className='row'>
					<div className='col-lg-4'>
						<Form.Group controlId='input1'>
							<Form.Label>Escribe el folio de venta</Form.Label>
							<Form.Control
								type='text'
								placeholder='Folio de venta'
								value={input1}
								onChange={handleInput1Change}
								isInvalid={errorValidation} // Campo inválido si hay error y no está registrado.
								isValid={isNotRegisteredOrder}
								defaultValue={isDataSaleComplete ? folioVenta : ''}
							/>
							<Form.Control.Feedback type='invalid'>
								Los folios no coinciden.
							</Form.Control.Feedback>
						</Form.Group>
					</div>
					<div className='col-lg-4'>
						<Form.Group controlId='input2'>
							<Form.Label>Vuelve a escribir el folio de venta</Form.Label>
							<Form.Control
								type='text'
								placeholder='Folio de venta'
								value={input2}
								onChange={handleInput2Change}
								isInvalid={errorValidation} // Campo inválido si hay error y no está registrado.
								isValid={isNotRegisteredOrder}
							/>
							<Form.Control.Feedback type='invalid'>
								Los folios no coinciden.
							</Form.Control.Feedback>
						</Form.Group>
					</div>
					<div className='col-lg-4 d-flex align-items-end'>
						<Button
							type='submit'
							className='btn btn-submit'
							disabled={errorValidation || !input1 || !input2}
							onClick={handleSearch}
							loading={loadingFolioVenta}
							style={{ height: 'auto' }}>
							Buscar
						</Button>
					</div>
				</div>
			</div>
			<br />
			<div className='page-title'>
				<h4>DATOS DE LA TIENDA</h4>
			</div>
			<div className='row'>
				<div className='col-lg-6'>
					<InputBlock
						label='Razón'
						type='text'
						isDisabled={true}
						value={!isNotRegisteredOrder ? '' : 'Thoro'}
					/>
				</div>
				<div className='col-lg-6'>
					<InputBlock
						label='Tienda'
						type='text'
						isDisabled={true}
						value={!isNotRegisteredOrder ? '' : 'La Pape'}
					/>
				</div>
			</div>
			<div className='col-lg-12'>
				<InputBlock
					label='Cajero'
					type='select'
					isDisabled={!isNotRegisteredOrder}
					options={cashiers}
					isLoading={isLoadingEmployees}
					onChange={(event) => {
						console.log(event.target.value)
					}}
				/>
			</div>

			<div className='modal-footer-btn'>
				<button
					type='submit'
					className='btn btn-submit'
					disabled={!isNotRegisteredOrder}
					onClick={handleConfirmSaleData}>
					Confirmar
				</button>
			</div>
		</div>
	)
}

export default StoreDataSection
