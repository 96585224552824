import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { formatCurrency } from '../../../utils/functions/money'
import { selectCart } from '../../../redux/selectors/cart'
import { Table } from 'antd'
import { tableOrderResumeItems } from '../../../utils/datatablesVar'

export default function ModalOrderList() {
	const cart = useSelector(selectCart)
	const contenedorRef = useRef(null)

	useEffect(() => {
		if (contenedorRef.current) {
			contenedorRef.current.scrollTop = contenedorRef.current.scrollHeight
		}
	}, [cart])

	return (
		<div className='table-responsive no-pagination'>
			<div className='table-responsive'>
				<h3 className='mb-2'>Productos</h3>
				<Table
					pagination={false}
					bordered={true}
					columns={tableOrderResumeItems}
					rowKey={'itemCode'}
					dataSource={cart.map((item) => {
						const cartFormatted = {
							itemCode: item.ItemCode,
							image: `http://thoro-ent.dyndns.info/empleados/imagenes/ITEMS/${item.PicturName}`,
							name: item.ItemName,
							quantity: item.quantity,
							prices: {
								priceLiquidation: item.AvgPriceLiquidation
									? formatCurrency(item.AvgPriceLiquidation)
									: null,
								priceWithDiscount: item.couponPrice
									? formatCurrency(item.couponPrice)
									: null,
								price: formatCurrency(item.AvgPrice),
							},
							totalByProduct: formatCurrency(
								item.quantity *
									(item.couponPrice
										? item.couponPrice
										: item.AvgPriceLiquidation
										? item.AvgPriceLiquidation
										: item.AvgPrice)
							),
						}

						return cartFormatted
					})}
				/>
			</div>
		</div>
	)
}
