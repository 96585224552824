import React from 'react'
import { useDispatch } from 'react-redux'
import { logout } from '../redux/authentication'

export default function NotMachineConfigured() {
	const dispatch = useDispatch()

	return (
		<main>
			<section className='vh-100 w-100 d-flex flex-column justify-content-center align-items-center'>
				<h1>
					No se ha configurado un dispositivo, contacte a un administrador para
					que lo haga.
				</h1>

				<button
					onClick={() => {
						dispatch(logout())
					}}
					type='button'
					className='w-25'>
					cerrar sesion
				</button>
			</section>
		</main>
	)
}
