import React, { useEffect, useState } from 'react'
import { Table } from 'antd'
import { PropTypes } from 'prop-types'
import { apiUrl } from '../../../../utils/var'
import { convertMilitaryTimeToStandardTimeFormat } from '../../../../utils/functions/date'
import { tableCashCountColumns } from '../../../../utils/datatablesVar'
import { formatCurrency } from '../../../../utils/functions/money'
import Error404 from '../../../../feature-module/pages/errorpages/error404'

CashCountHistoryTable.propTypes = {
	idDevice: PropTypes.string,
}

export default function CashCountHistoryTable({ idDevice }) {
	const [device] = useState(idDevice)
	const [cashCounts, setCashCounts] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState(false)

	const formatCashCount = (rawCashCount) => {
		return {
			folio: rawCashCount.Folio,
			dateTimeCreated: `${rawCashCount.Date_Created.substring(
				0,
				10
			)} ${convertMilitaryTimeToStandardTimeFormat(rawCashCount.Time_Created)}`,
			user: rawCashCount.User,
			dateTimeClose: `${rawCashCount.Date_Closed
				? rawCashCount.Date_Closed.substring(0, 10)
				: 'No disponible'
				} ${convertMilitaryTimeToStandardTimeFormat(rawCashCount.Time_Closed)}`,
			totalSales: rawCashCount.Total_Due
				? formatCurrency(rawCashCount.Total_Due)
				: 'No disponible',
			totalSurrended: rawCashCount.Total_Surrendered
				? formatCurrency(rawCashCount.Total_Surrendered)
				: 'No disponible',
			difference: rawCashCount.Difference
				? formatCurrency(-1 * rawCashCount.Difference)
				: 'No disponible',
			status:
				rawCashCount.Canceled === 'Y'
					? 'Cancelado'
					: rawCashCount.Status === 'C'
						? 'Cerrado'
						: 'Abierto',
		}
	}

	const getCashCounts = async () => {
		setIsLoading(true)
		try {
			const requestCashCounts = await fetch(`${apiUrl}/api/cash-count`, {
				method: 'GET',
				credentials: 'include',
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
				},
			})
			const responseCashCounts = await requestCashCounts.json()

			if (!requestCashCounts.ok) {

				console.error(responseCashCounts.message)
				setError(true)
				throw new Error(responseCashCounts.message);
			} else {
				setError(false)
				setCashCounts(
					responseCashCounts.data.map((cashCount) => formatCashCount(cashCount))
				)
			}
			setIsLoading(false)
		} catch (error) {
			setError(error.message);
		} finally {
			setIsLoading(false);
		}
	}

	useEffect(() => {
		getCashCounts()
	}, [])

	useEffect(() => {
		getCashCounts()
	}, [device])

	return (
		(!error) ?
			<Table
				className='w-100 '
				rowKey='idOrden'
				columns={tableCashCountColumns()}
				dataSource={cashCounts}
				loading={isLoading}
			/> :
			<Error404 />


	)
}
