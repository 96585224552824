import React from 'react'
import { PropTypes } from 'prop-types'

PageTitle.propTypes = {
    maintitle: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    toolbar: PropTypes.node
};
export default function PageTitle({ maintitle, subtitle, toolbar}) {
    return (
        <div className="page-header" style={{marginBlockEnd: '1%'}}>
            <div className="add-item d-flex">
                <div className="page-title">
                    <h4>{maintitle}</h4>
                    <h6>{subtitle}</h6>
                </div>
                
            </div>
           {toolbar && (  // Si se pasa un header, se muestra
                    <div className='page-header'>
                        {toolbar}
                    </div>
                )}
        </div>

    )
}