import React, { useContext } from 'react'
import { Row, Col, Space } from 'antd'
import { Modal } from 'react-bootstrap'
import { CouponsContext } from '../provider/Coupons'
import { formCreateCoupon } from '../utils/var'
import FormControl from '../components/FormControl'

// eslint-disable-next-line react/prop-types
const CouponsFormApplicabilities = () => {
	const {
		applicabilities,
		updateApplicabilities,
		resetApplicabilities,
		isAllItems,
		updateIsAllItems,
	} = useContext(CouponsContext)

	return (
		<Row>
			<Col span={24} className='mt-4'>
				<Modal.Title as={'h3'}>¿En qué aplicar los cupones?</Modal.Title>
				<Space size='large'>
					{formCreateCoupon(
						updateApplicabilities,
						applicabilities,
						'applicabilities'
					).map((control) => (
						<div
							key={control.name}
							className={`${isAllItems ? 'disabled-control' : ''}`}>
							<FormControl {...control} />
						</div>
					))}
					<FormControl
						name='isAllItems'
						label='Todos los articulos'
						type='switch'
						attributes={{
							value: isAllItems,
							onChange: (value) => {
								updateIsAllItems(value)
								if (value) {
									resetApplicabilities()
								}
							},
						}}
					/>
				</Space>
			</Col>
		</Row>
	)
}

export default CouponsFormApplicabilities
