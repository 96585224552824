import { Result } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectError, selectStatus } from '../redux/selectors/new-sale'

export default function SaleFinished() {
	const status = useSelector(selectStatus)
	const error = useSelector(selectError)

	return (
		<div className='barcode-content-list'>
			<div className='page-title'>
				<h4>ESTADO DE VENTA</h4>
			</div>
			<div className='col-lg-12 mt-4'>
				<div className='d-flex flex-row justify-content-between'>
					{status == 'error' ? (
						<Result
							status={'error'}
							title={'Error'}
							subTitle={`'Error de orden '${error.message}`}
						/>
					) : (
						<Result
							status={'success'}
							title={'Correcto'}
							subTitle={'Orden completada correctamente.'}
						/>
					)}
				</div>
			</div>
		</div>
	)
}
