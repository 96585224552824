import React, { useContext, useEffect } from 'react'
import { Col, TreeSelect } from 'antd'
import { Modal } from 'react-bootstrap'
import { CouponsContext } from '../provider/Coupons'
import FormControl from '../components/FormControl'

const { SHOW_PARENT } = TreeSelect

export default function CouponsFormApplicabilitiesLinesAndSublines() {
	const {
		lines,
		dataApplicabilityLinesAndSublines,
		applicabilitiesLinesAndSublines,
		isLoadingLinesAndSublines,
		getLines,
		errorLines,
		updateDataApplicabilityLinesAndSublines,
		updateApplicabilitiesLinesAndSublines,
		isApplicableLinesAndSublines,
		/* updateIsApplicableLinesAndSublines, */
		dataUpdateFormatCoupon,
	} = useContext(CouponsContext)

	const onChange = (newValue) => {
		console.log(newValue)

		updateApplicabilitiesLinesAndSublines(newValue)
	}

	useEffect(() => {
		getLines()
	}, [])

	useEffect(() => {
		const tempLines = lines.map((line) => {
			const tempSubLines = []
			line.SubLines.forEach((subline) => {
				if (subline.SubLineCode) {
					tempSubLines.push({
						title: subline.SubLineName,
						value: `${line.LineCode}-${subline.SubLineCode}`,
						key: `${line.LineCode}-${subline.SubLineCode}`,
					})
				}
			})

			const formattedSublines = {
				title: line.LineName,
				value: line.LineCode,
				key: line.LineCode,
				children: tempSubLines,
			}

			return formattedSublines
		})

		updateDataApplicabilityLinesAndSublines(tempLines)
	}, [lines])

	useEffect(() => {
		if (dataApplicabilityLinesAndSublines?.length > 0) {
			if (dataUpdateFormatCoupon?.linesAndSublines?.length > 0) {
				updateApplicabilitiesLinesAndSublines(
					dataUpdateFormatCoupon.linesAndSublines
				)
			}
		}
	}, [dataApplicabilityLinesAndSublines])

	/* useEffect(() => {
		console.log(applicabilitiesLinesAndSublines)
	}, [applicabilitiesLinesAndSublines]) */

	return (
		<Col className='w-100'>
			<Modal.Title as={'h4'}>Lineas y Sublineas</Modal.Title>
			{isLoadingLinesAndSublines ? (
				<span>Cargando...</span>
			) : errorLines ? (
				<span>{errorLines.message}</span>
			) : lines.length < 1 || !lines ? (
				<span>No se encontraron líneas y sublíneas</span>
			) : (
				<>
					{/* <div className='d-flex align-items-center gap-2'>
						<FormControl
							type={'switch'}
							name='isApplicableLinesAndSublines'
							label={'Cambiar modo de selección:'}
							id='isApplicableLinesAndSublines'
							attributes={{
								defaultChecked: true,
								value: { isApplicableLinesAndSublines },
								onChange: () => {
									updateIsApplicableLinesAndSublines(
										!isApplicableLinesAndSublines
									)
								},
							}}
						/>
					</div> */}
					<FormControl
						label={isApplicableLinesAndSublines ? 'Aplicar a' : 'No aplicar a'}
						name='linesAndSublines'
						type='treeselect'
						rules={[
							() => ({
								validator(_, values) {
									console.log(values)

									const errorValidationRequired = `Seleccionar al menos un(a) linea o sublinea.`
									if (!values) {
										return Promise.reject(new Error(errorValidationRequired))
									} else {
										let countValues = values.filter(
											(value) =>
												typeof value === 'string' || typeof value === 'number'
										).length

										if (countValues > 0) {
											return Promise.resolve()
										} else {
											return Promise.reject(new Error(errorValidationRequired))
										}
									}
								},
							}),
						]}
						attributes={{
							value: applicabilitiesLinesAndSublines,
							treeCheckable: true,
							treeNodeFilterProp: 'title',
							showCheckedStrategy: SHOW_PARENT,
							defaultValue: applicabilitiesLinesAndSublines,
							placeholder: 'Seleccionar lineas y/o sublineas',
							onChange: onChange,
							treeData: dataApplicabilityLinesAndSublines,
							style: { width: '50%' },
						}}
					/>
				</>
			)}
		</Col>
	)
}
