import React from 'react'
import { useSelector } from 'react-redux'
import {
	selectCouponAmount,
	selectCouponType,
	selectCouponCode,
} from '../../../redux/selectors/coupon'
import {
	selectCartTotal,
	selectCartSubtotal,
	selectCartDiscount,
	selectDiscontLiquidation,
} from '../../../redux/selectors/cart'
import { formatCurrency } from '../../../utils/functions/money'

export default function Amount() {
	const cartSubtotal = useSelector(selectCartSubtotal)
	const cartTotal = useSelector(selectCartTotal)
	const couponAmount = useSelector(selectCouponAmount)
	const coupon = useSelector(selectCartDiscount)
	const couponType = useSelector(selectCouponType)
	const couponCode = useSelector(selectCouponCode)
	const discountLiquidation = useSelector(selectDiscontLiquidation)

	return (
		<div className='block-section'>
			<div className='order-total'>
				<table className='table table-responsive table-borderless'>
					<tbody>
						<tr>
							<td>Subtotal</td>
							<td className='text-end'>{formatCurrency(cartSubtotal)}</td>
						</tr>
						{couponCode && (
							<tr>
								<td className='danger'>
									Descuento{' '}
									{couponType === 'per'
										? `${couponAmount}%`
										: `${formatCurrency(couponAmount)}`}
								</td>
								<td className='danger text-end'>{formatCurrency(coupon)}</td>
							</tr>
						)}
						{discountLiquidation && discountLiquidation > 0 ? (
							<tr>
								<td className='danger'>Ahorro por liquidación</td>
								<td className='danger text-end'>
									{formatCurrency(discountLiquidation)}
								</td>
							</tr>
						) : (
							<></>
						)}
						<tr>
							<td
								style={{
									fontSize: '1.3rem',
								}}>
								Total
							</td>
							<td
								className='text-end'
								style={{
									fontSize: '1.3rem',
								}}>
								{formatCurrency(cartTotal)}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	)
}
