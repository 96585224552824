import React from 'react';
import PropTypes from 'prop-types';

const PaymentMethodSelector = ({ label, paymentMethods, selectedMethods, onChange, disablePayMethod = false }) => {
    return (
        <div className="row align-items-center mb-sm-4">
            <div className="col-sm-4">
                <div className="setting-info">
                    <h6>{label}</h6>
                </div>
            </div>
            <div className="col-sm-8">
                <div className="localization-select pos-payment-method d-flex align-items-center mb-0 w-100">
                    {paymentMethods.map((method) => (
                        <div key={method} className="custom-control custom-checkbox">
                            <label className="checkboxs mb-0 pb-0 line-height-1">
                                <input
                                    type="checkbox"
                                    checked={selectedMethods.includes(method)}
                                    onChange={() => onChange(method)}
                                    disabled={disablePayMethod}
                                />
                                <span className="checkmarks" />
                                {method}
                            </label>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

// Definición de PropTypes para validar las props del componente
PaymentMethodSelector.propTypes = {
    label: PropTypes.string.isRequired,
    paymentMethods: PropTypes.arrayOf(PropTypes.string).isRequired,
    selectedMethods: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChange: PropTypes.func.isRequired,
    disablePayMethod: PropTypes.bool
};

export default PaymentMethodSelector;
