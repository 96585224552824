export const selectAuthenticationIsLoading = (state) =>
	state.authentication.isLoading
export const selectAuthenticationIsAuthenticated = (state) =>
	state.authentication.isAuthenticated
export const selectAuthenticationError = (state) => state.authentication.error
export const selectAuthenticationUser = (state) => state.authentication.user
export const selectAuthenticationExpiration = (state) =>
	state.authentication.expires
export const selectAuthenticationLogoutIsLoading = (state) =>
	state.authentication.logout.isLoading
export const selectAuthenticationLogoutError = (state) =>
	state.authentication.logout.error
