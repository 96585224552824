import React, { useEffect, useState } from "react";
import DashboardLayout from './DashboardLayout';
import ImageWithBasePath from '../core/img/imagewithbasebath';

export default function Dashboard() {
	// Puedes cambiar esta fecha por la que necesites
	const targetDate = new Date('2024-12-31T23:59:59'); // Fecha objetivo
	const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });

	useEffect(() => {
		const intervalId = setInterval(() => {
			const now = new Date();
			const difference = targetDate - now;

			// Si la diferencia es negativa, la fecha ha pasado
			if (difference <= 0) {
				clearInterval(intervalId);
				setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
				return;
			}

			const seconds = Math.floor((difference / 1000) % 60);
			const minutes = Math.floor((difference / 1000 / 60) % 60);
			const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
			const days = Math.floor(difference / (1000 * 60 * 60 * 24));

			setTimeLeft({ days, hours, minutes, seconds });
		}, 1000);

		return () => clearInterval(intervalId);
	}, [targetDate]);

	const formatTime = (time) => {
		return time < 10 ? `0${time}` : time;
	};

	return (
		<DashboardLayout>
			<div className='comming-soon-pg w-100'>
				<div className='coming-soon-box'>
					<div className='pos-logo'>
						<ImageWithBasePath src='assets/img/lapi.png' alt width={180} />
					</div>
					<h1>
						<span> COMING </span> SOON{' '}
					</h1>
					<p>Estamos trabajando en este modulo, esperelo pronto.</p>
					<ul className="coming-soon-timer">
						<li>
							<span className="days">{timeLeft.days}</span> días
						</li>
						<li className="seperate-dot">:</li>
						<li>
							<span className="hours">{formatTime(timeLeft.hours)}</span> Hrs
						</li>
						<li className="seperate-dot">:</li>
						<li>
							<span className="minutes">{formatTime(timeLeft.minutes)}</span> Min
						</li>
						<li className="seperate-dot">:</li>
						<li>
							<span className="seconds">{formatTime(timeLeft.seconds)}</span> Sec
						</li>
					</ul>
					<p>Menos 6 dias de fines de semana.</p>
					<p>Menos el 13 y 25</p>
				</div>
			</div>
		</DashboardLayout>
	);
}
