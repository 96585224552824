import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import { ConfigProvider, Steps } from 'antd'
import {
	LoadingOutlined,
	PoweroffOutlined,
	ApiOutlined,
	UserOutlined,
	CloudSyncOutlined,
	CheckOutlined,
} from '@ant-design/icons'
import { useContext } from 'react'
import { PinpadContext } from '../../../provider/Pinpad'
import Ready from '../components/Ready'
import DeviceSelection from '../components/DeviceSelection'
import LoginTab from '../components/LoginTab'
import LoadingKRSA from '../components/LoadingKRSA'

const ModalConfigPinpad = ({ visible, onClose, record }) => {
	const { portSelected, port1, login, updatePortSelected } =
		useContext(PinpadContext)
	const [isPasswordVisible, setPasswordVisible] = useState(false)
	const [activeStep, setActiveStep] = useState(0)
	const [usuario, setUsuario] = useState('')
	const [contra, setContra] = useState('')

	// Manejar el inicio de sesión
	const handleLogin = () => {
		setActiveStep(2) // Ir al paso "Cargando"
		login(usuario, contra, port1, setActiveStep)
	}

	const togglePasswordVisibility = () =>
		setPasswordVisible((prevState) => !prevState)

	const handleRestart = () => {
		setActiveStep(0) // Reiniciar flujo al paso 0
		updatePortSelected(null) // Reiniciar puerto seleccionado
		setUsuario('')
		setContra('')
	}

	useEffect(() => {}, [visible, record, activeStep, usuario, contra])

	return (
		<Modal
			show={visible}
			onHide={onClose}
			keyboard={false}
			size='lg'
			centered
			scrollable>
			<Modal.Body>
				<div className='page-wrapper details-blk'>
					<div className='content p-0'>
						<div className='card'>
							<div className='card-header'>
								<div className='d-flex justify-content-end'>
									<img
										src='../assets/img/bank/inbursa.png'
										alt='Inbursa logo'
										width={100}
									/>
								</div>
								<h5 className='card-title'>Flujo inicial de Configuración</h5>
							</div>
							<div className='card-body'>
								<ConfigProvider
									theme={{
										token: {
											colorPrimary: '#e51885',
											borderRadius: 2,
											colorBgContainer: '#f6ffed',
										},
									}}>
									<Steps
										className='stepper-pape'
										current={activeStep}
										items={[
											{
												title: 'Dispositivo',
												icon: <ApiOutlined />,
											},
											{
												title: 'Iniciar sesión',
												icon: <UserOutlined />,
											},
											{
												title: 'Obtener K.RSA',
												icon:
													activeStep === 2 ? (
														<LoadingOutlined />
													) : (
														<CloudSyncOutlined />
													),
											},
											{
												title: 'Todo Listo',
												icon:
													activeStep === 3 ? (
														<CheckOutlined />
													) : (
														<PoweroffOutlined />
													),
											},
										]}
									/>
								</ConfigProvider>

								<div className='tab-content d-flex justify-content-center align-items-center'>
									{activeStep === 0 && <DeviceSelection />}
									{activeStep === 1 && (
										<LoginTab
											isPasswordVisible={isPasswordVisible}
											togglePasswordVisibility={togglePasswordVisibility}
											setUsuario={setUsuario}
											setContra={setContra}
											handleLogin={handleLogin}
										/>
									)}
									{activeStep === 2 && <LoadingKRSA />}
								</div>

								{/* Botones de navegación */}
								{activeStep === 0 && (
									<div style={{ textAlign: 'right', marginTop: '20px' }}>
										<button
											type='button'
											className='btn btn-primary'
											onClick={() => setActiveStep(1)} // Avanzar al siguiente paso
											disabled={!portSelected} // Desactivado hasta que se seleccione un puerto
										>
											Siguiente
										</button>
									</div>
								)}
								{activeStep === 1 && (
									<div style={{ textAlign: 'left', marginTop: '20px' }}>
										{/* Solo mostrar el botón "Regresar" */}
										<button
											type='button'
											className='btn btn-outline-secondary'
											onClick={() => setActiveStep(0)}>
											Regresar
										</button>
									</div>
								)}
								{activeStep === 3 && (
									<>
										<Ready />
										<div style={{ textAlign: 'center', marginTop: '20px' }}>
											<button
												type='button'
												className='btn btn-outline-secondary'
												onClick={handleRestart}>
												Volver a empezar
											</button>
											<button
												type='button'
												className='btn btn-primary ms-2'
												onClick={onClose}>
												Cerrar
											</button>
										</div>
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	)
}

ModalConfigPinpad.propTypes = {
	visible: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	record: PropTypes.object,
}

export default ModalConfigPinpad
