import React from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { changeStatus, cleanWithdraw } from '../../../redux/withdraw'
import {
	selectIsLoadingExecuteCashCount,
	selectIsShowConfirm,
} from '../redux/selectors/cash-count'
import { executeCashCount, updateIsShowConfirm } from '../redux/cash-count'

export default function ConfirmExecuteCashCount() {
	const isShowConfirm = useSelector(selectIsShowConfirm)
	const isLoadingExecuteCashCount = useSelector(selectIsLoadingExecuteCashCount)

	const dispatch = useDispatch()

	return (
		<Modal centered size='md' show={isShowConfirm}>
			<Modal.Header>
				<Modal.Title>Confirmar corte de caja</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>¿Realizar Arqueo de Caja? Esta acción no se puede revertir.</p>
				<Form>
					<Button
						className='me-2 mt-4 py-3 px-4'
						onClick={() => {
							dispatch(cleanWithdraw())
							dispatch(updateIsShowConfirm(false))
							dispatch(changeStatus('noinit'))
						}}
						disabled={isLoadingExecuteCashCount}
						variant='pape_cancel'>
						Cancelar
					</Button>
					<Button
						className='mt-4 py-3 px-4'
						type='button'
						onClick={() => {
							dispatch(executeCashCount())
						}}
						disabled={isLoadingExecuteCashCount}>
						Continuar
						{isLoadingExecuteCashCount && (
							<div
								className='spinner-border spinner-border-sm ms-2'
								role='status'>
								<span className='sr-only'>Loading...</span>
							</div>
						)}
					</Button>
				</Form>
			</Modal.Body>
		</Modal>
	)
}
