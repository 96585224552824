import React, { useEffect, useState } from 'react'
import { Form, Button, Modal, Col } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { selectCashCountIsLoading } from '../redux/selectors/cash-count'
import { openCashCount } from '../redux/cash-count'
import { selectAuthenticationUser } from '../../../redux/selectors/authentication'
import {
	selectCashCountError,
	selectCashCountIsOpenModal,
} from '../redux/selectors/cash-count'
import { toggleModal } from '../redux/cash-count'
import { apiUrl } from '../../../utils/var'

/* const cashiers = [
	{ value: 401, label: 'Edgar Tapia' },
	{ value: 438, label: 'Fabian Alvarez' },
] */

export default function OpePOS() {
	const dispatch = useDispatch()
	const cashCountError = useSelector(selectCashCountError)
	const isLoading = useSelector(selectCashCountIsLoading)
	const user = useSelector(selectAuthenticationUser)
	const isOpenModal = useSelector(selectCashCountIsOpenModal)
	const [validated, setValidated] = useState(false)
	const [employee, setEmployee] = useState(null)
	const [cashiers, setCashiers] = useState(null)
	const [cashDrawerFund, setCashDrawerFund] = useState(500)
	const [isLoadingEmployees, setIsLoadingEmployees] = useState(false)
	const [errorEmployees, setErrorEmployees] = useState(null)

	const handleChangeSelectEmployee = (event) => {
		setEmployee(event.target.value)
	}

	const handleChangeCashDrawerFund = (event) => {
		const value = event.target.value
		const regexNumberTwoDecimals = /^\d*\.?\d{0,2}$/

		if (regexNumberTwoDecimals.test(value) || value === '') {
			setCashDrawerFund(value)
		}
	}

	const getEmployees = async () => {
		setIsLoadingEmployees(true)
		const requestGetEmployees = await fetch(
			`${apiUrl}/api/cash-count/get-cashiers`,
			{
				method: 'GET',
				credentials: 'include',
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
				},
			}
		)

		setIsLoadingEmployees(false)
		const responseGetEmployees = await requestGetEmployees.json()

		if (!requestGetEmployees.ok) {
			console.error(responseGetEmployees.message)

			setErrorEmployees({
				status: responseGetEmployees.status,
				message: responseGetEmployees.message,
			})
		} else {
			setCashiers(responseGetEmployees.data)
		}
	}

	const continueOpenCashCount = (event) => {
		event.preventDefault()
		const form = event.currentTarget
		setValidated(true)

		if (form.checkValidity() === false) {
			event.stopPropagation()
		} else {
			const device = JSON.parse(localStorage.getItem('device')).value
			dispatch(
				openCashCount({
					idEmployee: employee && +employee,
					idManager: user.userId,
					cashDrawerFund,
					idDevice: device,
				})
			)
		}
	}

	useEffect(() => {
		getEmployees()
	}, [])

	useEffect(() => {
		if (!isLoading) {
			setEmployee(false)
		}
	}, [isLoading])

	return (
		<Modal size='lg' centered show={isOpenModal}>
			<Modal.Header>
				{isLoadingEmployees ? (
					<Modal.Title>Cargando...</Modal.Title>
				) : errorEmployees ? (
					<Modal.Title>Error</Modal.Title>
				) : (
					<Modal.Title>Abrir Turno</Modal.Title>
				)}
			</Modal.Header>
			<Modal.Body>
				{isLoadingEmployees ? (
					<Modal.Title>Cargando información...</Modal.Title>
				) : errorEmployees || !cashiers || cashiers.length < 1 ? (
					<>
						<Modal.Title>
							Ocurrió un error, recargue el navegador o contacte a soporte
							técnico.
						</Modal.Title>
						<Button
							className='me-2 mt-4 py-3 px-4'
							variant='pape_cancel'
							onClick={() => dispatch(toggleModal(false))}
							disabled={isLoading}>
							Cerrar
						</Button>
					</>
				) : (
					<Form
						noValidate
						validated={validated}
						onSubmit={(event) => continueOpenCashCount(event)}>
						<Form.Group as={Col} className='mb-3'>
							<Form.Label className='form-label'>
								<span>*</span> Encargado de caja:
							</Form.Label>
							<Form.Select
								disabled={isLoading}
								required
								name='employee'
								size='sm'
								onChange={(event) => handleChangeSelectEmployee(event)}>
								<option>Selecciona un empleado</option>
								{cashiers.map((employee) => (
									<option key={employee.value} value={employee.value}>
										{employee.label}
									</option>
								))}
							</Form.Select>
							<Form.Control.Feedback type='invalid'>
								Campo obligatorio
							</Form.Control.Feedback>
						</Form.Group>

						<Form.Group as={Col} className='mb-3'>
							<Form.Label className='form-label'>
								<span>*</span> Efectivo inicial:
							</Form.Label>
							<Form.Control
								disabled={isLoading}
								name='cash-drawer-fund'
								size='sm'
								required
								type='number'
								value={cashDrawerFund}
								onChange={(event) => handleChangeCashDrawerFund(event)}
							/>
							<Form.Control.Feedback type='invalid'>
								Campo obligatorio
							</Form.Control.Feedback>
						</Form.Group>

						{cashCountError && (
							<p
								className='text-danger'
								style={{
									width: 'fit-content',
								}}>
								{cashCountError}
							</p>
						)}

						<Button
							className='me-2 mt-4 py-3 px-4'
							variant='pape_cancel'
							onClick={() => dispatch(toggleModal(false))}
							disabled={isLoading}>
							Cancelar
						</Button>

						<Button
							className='mt-4 py-3 px-4'
							type='submit'
							disabled={isLoading}>
							Continuar
							{isLoading && (
								<div
									className='spinner-border spinner-border-sm ms-2'
									role='status'>
									<span className='sr-only'>Loading...</span>
								</div>
							)}
						</Button>
					</Form>
				)}
			</Modal.Body>
		</Modal>
	)
}
