import React from 'react'
import SaleResumeAmounts from '../components/SaleResumeAmounts'
import SaleResumeProducts from './SaleResumeProducts'

export default function SaleResume() {
	return (
		<div className='barcode-content-list'>
			<div className='page-title'>
				<h4>RESUMEN DE VENTA</h4>
			</div>
			<div className='col-lg-12 mt-4'>
				<div className='d-flex flex-row justify-content-between'>
					<SaleResumeProducts />
					<SaleResumeAmounts />
				</div>
			</div>
		</div>
	)
}
