import React, { useContext } from 'react'
import { CouponsContext } from '../provider/Coupons'
import CouponsFormTransferApplicability from '../components/CouponsFormTransferApplicability'

export default function CouponsFormApplicabilitiesBrands() {
	const {
		getBrands,
		brands,
		applicabilityBrands,
		updateApplicabilityBrands,
		dataApplicabilityBrands,
		updateDataApplicabilityBrands,
		isApplicableBrands,
		updateIsApplicableBrands,
		isLoadingBrands,
		errorBrands,
		dataUpdateFormatCoupon,
	} = useContext(CouponsContext)

	return (
		<CouponsFormTransferApplicability
			key={'brandTransfer'}
			get={getBrands}
			list={brands}
			title='Marcas'
			name='brands'
			keyProp='FirmCode'
			titleProp='FirmName'
			entityText='marca'
			applicability={applicabilityBrands}
			updateApplicability={updateApplicabilityBrands}
			dataApplicability={dataApplicabilityBrands}
			updateDataApplicability={updateDataApplicabilityBrands}
			isLoading={isLoadingBrands}
			error={errorBrands}
			isApplicable={isApplicableBrands}
			updateIsApplicable={updateIsApplicableBrands}
			dataUpdateFormatCoupon={dataUpdateFormatCoupon}
			keyList='brands'
		/>
	)
}
