export const verifyUserRole = (user, requiredRoles) => {
	if (user) {
		/* const roles = user.roles.map((rol) => rol.U_CSM_ROL) */

		return user.roles.some((rol) => {
			return requiredRoles.includes(`${rol.U_CSM_ROL}`)
		})
	}

	return false
}
