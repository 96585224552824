import React, { useEffect, useState } from 'react';
import { Skeleton, Table } from 'antd';
import { PropTypes } from 'prop-types'
import { Button, Modal } from 'react-bootstrap';
import { Link } from "react-router-dom";
import ImageWithBasePath from "../core/img/imagewithbasebath";
import { apiUrl } from '../utils/var'
import { formatCurrency } from '../utils/functions/money';
import { convertMilitaryTimeToStandardTimeFormat } from '../utils/functions/date';
import { Print } from '../utils/functions/printTicket';
import Swal from 'sweetalert2';

const ModalDetails = ({ visible, onClose, record }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [order, setOrder] = useState([]);
    const [errorPrinting, setErrorPrinting] = useState(null);
    const [loadingPrinting, setLoadingPrinting] = useState(null);
    const [successPrinting, setSuccessPrinting] = useState(null);

    const getOrderDetails = async () => {
        setIsLoading(true);
        try {
            const requestorder = await fetch(`${apiUrl}/api/Orders/${record.DocEntry}/details`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
            });

            const responseorders = await requestorder.json();
            if (!requestorder.ok) {
                throw new Error(responseorders.message);
            } else {
                setError(null);
                setOrder(responseorders);
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handlePrintTicket = async () => {
        setLoadingPrinting(true);
        setErrorPrinting(null);
        console.log('loadingPrinting: ', loadingPrinting)
        try {
            showSweetAlertLoadingAnimation();
            const responsePrint = await Print(
                { "Type_XML": "TICKET", },
                `${apiUrl}/api/print/ticket/${record.DocEntry}`,
                'POST')

            if (responsePrint.statusCode !== 200) {
                console.log(responsePrint);
                throw new Error(`Error al imprimir el ticket: ${responsePrint.message} - ${responsePrint.errors ?? ''}`);
            }
            setSuccessPrinting(true)
            console.log(responsePrint)
        } catch (error) {
            console.error("Error en catch:", error);
            setErrorPrinting(error.message); // Esto activará el useEffect para mostrar el error
        } finally {
            setLoadingPrinting(false); // Actualizamos loadingPrinting al finalizar
            console.log('loadingPrinting: ', false)
        }
    } 


    const showSweetAlertLoadingAnimation = () => {
        Swal.fire({
            title: 'Imprimiendo...',
            text: 'Por favor, espera mientras se completa la impresión.',
            allowOutsideClick: false,
            didClose: () => {
                Swal.close()
            },
            didOpen: () => {
                Swal.showLoading();
            },
        });
    };

    const showSweetAlertSuccess2 = async () => {
        await Swal.fire({
            title: 'Impresión completada',
            icon: 'success',
        });
    };

    const showErrorPrint = async (errorPrinting) => {
        await Swal.fire({
            title: 'Ocurrió un error al imprimir',
            text: errorPrinting || 'Error desconocido',
            icon: 'error',
            footer: '<a href="#">¿Por qué tengo este problema?</a>',
            showConfirmButton: true,
            didOpen: () => {
                Swal.hideLoading()
            }
        });
    };

    useEffect(() => {
        if (visible) {
            getOrderDetails();
        }
    }, [visible]);

    useEffect(() => {
        if (errorPrinting) {
            Swal.close();
            showErrorPrint(errorPrinting);
        }
    }, [errorPrinting]);

    useEffect(() => {
        if (loadingPrinting && !errorPrinting) {
            showSweetAlertLoadingAnimation();
        } else if (!loadingPrinting && !errorPrinting && successPrinting) {
            Swal.close();
            showSweetAlertSuccess2()
        }
    }, [loadingPrinting, errorPrinting, successPrinting]);

    const columns = [
    
        {
            title: "#",
            dataIndex: 'LineNum',
            sorter: (a, b) => a.LineNum - b.LineNum,
            render: (text) => (
                <span>{text + 1}</span>

            )
        },
        {
            title: "",
            dataIndex: '',
            render: (text, record) => (
                <ImageWithBasePath
                    url={`${apiUrl}/proxy/empleados/imagenes/ITEMS/${record.PicturName}`}
                    width={50}
                    alt="product"
                />
            )
        },
        {
            title: "Numero de parte",
            dataIndex: "ItemCode",
            render: (text) => (
                <span className="badge badge-linesuccess">
                    {text}
                </span>

            ),
            sorter: (a, b) => a.ItemCode.localeCompare(b.ItemCode),
        },
        {
            title: "Descripcion",
            dataIndex: "Dscription",
            sorter: (a, b) => a.Dscription.localeCompare(b.Dscription),
            render: (text) => (
                <div>
                    <span>{text}</span>
                </div>
            ),
        },
        {
            title: "Codigo de barras",
            dataIndex: "CodeBars",
            sorter: (a, b) => a.CodeBars.localeCompare(b.CodeBars),
        }, {
            title: "Cantidad",
            dataIndex: "Quantity",
            sorter: (a, b) => a.Quantity - b.Quantity,
        },
        {
            title: "Precio unitario",
            dataIndex: "Price",
            sorter: (a, b) => a.Price - b.Price,
            render: (text) => {
                return formatCurrency(text);
            }
        }, {
            title: "Total",
            dataIndex: "LineTotal",
            sorter: (a, b) => a.LineTotal - b.LineTotal,
            render: (text) => {
                return formatCurrency(text);
            }
        },
    ];

    return (
        <Modal
            show={visible}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Venta</Modal.Title>
            </Modal.Header>
            <Modal.Body >
                {!error ?
                    <div className="page-wrapper details-blk">
                        <div className="content p-0">
                            <div className="page-header p-4 mb-0">
                                <div className="add-item d-flex">
                                    <div className="page-title modal-datail">
                                        <h4>Detalles de la venta :{order.orden?.DocEntryDrf ? order.orden.DocEntryDrf : "Información no disponible"}</h4>
                                    </div>
                                </div>
                                <ul className="table-top-head">
                                    <li>
                                        <Link
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title="Pdf"
                                            onClick={handlePrintTicket}
                                        >
                                            <ImageWithBasePath
                                                src="assets/img/icons/receipt.svg"
                                                alt="img"
                                            />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title="Pdf"
                                            // to={'http://thoro-ent.dyndns.info/pruebas/empleados/web/index.php?r=AppMovil/documents/get-document2&documentName=MT-37_1709733256-1-1.jpg'}
                                            to={'http://thoro-ent.dyndns.info/pruebas/empleados/web/index.php?r=AppMovil/documents/get-document2'}
                                        >
                                            <ImageWithBasePath
                                                src="assets/img/icons/pdf.svg"
                                                alt="img"
                                            />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title="Excel"
                                        >
                                            <ImageWithBasePath
                                                src="assets/img/icons/xls.svg"
                                                alt="img"
                                            />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title="Print"
                                        >
                                            <ImageWithBasePath
                                                src="assets/img/icons/excel.svg"
                                                alt="img"
                                            />
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <form>
                                        <div
                                            className="invoice-box table-height"
                                        >
                                            {isLoading ? <Skeleton active /> : <div className="sales-details-items d-flex">
                                                <div className="details-item">
                                                    <h6>Informacion de la venta</h6>

                                                    Cajero: {order.orden?.Employee ? order.orden.Employee : "Cajero no disponible"}
                                                    <br />
                                                    Caja: {order.orden?.U_CSM_IdEquipo ? order.orden.U_CSM_IdEquipo : "Caja no disponible"}
                                                    <br />
                                                    Fecha: {order.orden?.DocDate ? (order.orden.DocDate.substring(0, 10)) : "Fecha no disponible"}
                                                    <br />
                                                    Hora: {order.orden?.DocTime ? convertMilitaryTimeToStandardTimeFormat(order.orden.DocTime) : "Hora no disponible"}
                                                    <br />
                                                </div>
                                                {/* <div className="details-item">
                                                <h6>Company Info</h6>
                                                <p>
                                                    DGT
                                                    <br />
                                                    admin@example.com
                                                    <br />
                                                    6315996770
                                                    <br />
                                                    3618 Abia Martin Drive
                                                </p>
                                            </div> */}
                                                <div className="details-item">
                                                    <h6>Informacion de facturacion</h6>
                                                    <p>
                                                        Total: {order.orden?.DocTotal ? formatCurrency(order.orden.DocTotal) : "Total no disponible"}
                                                        <br />
                                                        Metodo de pago: {order.orden?.PeyMethod ? (
                                                            order.orden.PeyMethod == '01' ?
                                                                <span className="badge badge-linesuccess">
                                                                    <ImageWithBasePath
                                                                        src="assets/img/icons/cash-pay.svg"
                                                                        alt="img"
                                                                        width={15}
                                                                    /> Efectivo</span> :
                                                                <span className="badge badge-lineinfo">
                                                                    <ImageWithBasePath
                                                                        src="assets/img/icons/credit-card.svg"
                                                                        alt="img"
                                                                        width={15}
                                                                    /> Tarjeta</span>
                                                        ) : "Total no disponible"}
                                                        <br />
                                                        Estatus: {order.orden?.DocStatus ? (
                                                            order.orden.DocStatus == 'O' ?
                                                                <span className="badge badge-linesuccess">Abierto</span> :
                                                                <span className="badge badge-lineinfo">Cerrada</span>
                                                        ) : "Total no disponible"}
                                                        <br />

                                                    </p>
                                                </div>

                                            </div>}

                                            <h5 className="order-text">Articulos de la orden</h5>
                                            <div className="table-responsive no-pagination">
                                                <div className="table-responsive">
                                                    <Table
                                                        bordered={true}
                                                        columns={columns}
                                                        dataSource={order.items}
                                                        loading={isLoading}
                                                        rowKey={"LineNum"}
                                                        pagination={false}
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="row">
                                                <div className="col-lg-6 ms-auto">
                                                    <div className="total-order w-100 max-widthauto m-auto mb-4">
                                                        <ul>
                                                            <li>
                                                                <h4>IVA</h4>
                                                                <h5>{order.orden?.VatSum ? formatCurrency(order.orden.VatSum) : "Total no disponible"}</h5>
                                                            </li>
                                                            <li>
                                                                <h4>Cupon</h4>
                                                                <h5>{order.orden?.U_code ? order.orden.U_code : ""}</h5>
                                                            </li>
                                                            <li>
                                                                <h4>Ahorro por liquidación</h4>
                                                                <h5> - </h5>
                                                            </li>
                                                            <li>
                                                                <h4>Total</h4>
                                                                <h5>{order.orden?.DocTotal ? formatCurrency(order.orden.DocTotal) : "Total no disponible"}</h5>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div> :
                    <span>Error: error</span>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={onClose}>Cerrar</Button>
            </Modal.Footer>
        </Modal>
    );
};

ModalDetails.propTypes = {
    visible: PropTypes.bool.isRequired,  // 'visible' debe ser un booleano y es requerido
    onClose: PropTypes.func.isRequired,  // 'onClose' debe ser una función y es requerida
    record: PropTypes.shape({            // 'record' es un objeto que tiene varias propiedades
        DocNum: PropTypes.number,        // DocNum es una cadena
        DocDate: PropTypes.string,       // DocDate es una cadena
        DocEntry: PropTypes.number,
        PicturName: PropTypes.string,
        CreateDate: PropTypes.string,
        CreateTime: PropTypes.string,
        amount: PropTypes.oneOfType([    // amount puede ser un número o una cadena
            PropTypes.string,
            PropTypes.number,
        ]),

    }),
};

export default ModalDetails;
