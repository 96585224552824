import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Select from "react-select";
import {
	Eye,
	Box,
	Filter, Sliders,
} from "feather-icons-react/build/IconComponents";
import DashboardLayout from '../../../layouts/DashboardLayout'
import PageTtle from '../../../layouts/PagesTitle'
import { apiUrl } from '../../../utils/var'
import { Cascader, Table } from 'antd'
import ModalProductDetail from '../components/modalProductDetails';
import { formatCurrency } from '../../../utils/functions/money';
import Toolbar from '../components/toolbar';
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
const Inventory = () => {
	const [isLoading, setIsLoading] = useState(false)
	const [, setError] = useState(null)
	const [items, setItems] = useState({ items: [] })
	const [categories, setCategories] = useState([])
	const [showModal, setShowModal] = useState(false);
	const [selectedRecord, setSelectedRecord] = useState(null);
	const [selectedCategory, setSelectedCategory] = useState('')
	const [categoryOptions, setCategoryOptions] = useState([])
	const [sortOrder, setSortOrder] = useState("date"); // Estado para el criterio de orden
	
	const [isFilterVisible, setIsFilterVisible] = useState(false);
	const toggleFilterVisibility = () => {
		setIsFilterVisible((prevVisibility) => !prevVisibility);
	};

	const handleShowModal = (record) => {
		setSelectedRecord(record);
		setShowModal(true);
	};

	const handleCloseModal = () => {
		setShowModal(false);
		setSelectedRecord(null);
	};

	const handleCategorySelection = (selection) => {
		setSelectedCategory(selection && selection.length > 0 ? selection[0] : '');
	}

	const handleSearchCategory = () => {
		getItemsAndCategory(selectedCategory)

	}

	const getItemsAndCategory = async (category = null) => {

		setIsLoading(true);

		try {
			const itemsUrl = category
				? `${apiUrl}/api/items/getItems?itemName=ASC&Linea=${category}&Limit=10000`
				: `${apiUrl}/api/items/getItems?itemName=ASC&Limit=10000`;

			const [requestItems, requestCategories] = await Promise.all([
				fetch(itemsUrl, {
					method: 'GET',
					credentials: 'include',
					headers: {
						'Content-Type': 'application/json',
						'Access-Control-Allow-Origin': '*',
					},
				}),
				fetch(`${apiUrl}/api/items/getCategories`, {
					method: 'GET',
					credentials: 'include',
					headers: {
						'Content-Type': 'application/json',
						'Access-Control-Allow-Origin': '*',
					},
				})
			]);

			if (!requestItems.ok) {
				const errorResponse = await requestItems.json();
				throw new Error(errorResponse.message || 'Error al obtener items');
			}
			const responseItems = await requestItems.json();
			setItems(responseItems);

			if (!requestCategories.ok) {
				const errorResponse = await requestCategories.json();
				throw new Error(errorResponse.message || 'Error al obtener categorías');
			}
			const responseCategories = await requestCategories.json();
			setCategories(responseCategories);

			setError(null);

		} catch (error) {
			setError(error.message);
		} finally {
			setIsLoading(false);
		}
	};


	const [searchText, setSearchText] = useState('')

	const handleSearch = (e) => {
		setSearchText(e.target.value)
	}

	const filteredData = items.items
		.filter((entry) => Object.keys(entry).some((key) => String(entry[key]).toLowerCase().includes(searchText.toLowerCase())))
		.sort((a, b) => {
			if (sortOrder === "newest") return new Date(b.date) - new Date(a.date);
			if (sortOrder === "oldest") return new Date(a.date) - new Date(b.date);
			return 0; // Orden por defecto
		});


	const columns = [
		{
			title: 'No. de Parte',
			dataIndex: 'ItemCode',
			sorter: (a, b) => a.ItemCode.localeCompare(b.ItemCode),
			render: (ItemCode) => (<span className='badge badge-linesuccess'>{ItemCode}</span>)
		},
		{
			title: 'Articulo',
			dataIndex: 'ItemName',
			render: (text, record) => (
				<span className='productimgname'>
					<Link to='#' className='product-img stock-img'>
						<img
							alt='img'
							src={`${apiUrl}/proxy/empleados/imagenes/ITEMS/${record.PicturName}`}
						/>
					</Link>
					<span >{text}</span>
				</span>
			),
			sorter: (a, b) => a.ItemName.localeCompare(b.ItemName),
		},
		{
			title: 'Marca',
			dataIndex: 'Brand',
			sorter: (a, b) => a.Brand.localeCompare(b.Brand),
		},
		{
			title: 'Categoria',
			dataIndex: 'LineName',
			sorter: (a, b) => a.SubLineName.localeCompare(b.SubLineName),
		},
		{
			title: 'Subcategoria',
			dataIndex: 'SubLineName',
			sorter: (a, b) => a.SubLineName.localeCompare(b.SubLineName),
		},
		{
			title: 'Bodega',
			dataIndex: 'WhsCode',
			sorter: (a, b) => a.WhsCode.localeCompare(b.WhsCode),
		},
		{
			title: 'Stock',
			dataIndex: 'OnHand',
			sorter: (a, b) => a.OnHand - b.OnHand,
		}, {
			title: 'Precio',
			dataIndex: 'AvgPrice',
			sorter: (a, b) => a.AvgPrice - b.AvgPrice,
			render: (AvgPrice) => (
				formatCurrency(AvgPrice)
			)
		},
		{
			title: 'Liquidacion',
			dataIndex: 'Liquidacion',
			sorter: (a, b) => a.Liquidacion.localeCompare(b.Liquidacion),
		}, {
			title: 'Precio en liquidacion',
			dataIndex: 'AvgPriceLiquidation',
			sorter: (a, b) => a.AvgPriceLiquidation - b.AvgPriceLiquidation,
			render: (AvgPriceLiquidation) => (formatCurrency(AvgPriceLiquidation))
		}, {
			title: "Action",
			dataIndex: "action",
			render: (_, record) => (
				<div className="action-table-data">
					<div className="edit-delete-action">
						<div className="input-block add-lists"></div>
						<Link className="me-2 p-2" onClick={() => handleShowModal(record)}  >
							<Eye className="feather-view" />
						</Link>
					</div>
				</div>
			),
		},
	]

	const handleSortOrderChange = (selectedOption) => {
		setSortOrder(selectedOption.value);
	};

	const oldandlatestvalue = [
		{ value: "date", label: "Sort by Date" },
		{ value: "newest", label: "Newest" },
		{ value: "oldest", label: "Oldest" },
	];

	useEffect(() => {
		getItemsAndCategory()
	}, [])

	useEffect(() => {
		console.log(categories)
		const formatedCategories = categories.map(cat => ({
			value: cat.LineCode,
			label: cat.LineName,
			children: cat.SubLines.map(sub => ({
				value: sub.SubLineCode,
				label: sub.SubLineName,
			})),
		}));

		setCategoryOptions(formatedCategories)

	}, [categories])

	useEffect(() => {
	}, [selectedCategory])

	return (
		<DashboardLayout>
			<PageTtle
				maintitle='Inventario'
				subtitle='Catálogo de todos los articulos en La Pape '
				toolbar={
					<Toolbar />
				}
			/>

			{/* /product list */}
			<div className='card table-list-card'>
				<div className='card-body'>
					<div className="table-top">
						<div className="search-set">
							<div className="search-input">
								<input
									type='text'
									placeholder='Buscar en la tabla'
									className='form-control form-control-sm formsearch'
									aria-controls='DataTables_Table_0'
									value={searchText}
									onChange={handleSearch}
								/>
								<Link to className="btn btn-searchset">
									<i data-feather="search" className="feather-search" />
								</Link>
							</div>
						</div>
						<div className="search-path">
							<div className="d-flex align-items-center">
								<Link
									className={`btn btn-filter ${isFilterVisible ? "setclose" : ""
										}`}
									id="filter_search"
									onClick={toggleFilterVisibility}
								>
									<Filter
										className="filter-icon"

									/>
									<span >
										<ImageWithBasePath
											src="assets/img/icons/closes.svg"
											alt="img"
										/>
									</span>
								</Link>
							</div>
						</div>
						<div className="form-sort">
							<Sliders className="info-img" />
							<Select
								className="img-select"
								classNamePrefix="react-select"
								options={oldandlatestvalue}
								placeholder="Ordenar"
								onChange={handleSortOrderChange}
							/>

						</div>
					</div>
					<div
						className={`card${isFilterVisible ? " visible" : ""}`}
						id="filter_inputs"
						style={{ display: isFilterVisible ? "block" : "none" }}
					>
						<div className="card-body pb-0">
							<div className="row gy-3"> 
								<div className="col-lg-3 col-sm-6 col-12">
									<div className="input-blocks">
										<Cascader
											removeIcon={<Box className="info-img" />}
											options={categoryOptions}
											onChange={handleCategorySelection}
											placeholder="Categoria"
											loading={isLoading}
											disabled={isLoading}
											style={{ minWidth: '250px', width: '100%', height: '40px' }}
											changeOnSelect
										/>
									</div>
								</div>
								{/* <div className="col-lg-3 col-sm-6 col-12">
									<div className="input-blocks">
										<div className="input-groupicon">
											<Cascader
												removeIcon={<Box className="info-img" />}
												options={categoryOptions}
												onChange={handleCategorySelection}
												placeholder="Categoria"
												loading={isLoading}
												disabled={isLoading}
												style={{ minWidth: '250px', width: '100%', height: '40px' }}
												changeOnSelect
											/>
										</div>
									</div>
								</div>
								<div className="col-lg-3 col-sm-6 col-12">
									<div className="input-blocks">
										<div className="input-groupicon">
											<Cascader
												removeIcon={<Box className="info-img" />}
												options={categoryOptions}
												onChange={handleCategorySelection}
												placeholder="Categoria"
												loading={isLoading}
												disabled={isLoading}
												style={{ minWidth: '250px', width: '100%', height: '40px' }}
												changeOnSelect
											/>
										</div>
									</div>
								</div> */}
								<div className="col-lg-3 col-sm-6 col-12 ms-auto">
									<div className="input-blocks">
										<Link className="btn btn-filters ms-auto" onClick={handleSearchCategory}>
											<i data-feather="search" className="feather-search" /> Search
										</Link>
									</div>
								</div>
							</div>
						</div>

					</div>
					<div className='table-responsive'>
						<Table
							columns={columns}
							dataSource={filteredData}
							loading={isLoading}
							rowKey={(record) => record.ItemCode}
						/>
					</div>
				</div>
			</div>
			{/* /product list */}
			<ModalProductDetail visible={showModal} onClose={handleCloseModal} record={selectedRecord} />

		</DashboardLayout >
	)
}

export default Inventory


