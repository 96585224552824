import React from 'react'
import { useSelector } from 'react-redux'
import { selectItems } from '../redux/selectors/new-sale'
import SaleResumeProduct from '../components/SaleResumeProduct'

export default function SaleResumeProducts() {
	const items = useSelector(selectItems)

	return (
		<div
			className='d-flex flex-column gap-3 border p-3'
			style={{
				width: '55%',
				height: 'fit-content',
			}}>
			{items.map((item) => {
				return <SaleResumeProduct key={item.ItemCode} item={item} />
			})}
		</div>
	)
}
