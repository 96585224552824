import React, { useEffect /* , useState */ } from 'react'
import PropTypes from 'prop-types'
import { Col } from 'antd'
import { Modal } from 'react-bootstrap'
import FormControl from './FormControl'

const { Title } = Modal

CouponsFormTransferApplicability.propTypes = {
	get: PropTypes.func,
	list: PropTypes.array,
	title: PropTypes.string,
	name: PropTypes.string,
	keyProp: PropTypes.string,
	titleProp: PropTypes.string,
	entityText: PropTypes.string,
	applicability: PropTypes.array,
	updateApplicability: PropTypes.func,
	dataApplicability: PropTypes.array,
	updateDataApplicability: PropTypes.func,
	isLoading: PropTypes.bool,
	error: PropTypes.object,
	isApplicable: PropTypes.bool,
	updateIsApplicable: PropTypes.func,
	dataUpdateFormatCoupon: PropTypes.object,
	keyList: PropTypes.string,
}

export default function CouponsFormTransferApplicability({
	get,
	list,
	title,
	name,
	keyProp,
	titleProp,
	entityText,
	applicability,
	updateApplicability,
	dataApplicability,
	updateDataApplicability,
	isLoading,
	error,
	isApplicable,
	/* 	updateIsApplicable, */
	dataUpdateFormatCoupon,
	keyList,
}) {
	const filterOption = (inputValue, option) => {
		return option.title.indexOf(inputValue.toUpperCase()) > -1
	}

	const handleChangeApplicability = (newTargetKeys) => {
		updateApplicability(newTargetKeys)
	}

	useEffect(() => {
		get()
	}, [])

	useEffect(() => {
		let tempItems = []
		if (list.length > 0) {
			list.forEach((item) => {
				tempItems.push({
					key: item[keyProp],
					title: item[titleProp],
					description: '',
					chosen: 0,
				})
			})
		}

		updateDataApplicability(tempItems)
	}, [list])

	useEffect(() => {
		if (dataUpdateFormatCoupon) {
			if (dataUpdateFormatCoupon[keyList]) {
				updateApplicability(dataUpdateFormatCoupon[keyList])
			}
		}
	}, [dataUpdateFormatCoupon])

	return (
		<Col className='w-auto'>
			<Title as={'h4'}>{title}</Title>
			{isLoading ? (
				<span>Cargando...</span>
			) : error ? (
				<span>{error.message}</span>
			) : list.length < 1 ? (
				<span>No se encontraron{` ${entityText}s`}</span>
			) : (
				<>
					{/* <div className='d-flex align-items-center gap-2'>
						<FormControl
							type={'switch'}
							name={`isApplicable${
								name.charAt(0).toUpperCase() + name.slice(1)
							}`}
							label={'Cambiar modo de selección:'}
							attributes={{
								defaultChecked: true,
								value: { isApplicable },
								onChange: () => {
									updateIsApplicable(!isApplicable)
								},
							}}
						/>
					</div> */}
					<FormControl
						name={name}
						type='transfer'
						rules={[
							() => ({
								validator(_, values) {
									const errorValidationRequired = `Seleccionar al menos un(a) ${entityText}`

									if (!values) {
										return Promise.reject(new Error(errorValidationRequired))
									} else {
										if (
											values.filter(
												(value) =>
													typeof value === 'number' || typeof value === 'string'
											).length > 0
										) {
											return Promise.resolve()
										} else {
											return Promise.reject(new Error(errorValidationRequired))
										}
									}
								},
							}),
						]}
						attributes={{
							/* selectedKeys: applicability, */
							dataSource: dataApplicability,
							showSearch: true,
							filterOption: filterOption,
							targetKeys: applicability,
							onChange: handleChangeApplicability,
							render: (item) => item.title,
							pagination: true,
							titles: [
								`Seleccionar ${entityText}s`,
								isApplicable ? 'Aplicar a' : 'No aplicar a',
							],
						}}
					/>
				</>
			)}
		</Col>
	)
}
