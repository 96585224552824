import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	changeScannedProduct,
	changeComponentShow,
} from '../../../redux/catalog'
import { showScannedProductConfig } from '../../../utils/var'
import ScannedItem from '../layouts/ScannedItem'
import { selectIsShowScanedProduct } from '../../../redux/selectors/catalog'
import ImageWithBasePath from '../../../core/img/imagewithbasebath'
import { Link } from 'react-router-dom'

const Scanner = () => {
	const dispatch = useDispatch()
	const isShowScanedProduct = useSelector(selectIsShowScanedProduct)
	const [firstScan, setFirstScan] = useState(true)
	const inputRef = useRef(null)
	const [scannedProduct, setScannedProduct] = useState('')

	useEffect(() => {
		setInterval(function () {
			setFirstScan(true)
		}, 9000)
	}, [setFirstScan])

	useEffect(() => {
		const handleKeyPress = (e) => {
			if (e.key === 'Enter') {
				getProductByCodeBar()
			}
		}

		window.addEventListener('keydown', handleKeyPress)
		return () => {
			window.removeEventListener('keydown', handleKeyPress)
		}
	}, [scannedProduct])

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus()
		}

		const handleClick = (e) => {
			if (inputRef.current && !inputRef.current.contains(e.target)) {
				inputRef.current.focus()
			}
		}

		document.addEventListener('click', handleClick)
		return () => {
			document.removeEventListener('click', handleClick)
		}
	}, [])

	const getProductByCodeBar = () => {
		if (scannedProduct && scannedProduct !== '0') {
			console.log(scannedProduct)
			dispatch(changeScannedProduct(scannedProduct))
			dispatch(changeComponentShow(showScannedProductConfig))
			setScannedProduct('')
			setFirstScan(false) // Cambiar a false cuando se escanee un producto

			if (inputRef.current) {
				inputRef.current.focus()
			}
		}
	}

	return (
		<>
			<div style={{ margin: '7%' }}>
				<input
					ref={inputRef}
					id='searchCodeBar'
					type='text'
					placeholder='Código de barras'
					className='form-control form-control-sm formsearch hidden-input'
					value={scannedProduct}
					onChange={(e) => setScannedProduct(e.target.value)}
				/>
				{firstScan ? (
					<div className='row w-100 align-items-start pos-slick-item'>
						<div
							id='campo'
							style={{
								paddingTop: '200px',
								paddingLeft: '40px',
								textAlign: 'center',
							}}>
							<h2>
								<p>
									Escanee o ingrese el código del artículo para empezar a
									registrar
								</p>
							</h2>
							<Link to='#'>
								<ImageWithBasePath
									src='assets/img/categories/category-01.png'
									alt='Categories'
								/>
							</Link>
						</div>
					</div>
				) : (
					<div style={{ marginLeft: '5%', marginRight: '5%' }}>
						{isShowScanedProduct && <ScannedItem />}
					</div>
				)}
			</div>
		</>
	)
}

export default Scanner
