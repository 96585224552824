import React from 'react';
import PropTypes from 'prop-types';

const FormFooter = ({handleDeleteConfirmation, restoreConfigurations}) => {
    return (
        <div className="modal-footer-btn">
            <button
                onClick={() => handleDeleteConfirmation(restoreConfigurations)}
                type="button"
                className="btn btn-cancel me-2"
            >
                Eliminar cambios guardados
            </button>
            <button type="submit" className="btn btn-submit">
                Guardar cambios
            </button>
        </div>
    );
};

FormFooter.propTypes = {
    handleDeleteConfirmation: PropTypes.func.isRequired,
    restoreConfigurations: PropTypes.func.isRequired,
};

export default FormFooter;