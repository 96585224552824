import React from 'react'
import ImageWithBasePath from '../../../core/img/imagewithbasebath'
import { Link } from 'react-router-dom'
import Switch from '../components/Switch'
import Lector from '../components/Lector'

export default function WaitScan() {
	return (
		<>
			<div className='d-flex flex-column '>
				<div
					className='d-flex flex-row align-items-start justify-content-between'
					style={{ marginTop: 30 + 'px', marginRight: 20 + 'px' }}>
					<Lector />
					<Switch />
				</div>
				<div className='row w-100 align-items-start pos-slick-item'>
					<div
						id='campo'
						style={{
							paddingTop: 200 + 'px',
							paddingLeft: 40 + 'px',
							textAlign: 'center',
						}}>
						<h2>
							<p>
								Escanee o ingrese el código del artículo para empezar a registrar
							</p>
						</h2>
						<Link to='#'>
							<ImageWithBasePath
								src='assets/img/categories/category-01.png'
								alt='Categories'
							/>
						</Link>
					</div>
				</div>
			</div>
		</>
	)
}
