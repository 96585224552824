import React, { useContext } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { OrdersCashCountContext } from '../provider/OrdersCashCount'

export default function CancelOrder() {
	const {
		isCancelShow,
		updateIsCancelShow,
		idOrderCancel,
		isLoadingCancelOrder,
		cancelOrder,
	} = useContext(OrdersCashCountContext)

	return (
		<Modal size='md' centered show={isCancelShow}>
			<Modal.Header>
				<Modal.Title>Cancelar Orden</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>¿Seguro que desea cancelar la orden #{idOrderCancel}?</p>
			</Modal.Body>
			<Modal.Footer>
				<Button
					onClick={() => updateIsCancelShow(false)}
					disabled={isLoadingCancelOrder}
					variant='pape_cancel'>
					Cancelar
				</Button>
				<Button onClick={() => cancelOrder()} disabled={isLoadingCancelOrder}>
					Continuar
					{isLoadingCancelOrder && (
						<div
							className='spinner-border spinner-border-sm ms-2'
							role='status'>
							<span className='sr-only'>Loading...</span>
						</div>
					)}
				</Button>
			</Modal.Footer>
		</Modal>
	)
}
