import React, { useContext, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { CouponsContext } from '../provider/Coupons'
import { Collapse, Descriptions, Empty, Flex, Spin } from 'antd'
import { formatDataCouponDetails } from '../utils/functions/formatData'

export default function CouponDetail() {
	const {
		activeCoupon,
		updateActiveCoupon,
		dataActiveCoupon,
		updateDataActiveCoupon,
		isShowDetailModal,
		isLoadingShowCoupon,
		updateIsShowDetailModal,
		getCoupon,
		errorActiveCoupon,
		updateDataDetailCoupon,
		dataDetailCoupon,
	} = useContext(CouponsContext)

	useEffect(() => {
		if (isShowDetailModal && activeCoupon) {
			getCoupon()
		}
	}, [isShowDetailModal])

	useEffect(() => {
		if (dataActiveCoupon && isShowDetailModal) {
			updateDataDetailCoupon(formatDataCouponDetails(dataActiveCoupon))
		}
	}, [isShowDetailModal, dataActiveCoupon])

	return (
		<Modal
			centered
			show={isShowDetailModal}
			size='xl'
			backdrop='static'
			scrollable>
			<Modal.Header>
				<Modal.Title as='h2'>Detalle de Cupón</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{isLoadingShowCoupon ? (
					<Flex gap='middle'>
						<Spin tip='Loading' size='large' />
					</Flex>
				) : errorActiveCoupon ? (
					<span>{errorActiveCoupon.message}</span>
				) : dataDetailCoupon ? (
					<>
						<Descriptions
							layout='vertical'
							bordered
							title='Detalles del cupón'
							items={dataDetailCoupon.details}
						/>
						{dataDetailCoupon.applicabilities &&
						dataDetailCoupon.applicabilities.length > 0 ? (
							<Collapse
								className='mt-3'
								items={dataDetailCoupon.applicabilities}
							/>
						) : (
							<></>
						)}
					</>
				) : (
					<Empty />
				)}
			</Modal.Body>
			<Modal.Footer>
				<Button
					className='me-2 mt-4 py-3 px-4'
					variant='pape_cancel'
					onClick={() => {
						updateIsShowDetailModal(false)
						updateActiveCoupon(null)
						updateDataActiveCoupon(null)
						updateDataDetailCoupon(null)
					}}>
					Cerrar
				</Button>
			</Modal.Footer>
		</Modal>
	)
}
