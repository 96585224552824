import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { idDevice } from '../utils/var'
import { ROUTES } from '../utils/var'

export default function MachineConfiguredRoute() {
	return idDevice() ? (
		<Outlet />
	) : (
		<Navigate to={ROUTES.MACHINE_CONFIGURE_WARNING} />
	)
}
