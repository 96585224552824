import React from 'react'
import { PropTypes } from 'prop-types'

DashboardLayout.propTypes = {
	children: PropTypes.node,
}

export default function DashboardLayout({ children }) {
	return (
		<div className='page-wrapper'>
			<div className='content'>
				{children}
			</div>
		</div>
	)
}
