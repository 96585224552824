import React, {  useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	fetchCategories,
	changeComponentShow,
} from '../../../redux/catalog'
import { selectIsShowCatalog, selectIsShowWarning } from '../../../redux/selectors/catalog'
import { selectCategories } from '../../../redux/selectors/catalog'
import {
	showCatalogConfig,
	showShowWarningConfig,
    showMenuConfig,
} from '../../../utils/var'
import { useLocation } from 'react-router-dom'
import ImageWithBasePath from '../../../core/img/imagewithbasebath'
 
export default function Menu() {
	const location = useLocation()
	const dispatch = useDispatch()
	const categories = useSelector(selectCategories)
	const isShowCatalog = useSelector(selectIsShowCatalog)
    const isShowWarning = useSelector(selectIsShowWarning)

	const getCatalog = async () => {
		if (categories.length < 2) {
			dispatch(fetchCategories())
		}
		dispatch(
			changeComponentShow(
				isShowCatalog ? showShowWarningConfig : showCatalogConfig
			)
		)
	}

    const getScanner = async () => {
        dispatch(
            changeComponentShow(
                isShowWarning ? showMenuConfig : showShowWarningConfig
            )
        )
    }

	useEffect(() => {
		if (categories.length < 2) {
			dispatch(fetchCategories())
		}
	}, [location])

	return (
		<div
			className='d-flex flex-row align-items-center justify-content-evenly'
            style={{width:800+'px', height:600+'px'}}>
			<button
				id='mostrarCata'
				type='button'
				style={{ padding:10 + 'px', borderRadius: 20 + 'px', width: 290+'px' }}
				className='btn btn-primary d-flex flex-column align-items-center'
				onClick={getCatalog}>
                    <ImageWithBasePath
						src='assets/img/icons/dash7.svg'
						alt='Categories'
                        width={220+'px'}    
					/>
                <br></br>
				<h2 style={{color:'white'}}>Mostrar catalogo</h2>
			</button>
            <button
				id='mostrarScanner'
				type='button'
				style={{ padding:15 + 'px', borderRadius: 20 + 'px' }}
				className='btn btn-info d-flex flex-column align-items-center'
				onClick={getScanner}>
                <ImageWithBasePath
                    src='assets/img/icons/scanner1.svg'
                    alt='Scanner'
                    width={200+'px'}
                />
                <br></br>
				<h2 style={{color:'white'}}>Empezar a escanear</h2>
			</button>
		</div>
	)
}
