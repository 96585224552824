import React from 'react';
import { Table } from 'antd'
import { tableOrdersCashCountColumns2 } from '../../../utils/datatablesVar'
import FilterTable from './FilterTable'
import { PropTypes } from 'prop-types'

const TableOrder = ({ isLoadingOrders, updateSearchOrder, handleShowModal, showCancel, filteredData, searchOrder }) => {
    return (
        <div className='card table-list-card'>
            <div className='card-body'>
                <FilterTable updateSearchOrder={updateSearchOrder} searchOrder={searchOrder} />
                <div className='table-responsive'>
                    <Table
                        rowKey='DocEntry'
                        columns={tableOrdersCashCountColumns2(handleShowModal, showCancel)}
                        dataSource={filteredData}
                        loading={isLoadingOrders}
                    />
                </div>
            </div>
        </div>
    );
};

TableOrder.propTypes = {
    isLoadingOrders: PropTypes.any,
    handleShowModal: PropTypes.any,
    updateSearchOrder: PropTypes.any,
    showCancel: PropTypes.any,
    filteredData: PropTypes.any,
    searchOrder: PropTypes.func
};

export default TableOrder;