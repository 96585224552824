import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { selectAuthenticationIsAuthenticated } from '../redux/selectors/authentication'
import { useSelector } from 'react-redux'
import { ROUTES } from '../utils/var'

export default function AuthenticatedRoute() {
	const isAuthenticated = useSelector(selectAuthenticationIsAuthenticated)

	return (
		<>{isAuthenticated ? <Outlet /> : <Navigate to={ROUTES.LOGIN} replace />}</>
	)
}
