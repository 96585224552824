import React from 'react'
import PropTypes from 'prop-types'
import { Spin } from 'antd'
import InputBlock from './InputBlock'
import SaleDetails from './SaleDetails'
import { useSelector, useDispatch } from 'react-redux'
import { searchItem } from '../redux/new-sale'
import SaleProducts from './SaleProducts'

const SaleDataSection = () => {
	const dispatch = useDispatch()
	const { loading } = useSelector((state) => state.sale)

	const [query, setQuery] = React.useState('')

	const handleSearch = (e) => {
		e.preventDefault()
		if (query.trim()) {
			dispatch(searchItem(query))
		}
	}

	return (
		<>
			{loading ? (
				<Spin spinning={true} />
			) : (
				<div className='barcode-content-list' style={{ marginBottom: '15px' }}>
					<div className='page-title'>
						<h4>DATOS DE LA VENTA</h4>
					</div>
					<div className='col-lg-12'>
						<div className='row' style={{ justifyContent: 'end' }}>
							<div className='col-lg-4'>
								<InputBlock
									placeholder='Código de Barras o Número de parte'
									isSearchable={true}
									enablePrefix={true}
									value={query}
									onChange={(e) => setQuery(e.target.value)}
									onKeyPress={(e) => {
										if (e.key === 'Enter') handleSearch(e) // Buscar al presionar Enter
									}}
								/>
							</div>
						</div>
					</div>
					<SaleProducts />
					<SaleDetails />
				</div>
			)}
		</>
	)
}

SaleDataSection.propTypes = {
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			dataIndex: PropTypes.string.isRequired,
			key: PropTypes.string,
		})
	),
}

export default SaleDataSection
