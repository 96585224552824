import React, { useEffect, useState } from 'react'
import { Modal, Button, Form, Col, Row } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import {
	selectWithdrawIsShowModalWithdraw,
	selectSaldoRetirado,
	selectGerente,
	selectHora,
	selectFecha,
	selectIsLoading,
	selectStatus,
	selectError,
	selectPlace,
} from '../../../redux/selectors/withdraw'
import { idDevice } from '../../../utils/var'
import {
	transactWithdraw,
	changeGerente,
	changeEfectivoRetiro,
	changeStatus,
	verifyNeedWithdraw,
	cleanWithdraw,
	changePlaceToAdmin,
	changePlaceToPOS,
} from '../../../redux/withdraw'
import { logout } from '../../../redux/authentication'
import { convertMilitaryTimeToStandardTimeFormat } from '../../../utils/functions/date'
import { useLocation } from 'react-router-dom'
import { updateIsShowConfirm } from '../../cash-count/redux/cash-count'

export default function WithdrawModal() {
	const dispatch = useDispatch()
	const location = useLocation()
	const saldoRetirado = useSelector(selectSaldoRetirado)
	const gerente = useSelector(selectGerente)
	const hora = useSelector(selectHora)
	const fecha = useSelector(selectFecha)
	const isLoading = useSelector(selectIsLoading)
	const status = useSelector(selectStatus)
	const error = useSelector(selectError)
	const isShowModalWithdraw = useSelector(selectWithdrawIsShowModalWithdraw)
	const place = useSelector(selectPlace)
	const [validated, setValidated] = useState(false)
	const [password, setPassword] = useState('')

	const continueWithdraw = (event) => {
		event.preventDefault()
		const form = event.currentTarget
		setValidated(true)

		if (form.checkValidity() === false) {
			event.stopPropagation()
		} else {
			dispatch(transactWithdraw({ password }))
			setPassword('')
			setValidated(false)
			dispatch(changeGerente(''))
		}
	}

	const handlePassword = (event) => {
		setPassword(event.target.value)
	}

	const handleSaldoRetirado = (event) => {
		const value = event.target.value
		const regexNumberTwoDecimals = /^\d*\.?\d{0,2}$/
		/*/^\d*\.?\d{0,2}$/*/

		if (regexNumberTwoDecimals.test(value) || value === '') {
			dispatch(changeEfectivoRetiro(value))
		}
	}

	const closeRetiro = () => {
		dispatch(changeStatus('noinit'))
		dispatch(verifyNeedWithdraw(false))
		if (place === 'admin') {
			dispatch(updateIsShowConfirm(true))
		} else {
			dispatch(updateIsShowConfirm(false))
			dispatch(cleanWithdraw())
		}
	}

	useEffect(() => {
		if (location.pathname === '/admin/corte-caja') {
			dispatch(changePlaceToAdmin())
		} else {
			dispatch(changePlaceToPOS())
		}
	}, [])

	return (
		<>
			<Modal
				size={status === 'complete' ? 'md' : 'lg'}
				centered
				show={isShowModalWithdraw}>
				<Modal.Header>
					<Modal.Title as='h2'>Retirar efectivo de caja</Modal.Title>
					{status === 'complete' ? (
						<></>
					) : (
						<Button
							onClick={() => {
								dispatch(logout())
							}}
							disabled={isLoading}>
							Cerrar Sesión
						</Button>
					)}
				</Modal.Header>
				<Modal.Body>
					{status == 'complete' ? (
						<p>Se realizo el retiro correctamente.</p>
					) : (
						<Form
							noValidate
							validated={validated}
							onSubmit={(event) => continueWithdraw(event)}
							className='d-flex flex-column gap-4'>
							<Row>
								<Form.Group as={Col}>
									<Form.Label>Id del gerente</Form.Label>
									<Form.Control
										size='lg'
										type='text'
										placeholder='Id del gerente'
										name='idGerente'
										value={gerente}
										disabled={isLoading}
										required
										pattern='\d*'
										onChange={(event) =>
											dispatch(changeGerente(event.target.value))
										}
									/>
									<Form.Control.Feedback type='invalid'>
										Campo obligatorio y numerico
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group as={Col}>
									<Form.Label>Contraseña:</Form.Label>
									<Form.Control
										size='lg'
										type='password'
										placeholder='Password'
										name='password'
										value={password}
										disabled={isLoading}
										required
										maxLength={50}
										onChange={(event) => handlePassword(event)}
									/>
									<Form.Control.Feedback type='invalid'>
										Campo obligatorio
									</Form.Control.Feedback>
								</Form.Group>
							</Row>
							<Row>
								<Form.Group as={Col}>
									<Form.Label>Caja:</Form.Label>
									<Form.Control
										size='lg'
										type='text'
										placeholder='Caja'
										name='caja'
										value={idDevice()}
										required
										disabled
									/>
									<Form.Control.Feedback type='invalid'>
										Campo obligatorio
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group as={Col}>
									<Form.Label>Efectivo a retirar</Form.Label>
									<Form.Control
										size='lg'
										type='text'
										placeholder='Efectivo a retirar'
										name='retiro'
										value={saldoRetirado}
										required
										onChange={(event) => handleSaldoRetirado(event)}
										pattern='(?!0(\.0{1,2})?$)\d*\.?\d{0,2}'
									/>
									<Form.Control.Feedback type='invalid'>
										Campo obligatorio ý numerico mayor que cero
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group as={Col}>
									<Form.Label>Fondo de caja:</Form.Label>
									<Form.Control
										size='lg'
										type='text'
										placeholder='Fondo de caja'
										name='fondo'
										disabled
										value='$500'
										required
									/>
									<Form.Control.Feedback type='invalid'>
										Campo obligatorio y numerico
									</Form.Control.Feedback>
								</Form.Group>
							</Row>
							<Row>
								<Form.Group as={Col}>
									<Form.Label>Fecha de retiro:</Form.Label>
									<Form.Control
										size='lg'
										type='text'
										placeholder='Fecha'
										name='fecha'
										value={fecha}
										required
										disabled
									/>
									<Form.Control.Feedback type='invalid'>
										Campo obligatorio
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group as={Col}>
									<Form.Label>Hora de retiro:</Form.Label>
									<Form.Control
										size='lg'
										type='text'
										placeholder='Hora de retiro'
										name='hora'
										value={convertMilitaryTimeToStandardTimeFormat(hora)}
										required
										disabled
									/>
									<Form.Control.Feedback type='invalid'>
										Campo obligatorio y numerico
									</Form.Control.Feedback>
								</Form.Group>
							</Row>
							{status == 'error' ? (
								<p className='text-danger'>{error}</p>
							) : (
								<></>
							)}
							<Col sm={{ span: 10, offset: 0 }} className=''>
								<Button
									type='submit'
									className='btn btn-save'
									disabled={isLoading}>
									Realizar retiro
									{isLoading && (
										<div
											className='spinner-border spinner-border-sm ms-2'
											role='status'>
											<span className='sr-only'>Loading...</span>
										</div>
									)}
								</Button>
								<Button
									type='button'
									className='btn btn-save ms-2'
									onClick={() => {
										dispatch(updateIsShowConfirm(false))
										dispatch(cleanWithdraw())
									}}
									disabled={isLoading}>
									Cancelar
								</Button>
								{place === 'admin' ? (
									<Button
										type='button'
										className='btn btn-save ms-2'
										onClick={() => {
											dispatch(cleanWithdraw())
											dispatch(updateIsShowConfirm(true))
										}}
										disabled={isLoading}>
										Continuar con corte
									</Button>
								) : (
									<></>
								)}
							</Col>
						</Form>
					)}
				</Modal.Body>
				<Modal.Footer>
					{status == 'complete' ? (
						<Button
							onClick={() => closeRetiro()}
							type='button'
							className='btn btn-save'>
							Cerrar
						</Button>
					) : (
						<></>
					)}
				</Modal.Footer>
			</Modal>
		</>
	)
}
