import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
	selectFilters,
	selectCategories,
	selectCategory,
	selectCategoriesError,
} from '../../../redux/selectors/catalog'
import { changeFilter, changeSearch } from '../../../redux/catalog'
import { Form, Dropdown } from 'react-bootstrap'

export default function Subcategory() {
	const categories = useSelector(selectCategories)
	const category = useSelector(selectCategory)
	const filters = useSelector(selectFilters)
	const categoriesError = useSelector(selectCategoriesError)
	const dispatch = useDispatch()
	const [searchTerm, setSearchTerm] = useState('')
	const [selectedOption, setSelectedOption] = useState(null)
	const [options, setOptions] = useState([])
	const [subcategories, setSubcategories] = useState([])
	const [text, setText] = useState('Seleccionar')

	const handleChange = (e) => {
		setSearchTerm(e.target.value)
	}

	useEffect(() => {
		setText('Seleccionar')
		if (category && categories && categories.length > 0) {
			const categoriesSelected = categories.find(
				(categoryStored) => categoryStored.LineCode == category
			)

			if (
				categoriesSelected.SubLines &&
				categoriesSelected.SubLines.length > 0
			) {
				const subcategories = categoriesSelected.SubLines.map((Subline) => {
					if (!Subline.SubLineCode && !Subline.SubLineName) {
						return { SubLineCode: 'NA', SubLineName: 'SIN SUBCATEGORÍA' }
					}
					return Subline
				})
				setSubcategories(subcategories)
			} else {
				setSubcategories([])
			}
		}
	}, [category])

	useEffect(() => {
		const resultadosFiltrados = subcategories.filter((subcategory) =>
			subcategory.SubLineName.toLowerCase().includes(searchTerm.toLowerCase())
		)
		setOptions(resultadosFiltrados)
	}, [searchTerm])

	useEffect(() => {
		setOptions(subcategories)
	}, [subcategories])

	useEffect(() => {
		if (selectedOption) {
			setText(selectedOption.SubLineName)
			dispatch(changeSearch(''))
			dispatch(
				changeFilter({
					...filters,
					subcategory: selectedOption.SubLineCode,
				})
			)
		}
	}, [selectedOption])

	return !categoriesError ? (
		<div className='d-flex flex-column gap-2'>
			<h5>Subcategoría</h5>

			{category ? (
				subcategories.length > 0 ? (
					<Dropdown>
						<Dropdown.Toggle
							id='dropdown-basic'
							style={{
								width: '150px',
								whiteSpace: 'nowrap',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
							}}>
							{text}
						</Dropdown.Toggle>

						<Dropdown.Menu style={{ height: '300px', overflowY: 'scroll' }}>
							<Form.Control
								autoFocus
								className='mx-3 my-2 w-auto'
								placeholder='Buscar...'
								onChange={(e) => handleChange(e)}
								value={searchTerm}
							/>
							{options.map((option) => (
								<Dropdown.Item
									key={option.SubLineCode}
									onClick={() => setSelectedOption(option)}>
									{option.SubLineName}
								</Dropdown.Item>
							))}
						</Dropdown.Menu>
					</Dropdown>
				) : (
					<span>
						No hay subcategorías <br /> disponibles
					</span>
				)
			) : (
				<span>
					Seleccione una <br /> categoría
				</span>
			)}
		</div>
	) : (
		<></>
	)
}
