import React, { useContext } from 'react'
import { Button } from 'react-bootstrap'
import { PinpadContext } from '../../../provider/Pinpad'

export default function DeviceSelection() {
	const { portSelected, showPortsToPermission } = useContext(PinpadContext)

	return (
		<div className='col-xl'>
			<div className='card text-center'>
				<div className='card-body'>
					<h6 className='card-title fw-semibold mb-2'>
						Dispositivo seleccionado:
					</h6>
					{portSelected ? (
						<p className='card-text mb-4'>
							Vendor ID: {portSelected.usbVendorId}, Product ID:{' '}
							{portSelected.usbProductId}
						</p>
					) : (
						<p className='card-text mb-4'>Ningún dispositivo seleccionado</p>
					)}
					<Button
						onClick={showPortsToPermission}
						variant='primary'
						className='me-2'>
						Seleccionar Dispositivo
					</Button>
				</div>
				<div className='card-footer text-muted'>Verifone P400</div>
			</div>
		</div>
	)
}
