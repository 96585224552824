import React, { useState } from 'react'
import Scrollbars from 'react-custom-scrollbars-2'
import HorizontalSidebar from './horizontalSidebar'
import CollapsedSidebar from './collapsedSidebar'
import { Link, useLocation } from 'react-router-dom'
import { SidebarDataPape } from '../../core/json/sidebar_data_pape'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../redux/authentication'
import { verifyUserRole } from '../../utils/functions/authorization'
import { selectAuthenticationUser } from '../../redux/selectors/authentication'

const Sidebar = () => {
	const Location = useLocation()
	const dispatch = useDispatch()
	const user = useSelector(selectAuthenticationUser)

	const [subOpen, setSubopen] = useState('')
	const [subsidebar, setSubsidebar] = useState('')
	const handleLogout = () => {
		dispatch(logout())
	}
	const functions = {
		logout: handleLogout,
	}

	const toggleSidebar = (title) => {
		if (title == subOpen) {
			setSubopen('')
		} else {
			setSubopen(title)
		}
	}

	const toggleSubsidebar = (subitem) => {
		if (subitem == subsidebar) {
			setSubsidebar('')
		} else {
			setSubsidebar(subitem)
		}
	}

	return (
		<div>
			<div className='sidebar' id='sidebar' style={{ top: '7%' }}>
				<Scrollbars>
					{/* background:'#f3f3f3', */}
					{/* <div style={{ padding: 10 + '%' }}>
						<ImageWithBasePath
							src='assets/img/login-images/lapape-logo.png'
							alt='img'
							width={150}
						/>
					</div> */}
					<div className='sidebar-inner slimscroll'>
						<div id='sidebar-menu' className='sidebar-menu'>
							<ul>
								{SidebarDataPape?.map((mainLabel, index) => {
									return mainLabel.roles ? (
										verifyUserRole(user, mainLabel.roles) ? (
											<li className='submenu-open' key={index}>
												<h6 className='submenu-hdr'>{mainLabel?.label}</h6>
												<ul>
													{mainLabel?.submenuItems?.map((title, i) => {
														let link_array = []
														title?.submenuItems?.map((link) => {
															link_array.push(link?.link)
															if (link?.submenu) {
																link?.submenuItems?.map((item) => {
																	link_array.push(item?.link)
																})
															}
															return link_array
														})
														title.links = link_array
														return title.roles ? (
															verifyUserRole(user, title.roles) ? (
																<React.Fragment key={i}>
																	{' '}
																	<li
																		className={`submenu ${
																			!title?.submenu &&
																			Location.pathname === title?.link
																				? 'custom-active-hassubroute-false'
																				: ''
																		}`}>
																		{title.link === '#' ? (
																			<button
																				onClick={() =>
																					functions[title.function]()
																				}
																				className={`${
																					subOpen === title?.label
																						? 'subdrop'
																						: ''
																				} ${
																					title?.links?.includes(
																						Location.pathname
																					)
																						? 'active'
																						: ''
																				}`}>
																				{title?.icon}
																				<span className='custom-active-span'>
																					{title?.label}
																				</span>
																				{title?.submenu && (
																					<span className='menu-arrow' />
																				)}
																			</button>
																		) : (
																			<Link
																				// reloadDocument
																				to={title?.link}
																				onClick={() =>
																					toggleSidebar(title?.label)
																				}
																				className={`${
																					subOpen === title?.label
																						? 'subdrop'
																						: ''
																				} ${
																					title?.links?.includes(
																						Location.pathname
																					)
																						? 'active'
																						: ''
																				}`}>
																				{title?.icon}
																				<span className='custom-active-span'>
																					{title?.label}
																				</span>
																				{title?.submenu && (
																					<span className='menu-arrow' />
																				)}
																			</Link>
																		)}
																		<ul
																			style={{
																				display:
																					subOpen === title?.label
																						? 'block'
																						: 'none',
																			}}>
																			{title?.submenuItems?.map(
																				(item, titleIndex) => (
																					<li
																						className='submenu submenu-two'
																						key={titleIndex}>
																						<Link
																							// reloadDocument
																							to={item?.link}
																							className={`${
																								item?.submenuItems
																									?.map((link) => link.link)
																									.includes(
																										Location.pathname
																									) ||
																								item?.link === Location.pathname
																									? 'active'
																									: ''
																							} ${
																								subsidebar === item?.label
																									? 'subdrop'
																									: ''
																							}`}
																							onClick={() =>
																								toggleSubsidebar(item?.label)
																							}>
																							{item?.label}
																							{item?.submenu && (
																								<span className='menu-arrow inside-submenu' />
																							)}
																						</Link>
																						<ul
																							style={{
																								display:
																									subsidebar === item?.label
																										? 'block'
																										: 'none',
																							}}>
																							{item?.submenuItems?.map(
																								(items, subIndex) => (
																									<li key={subIndex}>
																										<Link
																											// reloadDocument
																											to={items?.link}
																											className={`${
																												subsidebar ===
																												items?.label
																													? 'submenu-two subdrop'
																													: 'submenu-two'
																											} ${
																												items?.submenuItems
																													?.map(
																														(link) => link.link
																													)
																													.includes(
																														Location.pathname
																													) ||
																												items?.link ===
																													Location.pathname
																													? 'active'
																													: ''
																											}`}>
																											{items?.label}
																										</Link>
																									</li>
																								)
																							)}
																						</ul>
																					</li>
																				)
																			)}
																		</ul>
																	</li>
																</React.Fragment>
															) : (
																<></>
															)
														) : (
															<React.Fragment key={i}>
																{' '}
																<li
																	className={`submenu ${
																		!title?.submenu &&
																		Location.pathname === title?.link
																			? 'custom-active-hassubroute-false'
																			: ''
																	}`}>
																	{title.link === '#' ? (
																		<button
																			onClick={() =>
																				functions[title.function]()
																			}
																			className={`${
																				subOpen === title?.label
																					? 'subdrop'
																					: ''
																			} ${
																				title?.links?.includes(
																					Location.pathname
																				)
																					? 'active'
																					: ''
																			}`}>
																			{title?.icon}
																			<span className='custom-active-span'>
																				{title?.label}
																			</span>
																			{title?.submenu && (
																				<span className='menu-arrow' />
																			)}
																		</button>
																	) : (
																		<Link
																			// reloadDocument
																			to={title?.link}
																			onClick={() =>
																				toggleSidebar(title?.label)
																			}
																			className={`${
																				subOpen === title?.label
																					? 'subdrop'
																					: ''
																			} ${
																				title?.links?.includes(
																					Location.pathname
																				)
																					? 'active'
																					: ''
																			}`}>
																			{title?.icon}
																			<span className='custom-active-span'>
																				{title?.label}
																			</span>
																			{title?.submenu && (
																				<span className='menu-arrow' />
																			)}
																		</Link>
																	)}
																	<ul
																		style={{
																			display:
																				subOpen === title?.label
																					? 'block'
																					: 'none',
																		}}>
																		{title?.submenuItems?.map(
																			(item, titleIndex) => (
																				<li
																					className='submenu submenu-two'
																					key={titleIndex}>
																					<Link
																						// reloadDocument
																						to={item?.link}
																						className={`${
																							item?.submenuItems
																								?.map((link) => link.link)
																								.includes(Location.pathname) ||
																							item?.link === Location.pathname
																								? 'active'
																								: ''
																						} ${
																							subsidebar === item?.label
																								? 'subdrop'
																								: ''
																						}`}
																						onClick={() =>
																							toggleSubsidebar(item?.label)
																						}>
																						{item?.label}
																						{item?.submenu && (
																							<span className='menu-arrow inside-submenu' />
																						)}
																					</Link>
																					<ul
																						style={{
																							display:
																								subsidebar === item?.label
																									? 'block'
																									: 'none',
																						}}>
																						{item?.submenuItems?.map(
																							(items, subIndex) => (
																								<li key={subIndex}>
																									<Link
																										// reloadDocument
																										to={items?.link}
																										className={`${
																											subsidebar ===
																											items?.label
																												? 'submenu-two subdrop'
																												: 'submenu-two'
																										} ${
																											items?.submenuItems
																												?.map(
																													(link) => link.link
																												)
																												.includes(
																													Location.pathname
																												) ||
																											items?.link ===
																												Location.pathname
																												? 'active'
																												: ''
																										}`}>
																										{items?.label}
																									</Link>
																								</li>
																							)
																						)}
																					</ul>
																				</li>
																			)
																		)}
																	</ul>
																</li>
															</React.Fragment>
														)
													})}
												</ul>
											</li>
										) : (
											<></>
										)
									) : (
										<li className='submenu-open' key={index}>
											<h6 className='submenu-hdr'>{mainLabel?.label}</h6>
											<ul>
												{mainLabel?.submenuItems?.map((title, i) => {
													let link_array = []
													title?.submenuItems?.map((link) => {
														link_array.push(link?.link)
														if (link?.submenu) {
															link?.submenuItems?.map((item) => {
																link_array.push(item?.link)
															})
														}
														return link_array
													})
													title.links = link_array
													return title.roles ? (
														verifyUserRole(user, title.roles) ? (
															<React.Fragment key={i}>
																{' '}
																<li
																	className={`submenu ${
																		!title?.submenu &&
																		Location.pathname === title?.link
																			? 'custom-active-hassubroute-false'
																			: ''
																	}`}>
																	{title.link === '#' ? (
																		<button
																			onClick={() =>
																				functions[title.function]()
																			}
																			className={`${
																				subOpen === title?.label
																					? 'subdrop'
																					: ''
																			} ${
																				title?.links?.includes(
																					Location.pathname
																				)
																					? 'active'
																					: ''
																			}`}>
																			{title?.icon}
																			<span className='custom-active-span'>
																				{title?.label}
																			</span>
																			{title?.submenu && (
																				<span className='menu-arrow' />
																			)}
																		</button>
																	) : (
																		<Link
																			// reloadDocument
																			to={title?.link}
																			onClick={() =>
																				toggleSidebar(title?.label)
																			}
																			className={`${
																				subOpen === title?.label
																					? 'subdrop'
																					: ''
																			} ${
																				title?.links?.includes(
																					Location.pathname
																				)
																					? 'active'
																					: ''
																			}`}>
																			{title?.icon}
																			<span className='custom-active-span'>
																				{title?.label}
																			</span>
																			{title?.submenu && (
																				<span className='menu-arrow' />
																			)}
																		</Link>
																	)}
																	<ul
																		style={{
																			display:
																				subOpen === title?.label
																					? 'block'
																					: 'none',
																		}}>
																		{title?.submenuItems?.map(
																			(item, titleIndex) => (
																				<li
																					className='submenu submenu-two'
																					key={titleIndex}>
																					<Link
																						// reloadDocument
																						to={item?.link}
																						className={`${
																							item?.submenuItems
																								?.map((link) => link.link)
																								.includes(Location.pathname) ||
																							item?.link === Location.pathname
																								? 'active'
																								: ''
																						} ${
																							subsidebar === item?.label
																								? 'subdrop'
																								: ''
																						}`}
																						onClick={() =>
																							toggleSubsidebar(item?.label)
																						}>
																						{item?.label}
																						{item?.submenu && (
																							<span className='menu-arrow inside-submenu' />
																						)}
																					</Link>
																					<ul
																						style={{
																							display:
																								subsidebar === item?.label
																									? 'block'
																									: 'none',
																						}}>
																						{item?.submenuItems?.map(
																							(items, subIndex) => (
																								<li key={subIndex}>
																									<Link
																										// reloadDocument
																										to={items?.link}
																										className={`${
																											subsidebar ===
																											items?.label
																												? 'submenu-two subdrop'
																												: 'submenu-two'
																										} ${
																											items?.submenuItems
																												?.map(
																													(link) => link.link
																												)
																												.includes(
																													Location.pathname
																												) ||
																											items?.link ===
																												Location.pathname
																												? 'active'
																												: ''
																										}`}>
																										{items?.label}
																									</Link>
																								</li>
																							)
																						)}
																					</ul>
																				</li>
																			)
																		)}
																	</ul>
																</li>
															</React.Fragment>
														) : (
															<></>
														)
													) : (
														<React.Fragment key={i}>
															{' '}
															<li
																className={`submenu ${
																	!title?.submenu &&
																	Location.pathname === title?.link
																		? 'custom-active-hassubroute-false'
																		: ''
																}`}>
																{title.link === '#' ? (
																	<button
																		onClick={() => functions[title.function]()}
																		className={`${
																			subOpen === title?.label ? 'subdrop' : ''
																		} ${
																			title?.links?.includes(Location.pathname)
																				? 'active'
																				: ''
																		}`}>
																		{title?.icon}
																		<span className='custom-active-span'>
																			{title?.label}
																		</span>
																		{title?.submenu && (
																			<span className='menu-arrow' />
																		)}
																	</button>
																) : (
																	<Link
																		// reloadDocument
																		to={title?.link}
																		onClick={() => toggleSidebar(title?.label)}
																		className={`${
																			subOpen === title?.label ? 'subdrop' : ''
																		} ${
																			title?.links?.includes(Location.pathname)
																				? 'active'
																				: ''
																		}`}>
																		{title?.icon}
																		<span className='custom-active-span'>
																			{title?.label}
																		</span>
																		{title?.submenu && (
																			<span className='menu-arrow' />
																		)}
																	</Link>
																)}
																<ul
																	style={{
																		display:
																			subOpen === title?.label
																				? 'block'
																				: 'none',
																	}}>
																	{title?.submenuItems?.map(
																		(item, titleIndex) => (
																			<li
																				className='submenu submenu-two'
																				key={titleIndex}>
																				<Link
																					// reloadDocument
																					to={item?.link}
																					className={`${
																						item?.submenuItems
																							?.map((link) => link.link)
																							.includes(Location.pathname) ||
																						item?.link === Location.pathname
																							? 'active'
																							: ''
																					} ${
																						subsidebar === item?.label
																							? 'subdrop'
																							: ''
																					}`}
																					onClick={() =>
																						toggleSubsidebar(item?.label)
																					}>
																					{item?.label}
																					{item?.submenu && (
																						<span className='menu-arrow inside-submenu' />
																					)}
																				</Link>
																				<ul
																					style={{
																						display:
																							subsidebar === item?.label
																								? 'block'
																								: 'none',
																					}}>
																					{item?.submenuItems?.map(
																						(items, subIndex) => (
																							<li key={subIndex}>
																								<Link
																									// reloadDocument
																									to={items?.link}
																									className={`${
																										subsidebar === items?.label
																											? 'submenu-two subdrop'
																											: 'submenu-two'
																									} ${
																										items?.submenuItems
																											?.map((link) => link.link)
																											.includes(
																												Location.pathname
																											) ||
																										items?.link ===
																											Location.pathname
																											? 'active'
																											: ''
																									}`}>
																									{items?.label}
																								</Link>
																							</li>
																						)
																					)}
																				</ul>
																			</li>
																		)
																	)}
																</ul>
															</li>
														</React.Fragment>
													)
												})}
											</ul>
										</li>
									)
								})}
							</ul>
						</div>
					</div>
				</Scrollbars>
			</div>
			<HorizontalSidebar />
			<CollapsedSidebar />
		</div>
	)
}

export default Sidebar
