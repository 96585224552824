import React from 'react'
import { useSelector } from 'react-redux'
import {
	selectItemsCouponAmount,
	selectItemsCouponCode,
	selectItemsCouponType,
	selectItemsDiscount,
	selectItemsDiscountLiquidation,
	selectItemsSubtotal,
	selectItemsTotal,
} from '../redux/selectors/new-sale'
import { Descriptions } from 'antd'
import { formatCurrency } from '../../../utils/functions/money'

export default function SaleResumeAmounts() {
	const total = useSelector(selectItemsTotal)
	const subtotal = useSelector(selectItemsSubtotal)
	const discount = useSelector(selectItemsDiscount)
	const couponAmount = useSelector(selectItemsCouponAmount)
	const couponCode = useSelector(selectItemsCouponCode)
	const couponType = useSelector(selectItemsCouponType)
	const discountLiquidation = useSelector(selectItemsDiscountLiquidation)

	return (
		<Descriptions
			size='small'
			style={{
				width: '40%',
			}}
			bordered={true}
			items={[
				...(discountLiquidation && discountLiquidation > 0
					? [
							{
								key: 'liquidation_save',
								label: 'Ahorro por liquidación',
								children: formatCurrency(discountLiquidation),
								span: 5,
							},
					]
					: []),
				{
					key: 'subtotal',
					label: 'Subtotal',
					children: formatCurrency(subtotal),
					span: 5,
				},
				...(couponCode
					? [
							{
								key: 'coupon_code',
								label: 'Cupón aplicado',
								children: couponCode,
								span: 5,
							},
							{
								key: 'discount_amount',
								label: 'Descuento',
								children:
									couponType === 'per'
										? `${couponAmount}%`
										: `${formatCurrency(couponAmount)}`,
								span: 5,
							},
							{
								key: 'discount_value',
								label: 'Cantidad descontada',
								children: formatCurrency(discount),
								span: 5,
							},
					]
					: []),

				{
					key: 'total',
					label: 'Total',
					children: formatCurrency(total),
					span: 5,
				},
			]}
		/>
	)
}
