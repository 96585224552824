import { Spin } from 'antd'
import React from 'react'

export default function LoadingKRSA() {
	return (
		<div>
			<Spin tip='Cargando Información' size='large'>
				<div
					style={{
						padding: 50,
						background: 'rgba(0, 0, 0, 0.05)',
						borderRadius: 4,
					}}
				/>
			</Spin>
		</div>
	)
}
