import { AlertOctagon } from 'feather-icons-react/build/IconComponents'
import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { toggleOpenModalDeleteCart, clean } from '../redux/cart'
import { selectCartIsShowDeleteCartModal } from '../../../redux/selectors/cart'
import { eraseCoupon } from '../../../redux/coupon'

export default function ModalCleanCart() {
	const isShowDeleteCartModal = useSelector(selectCartIsShowDeleteCartModal)
	const dispatch = useDispatch()

	const deleteCart = () => {
		dispatch(eraseCoupon())
		dispatch(clean())
		dispatch(toggleOpenModalDeleteCart(false))
	}

	return (
		<Modal centered show={isShowDeleteCartModal}>
			<Modal.Header>
				<AlertOctagon />
				<Modal.Title>Advertencia</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>
					¿Seguro que desea <b>ELIMINAR</b> el carrito actual?
				</p>
			</Modal.Body>
			<Modal.Footer>
				<Button
					onClick={() => dispatch(toggleOpenModalDeleteCart(false))}
					variant='pape_cancel'>
					Cancelar
				</Button>
				<Button onClick={() => deleteCart()}>Continuar</Button>
			</Modal.Footer>
		</Modal>
	)
}
