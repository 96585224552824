// Importaciones de React y bibliotecas relacionadas
import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

// Importaciones de store y configuración
import { store, persistor } from './redux/store'
import { base_path } from './environment.jsx'

// Importaciones de componentes
import AppRoutes from './routes/AppRoutes'

// Importaciones de estilos
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.js'
import './style/css/feather.css'
import './style/css/line-awesome.min.css'
import './style/scss/main.scss'
import './style/icons/fontawesome/css/fontawesome.min.css'
import './style/icons/fontawesome/css/all.min.css'
import PinpadProvider from './provider/Pinpad.jsx'

// Obtiene el elemento raíz del DOM
const rootElement = document.getElementById('root')

// Crea una raíz de React 18 para el renderizado concurrente
const root = createRoot(rootElement)

/**
 * Renderiza la aplicación React en el elemento raíz.
 * Utiliza StrictMode para identificar problemas potenciales durante el desarrollo.
 * Envuelve la aplicación con Provider para el estado global de Redux.
 * Utiliza PersistGate para persistir y rehidratar un estado Redux.
 * BrowserRouter proporciona enrutamiento para la aplicación.
 */
root.render(
	<Provider store={store}>
		<PinpadProvider>
			<PersistGate loading={null} persistor={persistor}>
				<BrowserRouter forceRefresh={true} basename={base_path}>
					<AppRoutes />
				</BrowserRouter>
			</PersistGate>
		</PinpadProvider>
	</Provider>
)
