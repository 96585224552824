import { createSelector } from '@reduxjs/toolkit'
import { selectCouponCode } from './coupon'

export const selectCart = (state) => state.cart.items

export const selectCartItemsCount = createSelector([selectCart], (items) =>
	items.reduce((count, item) => (count += item.quantity), 0)
)
export const selectCartSubtotal = createSelector([selectCart], (items) =>
	items.reduce((subtotal, item) => {
		return (
			subtotal +
				(item.AvgPriceLiquidation
					? parseFloat(item.AvgPriceLiquidation)
					: parseFloat(item.AvgPrice)) *
					item.quantity ?? 0
		)
	}, 0)
)
export const selectCartDiscount = createSelector(
	[selectCouponCode, selectCart],
	(discountCode, cart) => {
		if (discountCode) {
			const discount = cart.reduce(
				(accumulator, item) =>
					accumulator + item.couponAmountDiscounted * item.quantity,
				0
			)

			return discount
		}

		return 0
	}
)
export const selectCartTotal = createSelector(
	[selectCartSubtotal, selectCartDiscount],
	(subtotal, discount) => subtotal - discount
)

export const selectCartIsShowDeleteCartModal = (state) =>
	state.cart.isShowDeleteCartModal

export const selectDiscontLiquidation = createSelector([selectCart], (cart) =>
	cart.reduce((liq, item) => {
		if (item.AvgPriceLiquidation) {
			liq += (item.AvgPrice - item.AvgPriceLiquidation) * item.quantity
		}

		return liq
	}, 0)
)
