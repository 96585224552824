/**
 * Entry function
 * @params body - Cuerpo adicional para la solicitud. Adicional al Logo, RazonSocial, Tienda, etc. enviadas por defecto
 * @params url - Url del endpoint a consumir
 * @params type - el tipo de solicitud, POST, GET, Ect.
 *
 * @return response
 */

import { apiUrl, printServer } from '../var'

export const Print = async (body = {}, url, type = 'POST') => {
	// Leer la configuración de la impresora desde localStorage
	const printerSettings = JSON.parse(localStorage.getItem('printerSettings'))

	// Verificar si está configurada la impresora
	if (!printerSettings || !printerSettings.isConfigured) {
		console.error('La impresora no está configurada.')
		return {
			message: 'Error',
			errors: 'La impresora no está configurada',
			statusCode: 400,
		}
	}

	if (!printerSettings.usePrinter) {
		return { message: 'No se está utilizando impresora', statusCode: 200 }
	}

	// Crear el cuerpo de la solicitud usando los valores configurados o valores por defecto
	const requestBody = {
		Printer: {
			host: printerSettings.printer || '192.168.1.28',
			port: '9100',
		},
		DataTemplate: {
			Logo: printerSettings.logo || 'LOGO NO CONFIGURADO',
			RazonSocial: printerSettings.razon || 'RAZON NO CONFIGURADO',
			Tienda: printerSettings.tienda || 'TIENDA NO CONFIGURADO',
			RFC: printerSettings.rfc || 'RFC NO CONFIGURADO',
			Ubicacion: printerSettings.ubicacion || 'UBICACION NO CONFIGURADO',
			Telefono: printerSettings.telefono || 'TELFONO NO CONFIGURADO',
			...body,
		},
	}

	// Hacer la solicitud al endpoint del backend
	const ticketDataResponse = await fetch(url, {
		method: type,
		credentials: 'include',
		headers: {
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': '*',
		},
		body: JSON.stringify(requestBody),
	})

	// Si la respuesta no es exitosa, lanzar error
	if (!ticketDataResponse.ok) {
		console.log(ticketDataResponse)
		return ticketDataResponse
	}

	const ticketData = await ticketDataResponse.json()

	// Si todo está bien, enviar los datos al servidor de impresión
	const printTicketResponse = await fetch(`${printServer}/api/print/test`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': '*',
		},
		body: JSON.stringify(ticketData.data),
	})

	return await printTicketResponse.json()
}

export const clearPrinterSettings = () => {
	// Verificar si existe la configuración en el localStorage
	if (localStorage.getItem('printerSettings')) {
		// Eliminar la configuración de printerSettings del localStorage
		localStorage.removeItem('printerSettings')
		console.log('Configuración de la impresora eliminada.')
	} else {
		console.log('No hay configuración de impresora para eliminar.')
	}
}

export const verifyConnectionToPrinter = async (host, port) => {
	const printerResponse = await fetch(`${printServer}/api/print/verify`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': '*',
		},
		body: JSON.stringify({ Printer: { host, port } }),
	})

	return printerResponse.json()
}

export const handlePrintTicket = async (DocEntry) => {
	try {
		const responsePrint = await Print(
			{ Type_XML: 'TICKET' },
			`${apiUrl}/api/print/ticket/${DocEntry}`,
			'POST'
		)
		if (responsePrint.statusCode !== 200) {
			console.error(responsePrint)
			return false
		}

		return true
	} catch (error) {
		const errorBody = JSON.stringify({
			message: error.message,
			stack: error.stack,
			type: 'Ticket',
		})

		console.error('Error en catch:', error)
		throw new Error(errorBody)
	}
}

export const handlePrintVoucher = async (voucher, type) => {
	try {
		const responsePrint = await Print(
			{
				Voucher: `${
					voucher ??
					'@cnb INBURSA \n@cnn VENTA \n@br \n@cnn TESTING SD INBURSA \n@cnn (0001) THORO \n@cnn MIGUEL DE CERVANTES SAAVEDRA 169 INT. 12 \n@cnn COL. GRANADA, CMX \n \n@cnn 2016309 COBROS INBURSA \n@cnn W69ASIUS0 \n \n \n@lnn No.Tarjeta: xxxxxxxxxxxx8234 \n \n@lnn CREDITO/BBVA/Visa \n \n@cnb -C-O-M-E-R-C-I-O- \n@br \n@lnn APROBADA \n \n \n@lnn IMPORTE\n@cnb $ 12.20 MXN \n@cnb Oper.:     100833901 \n@lnn Ref.:      1732287655142\n@lnn ARQC:      ***********6C04\n@lnn AID:       A0000000031010 \n@cnb Aut.:      367483 \n \n \n \n \n@lnn   \n@lnn Fecha: 22/11/2024 09:00:37 \n \n@br \n@br\n@cnn Genera tu factura ingresando a\n@cnn https://inbursa.detickets.mx/#/ticket/buscar\n@br\n \n@br \n@br \n@cnn  \n@cnn Autorizado sin Firma \n@cnn ZARATE CEBALLOS/DARIO SEBA \n@br \n \n@br \n \n@br \n@cnb logo_cpagos \n@cnn ver_app \n@bc 100833901 \n@br \n@lsn POR ESTE PAGARE ME OBLIGO INCONDI \n@lsn CIONALMENTE A PAGAR A LA ORDEN DEL \n@lsn BANCO EMISOR EL IMPORTE DE ESTE  \n@lsn TITULO EN LOS TERMINOS DEL CONTRA \n@lsn TO SUSCRITO PARA EL USO DE ESTA  \n@lsn TARJETA DE CREDITO EN EL CASO DE  \n@lsn OPERACIONES CON TARJETA DE DEBITO, \n@lsn EXPRESAMENTE RECONOZCO Y ACEPTO  \n@lsn ESTE RECIBO ES EL COMPROBANTE DE  \n@lsn LA OPERACION REALIZADA, MISMA QUE \n@lsn SE CONSIGNA  EN EL ANVERSO Y TEN \n@lsn DRA PLENO VALOR PROBATORIO Y FUER \n@lsn ZA LEGAL, EN VIRTUD DE QUE LO FIR \n@lsn ME PERSONALMENTE Y/O DIGITE MI NU \n@lsn MERO DE IDENTIFICACION PERSONAL  \n@lsn COMO FIRMA ELECTRONICA EL CUAL ES  \n@lsn EXCLUSIVO DE MI RESPONSABILIDAD \n@lsn MANIFESTANDO PLENA CONFORMIDAD  \n@lsn AL RESPECTO. \n \n    \n'
				}`,
			},
			`${apiUrl}/api/print/voucher/`,
			'POST'
		)
		if (responsePrint.statusCode !== 200) {
			console.error(responsePrint)
			return false
		}
		return true
	} catch (error) {
		const errorBody = JSON.stringify({
			message: error.message,
			stack: error.stack,
			type: type,
		})

		console.error('Error en catch:', error)
		throw new Error(errorBody)
	}
}
