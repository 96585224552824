import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	changeScannedProduct,
	changeComponentShow,
} from '../../../redux/catalog';
import { showScannedProductConfig } from '../../../utils/var';
import { selectIsShowScanedProduct } from '../../../redux/selectors/catalog';
import { Button } from 'react-bootstrap';

export default function Lector() {
	const dispatch = useDispatch();
	const isShowCatalog = useSelector(selectIsShowScanedProduct);
	const [scannedProduct, setScannedProduct] = useState('');
	const [isScanning, setIsScanning] = useState(true);
	const inputRef = useRef(null);

	useEffect(() => {
		const handleKeyPress = (e) => {
			if (e.key === 'Enter') {
				getProductByCodeBar();
			}
		};

		window.addEventListener('keydown', handleKeyPress);

		return () => {
			window.removeEventListener('keydown', handleKeyPress);
		};
	}, [scannedProduct]);

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}

		const handleClick = (e) => {
			// Evitar que el input pierda el foco
			if (inputRef.current && !inputRef.current.contains(e.target)) {
				inputRef.current.focus();
			}
		};

		document.addEventListener('click', handleClick);

		return () => {
			document.removeEventListener('click', handleClick);
		};
	}, []);

	const getProductByCodeBar = () => {
		if (scannedProduct && scannedProduct !== '0') {
			console.log(scannedProduct);
			dispatch(changeScannedProduct(scannedProduct));
			dispatch(changeComponentShow(showScannedProductConfig));

			setScannedProduct('');

			if (inputRef.current) {
				inputRef.current.focus();
			}
		}
	};

	const getProductByCodeBarByInput = () => {
		if (scannedProduct && scannedProduct !== '0') {
			console.log(scannedProduct);
			dispatch(changeScannedProduct(scannedProduct));
			dispatch(changeComponentShow(showScannedProductConfig));

			setScannedProduct('');

			if (inputRef.current) {
				inputRef.current.focus();
			}
		}
		setIsScanning(false);
	};

	const handleButtonClick = () => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	};

	return (
		<>
			<div className='d-flex' style={{marginLeft:3+'%'}}>
				<div className='col search-set'>
					<div className='search-input'>
						<input
							id='searchCodeBar'
							type='text'
							placeholder='Agregar por escrito'
							className='form-control form-control-sm formsearch'
							value={scannedProduct}
							onChange={(e) =>
								setScannedProduct(e.target.value.trim().toUpperCase())
							}
						/>
					</div>
				</div>
				<button
					type='button'
					className='btn btn-primary btn-icon'
					onClick={getProductByCodeBarByInput}>
					+
				</button>
			</div>
			<input
				ref={inputRef}
				id='searchCodeBar'
				type='text'
				placeholder='Código de barras'
				className='form-control form-control-sm formsearch hidden-input'
				value={scannedProduct}
				onChange={(e) => setScannedProduct(e.target.value)}
			/>
			{isShowCatalog ? (
				isScanning ? (
					<div></div>
				) : (
					<Button className='btn btn-info' onClick={handleButtonClick}>
						Seguir escaneando
					</Button>
				)
			) : (
				<div></div>
			)}
		</>
	);
}
