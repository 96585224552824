import React, { useContext, useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import {
	selectOrderIsShowModalOrder,
	selectOrderIsLoadingOrder,
	selectPayMethod,
	selectOrderCash,
	selectIsLoadingNextOrderId,
	selectIsLoadingTerminalPay,
	selectOrderStatus,
	//selectErrorNextOrderId,
} from '../../../redux/selectors/order'
import { selectCartTotal } from '../../../redux/selectors/cart'
import {
	//toggleShowModalOrder,
	stopPaying,
	changeStatus,
	cleanOrderBody,
	transactOrder,
	changeCash,
	changeFolio,
	changeExchange,
	changeIsLoadingTerminalPay,
	toggleShowModalOrder,
} from '../../../redux/order'
import { useDispatch, useSelector } from 'react-redux'
import ModalOrderList from '../components/ModalOrderList'
import ModalOrderAmounts from '../components/ModalOrderAmounts'
import '../utils/PayMethod.css'
import { selectCart } from '../../../redux/selectors/cart'
import { selectCouponCode } from '../../../redux/selectors/coupon'
import {
	generateOrderBodyEfective,
	generateOrderBodyCard,
} from '../../../redux/order'
import ModalTicket from '../../../feature-module/sales/Pay/ModalTicket'
import Coupon from '../components/Coupon'
import { eraseCoupon } from '../../../redux/coupon'
import { removeDiscountPricesFromCart } from '../redux/cart'
import { PinpadContext } from '../../../provider/Pinpad'
import { Flex, Form } from 'antd'
import FormControl from '../../../components/FormControl'
import Swal from 'sweetalert2'

export default function OrderModal() {
	const [form] = Form.useForm()
	const [folio, setFolio] = useState('')
	const dispatch = useDispatch()
	const cash = useSelector(selectOrderCash)
	const cartTotal = useSelector(selectCartTotal)
	const isShowModalOrder = useSelector(selectOrderIsShowModalOrder)
	const isLoadingOrder = useSelector(selectOrderIsLoadingOrder)
	const payMethod = useSelector(selectPayMethod)
	const cart = useSelector(selectCart)
	const coupon = useSelector(selectCouponCode)
	const isLoadingNextOrderId = useSelector(selectIsLoadingNextOrderId)
	const isLoadingTerminalPay = useSelector(selectIsLoadingTerminalPay)
	const total = useSelector(selectCartTotal)
	const status = useSelector(selectOrderStatus)
	const {
		cancelReadCard,
		readCard,
		isPinpadConfigured,
		getSerialPortSelected,
		getIsPinPadConfigured,
	} = useContext(PinpadContext)

	const handleCash = (event) => {
		const value = event.target.value
		const regexNumberTwoDecimals = /^\d*\.?\d{0,2}$/

		if (regexNumberTwoDecimals.test(value) || value === '') {
			const exchangeCalculus = value - cartTotal

			dispatch(changeExchange(exchangeCalculus < 0 ? 0 : exchangeCalculus))
			dispatch(changeCash(value))
		}
	}

	const handleFolio = (event) => {
		const value = event.target.value

		const numericValue = value.replace(/[^0-9]/g, '')
		setFolio(numericValue)
		dispatch(changeFolio(numericValue))
	}

	const cancelOrder = async () => {
		form.setFieldValue('cash', null)
		dispatch(stopPaying())
		dispatch(changeStatus('cancelled'))
		dispatch(toggleShowModalOrder(false))
		dispatch(cleanOrderBody())
		dispatch(changeCash(''))
		dispatch(changeFolio(''))
		setFolio('')
		dispatch(eraseCoupon())
		dispatch(removeDiscountPricesFromCart())
		dispatch(changeIsLoadingTerminalPay(false))
		if (
			payMethod === 'Tarjeta' &&
			getIsPinPadConfigured() &&
			status === 'readingcard'
		) {
			cancelReadCard()
		}
	}

	const continueOrder = async (values) => {
		const folio = values.cash

		if (payMethod === 'Tarjeta') {
			if (getIsPinPadConfigured() && getSerialPortSelected()) {
				console.log('Folio automatico')
				dispatch(changeIsLoadingTerminalPay(true))
				await readCard()
			} else {
				dispatch(
					generateOrderBodyCard({
						items: cart,
						coupons: coupon ? [coupon] : [],
						total: cartTotal,
						folio: folio,
						timestamp: (+new Date()).toString(),
					})
				)
				dispatch(transactOrder())
			}
		} else {
			dispatch(
				generateOrderBodyEfective({
					items: cart,
					coupons: coupon ? [coupon] : [],
					total: cartTotal,
					timestamp: (+new Date()).toString(),
				})
			)
			dispatch(transactOrder())
		}
		setFolio('')
	}

	useEffect(() => {
		form.setFieldValue('cash', null)
	}, [isPinpadConfigured])

	useEffect(() => {
		if (!isShowModalOrder) {
			form.setFieldValue('cash', '')
			dispatch(changeCash(''))
		}

		if (isShowModalOrder && payMethod === 'Tarjeta') {
			if (!getSerialPortSelected() || !getIsPinPadConfigured()) {
				Swal.fire({
					icon: 'warning',
					title: 'Error de autenticación de la terminal.',
					text: 'No se ha podido autenticar la terminal, contacte a soporte para corregirlo.',
					confirmButtonText: 'Ok',
				})
			}
		}
	}, [isShowModalOrder])

	return (
		<>
			<Modal
				size='xl'
				centered
				show={isShowModalOrder}
				scrollable
				backdrop='static'>
				<Modal.Header>
					<Modal.Title as='h2'>Resumen de orden</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<ModalOrderList />
					<div className='d-flex justify-content-between gap-3 mt-4'>
						<Coupon />
						<ModalOrderAmounts />
					</div>

					<Form
						onFinish={continueOrder}
						form={form}
						className='d-flex flex-column'
						initialValues={{
							typeCardPay: false,
						}}>
						{payMethod == 'Efectivo' ? (
							<h3>Efectivo entregado</h3>
						) : (
							<h3>Folio de pago</h3>
						)}

						<Flex
							align='center'
							gap='large'
							className='w-100'
							style={{
								width: `100%`,
							}}>
							<FormControl
								type={'input'}
								name={'cash'}
								rules={[
									{
										required:
											payMethod === 'Efectivo' ||
											(payMethod === 'Tarjeta' &&
												(!getIsPinPadConfigured() || !isPinpadConfigured)) /* &&
												!getPortSelected() */
												? true
												: false,
										message:
											payMethod === 'Efectivo' ||
											(payMethod === 'Tarjeta' &&
												(!getIsPinPadConfigured() || !isPinpadConfigured))
												? 'El folio de pago es obligatorio.'
												: 'La cantidad de efectivo entregada es obligatoria.',
									},
									{
										validator: async (_, value) => {
											if (payMethod === 'Efectivo') {
												if (isNaN(value)) {
													throw new Error('Debe ser un número')
												}
												if (value < total) {
													throw new Error(
														'La cantidad de efectivo debe ser igual o mayor al total de la compra.'
													)
												}
											}
											return Promise.resolve()
										},
									},
								]}
								attributes={{
									style: {
										marginTop: '0.5rem',
									},
									value: payMethod == 'Efectivo' ? cash : folio,
									disabled:
										isLoadingNextOrderId ||
										isLoadingTerminalPay ||
										(getIsPinPadConfigured() &&
											getSerialPortSelected() &&
											payMethod === 'Tarjeta'),
									prefix: payMethod === 'Efectivo' ? '$' : 'No.',
									onChange:
										payMethod == 'Efectivo'
											? (value) => handleCash(value)
											: (value) => handleFolio(value),
									placeholder:
										payMethod == 'Efectivo'
											? 'Efectivo entregado'
											: 'Folio de pago con tarjeta',
								}}
							/>
						</Flex>

						<div className='d-flex row-flex justify-content-between'>
							<Button
								className='me-2 mt-4 py-3 px-3'
								onClick={() => cancelOrder()}
								disabled={isLoadingOrder || isLoadingNextOrderId}
								variant='pape_cancel'>
								Cancelar
							</Button>
							<Button
								className='mt-4 py-3 px-4'
								type='submit'
								disabled={isLoadingOrder || isLoadingTerminalPay}>
								Continuar
								{(isLoadingOrder ||
									isLoadingNextOrderId ||
									isLoadingTerminalPay) && (
									<div
										className='spinner-border spinner-border-sm ms-2'
										role='status'>
										<span className='sr-only'>Loading...</span>
									</div>
								)}
							</Button>
						</div>
					</Form>
				</Modal.Body>
			</Modal>
			<ModalTicket />
		</>
	)
}
