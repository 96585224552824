import React, { useState, useEffect } from 'react'
import CashCountInfo from './CashCountInfo'
import { useDispatch } from 'react-redux'
import { toggleModal } from '../redux/cash-count'
import { UserCheck } from 'react-feather'
import { convertMilitaryTimeToStandardTimeFormat } from '../../../utils/functions/date'
import { idDevice } from '../../../utils/var'
import { useSelector } from 'react-redux'
import { apiUrl } from '../../../utils/var'
import {
	selectCashCountIsLoading,
	selectDevice,
	selectIsShowFeedbackCashCount,
} from '../redux/selectors/cash-count'
import { Spin } from 'antd'

export default function Actions() {
	const dispatch = useDispatch()
	const isLoadingRequest = useSelector(selectCashCountIsLoading)
	const isShowFeedbackCashCount = useSelector(selectIsShowFeedbackCashCount)
	const device = useSelector(selectDevice)
	const [nameManager, setNameManager] = useState(null)
	const [nameEmployee, setNameEmployee] = useState(null)
	const [date, setDate] = useState(null)
	const [time, setTime] = useState(null)
	const [id, setId] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState(null)

	const getCashCount = async () => {
		setIsLoading(true)
		const requestGetCashCount = await fetch(`${apiUrl}/api/cash-count/`, {
			method: 'POST',
			body: JSON.stringify({
				U_CSM_IdEquipo: idDevice(),
			}),
			credentials: 'include',
			headers: {
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
			},
		})
		setIsLoading(false)

		const responseGetCashCount = await requestGetCashCount.json()

		if (!requestGetCashCount.ok) {
			if (requestGetCashCount.status === 404) {
				setError({
					status: 404,
					message: responseGetCashCount.message,
				})
			} else {
				setError({
					status: responseGetCashCount.status,
					message: responseGetCashCount.message,
				})
			}
		} else {
			const dataResponseGetCashCount = responseGetCashCount.data

			setNameManager(dataResponseGetCashCount.nameManager)
			setNameEmployee(dataResponseGetCashCount.nameEmployee)
			setDate(dataResponseGetCashCount.createDate)
			setTime(dataResponseGetCashCount.createTime)
			setId(dataResponseGetCashCount.id)
			setError(null)
		}
	}

	useEffect(() => {
		if (!isLoadingRequest || !isShowFeedbackCashCount) {
			getCashCount()
		}
	}, [isLoadingRequest, isShowFeedbackCashCount, device])

	return (
		<div className='d-flex flex-row align-items-center'>
			<div className='d-flex flex-row align-items-start justify-content-between'>
				{isLoading ? (
					<Spin tip='Loading' size='large'>
						<div
							style={{
								padding: 50,
								background: 'rgba(0, 0, 0, 0.05)',
								borderRadius: 4,
							}}
						/>
					</Spin>
				) : error ? (
					error.status === 404 ? (
						<div className='d-flex flex-column'>
							<p>{error.message}</p>
							<button
								className='btn btn-primary add-em-payroll'
								type='button'
								onClick={() => dispatch(toggleModal(true))}>
								<UserCheck />
								<br></br>
								Abrir caja
							</button>
						</div>
					) : (
						<p>{error.message}</p>
					)
				) : (
					<>
						<br></br>
						<div
							className='d-flex flex-row justify-content-between gap-5'
							style={{
								width: 'fit-content',
							}}>
							<CashCountInfo
								nameManager={nameManager}
								nameEmployee={nameEmployee}
								date={date}
								time={convertMilitaryTimeToStandardTimeFormat(time)}
								id={id}
							/>
						</div>
					</>
				)}
			</div>
		</div>
	)
}
