import React, { useContext, useMemo, useEffect } from 'react'
import { CouponsContext } from '../provider/Coupons'
import { tableCouponColumns } from '../utils/datatablesVar'
import { Input, Table } from 'antd'

const CouponsTable = () => {
	const {
		searchCoupon,
		coupons,
		updateSearchCoupon,
		getCoupons,
		error,
		isLoading,
		updateActiveCoupon,
		updateIsShowDetailModal,
		updateIsShowModalForm,
	} = useContext(CouponsContext)

	const showCoupon = (idCoupon) => {
		updateActiveCoupon(idCoupon)
		updateIsShowDetailModal(true)
	}

	const modifyCoupon = (idCoupon) => {
		updateActiveCoupon(idCoupon)
		updateIsShowModalForm(true)
	}

	const filteredData = useMemo(() => {
		if (!searchCoupon) {
			return coupons
		}

		return coupons.filter((coupon) => {
			return Object.values(coupon).some((value) => {
				if (value === null || value === undefined) {
					return false
				}

				return value
					.toString()
					.toLowerCase()
					.includes(searchCoupon.toLowerCase())
			})
		})
	}, [searchCoupon, coupons])

	useEffect(() => {
		getCoupons()
	}, [])

	return (
		<div className='card table-list-card'>
			<div className='card-body'>
				{error ? <span className='text-danger'>{error.message}</span> : <></>}
				<div className='table-responsive'>
					<Input
						placeholder='Buscar'
						name='search'
						id='search'
						onChange={(event) => updateSearchCoupon(event)}
						value={searchCoupon}
						className='mb-4 w-25'
					/>
					<Table
						rowKey='code'
						columns={tableCouponColumns(showCoupon, modifyCoupon)}
						loading={isLoading}
						dataSource={filteredData}
					/>
				</div>
			</div>
		</div>
	)
}

export default CouponsTable
