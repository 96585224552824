import React from 'react';
const { RangePicker } = DatePicker;
import { Link } from "react-router-dom";
import { Filter } from "react-feather";
import { Space,  DatePicker } from 'antd'
import dayjs from 'dayjs'
import { PropTypes } from 'prop-types'

const FilterTable = ({ updateSearchOrder, searchOrder }) => {
    const fromDate = dayjs().subtract(15, 'day').format('YYYY-MM-DD')
    const todayDate = dayjs().format('YYYY-MM-DD');

    return (
        <div className="table-top">
            <div className="search-set">
                <div className="search-input">
                    <input
                        type="text"
                        placeholder="Search"
                        className="form-control form-control-sm formsearch"
                        aria-controls="DataTables_Table_0"
                        onChange={(event) => updateSearchOrder(event)}
                        value={searchOrder}
                    />
                    <Link to className="btn btn-searchset">
                        <i data-feather="search" className="feather-search" />
                    </Link>
                </div>
            </div>
            <div className="search-path" >
                <Link
                    className={`btn btn-filter `}
                    id="filter_search"
                >
                    <Filter
                        className="filter-icon"
                    />

                </Link>
            </div>
            <div className="form-sort stylewidth" style={{ width: 280 }}>
                <Space direction="vertical" size={12}>
                    <RangePicker
                        defaultValue={[dayjs(fromDate, 'YYYY-MM-DD'), dayjs(todayDate, 'YYYY-MM-DD')]}
                        allowClear={false}
                    />
                </Space>
            </div>
        </div>
    );
};

FilterTable.propTypes = {
    updateSearchOrder: PropTypes.func.isRequired,  // 'visible' debe ser un booleano y es requerido
    searchOrder: PropTypes.func.isRequired,
};

export default FilterTable;