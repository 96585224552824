import React from 'react'
import Header from '../InitialPage/Sidebar/Header'
import { Outlet } from 'react-router-dom'

export default function HeaderLayout() {
	return (
		<div className='main-wrapper'>
			<Header />
			<Outlet />
		</div>
	)
}
