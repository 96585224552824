import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changePaymentMethod } from '../../../redux/order'
import { selectPayMethod } from '../../../redux/selectors/order'
import ImageWithBasePath from '../../../core/img/imagewithbasebath'
import PropTypes from 'prop-types'
import '../utils/PayMethod.css'

PayMethod.propTypes = {
	paymethod: PropTypes.string,
	icon: PropTypes.string,
}

export default function PayMethod({ paymethod, icon }) {
	const dispatch = useDispatch()
	const method = useSelector(selectPayMethod)

	return (
		<div
			className='col-md-6 col-lg-4 item'
			onClick={() => dispatch(changePaymentMethod(paymethod))}>
			<button
				className={`px-3 py-2 d-flex flex-column justify-content-center align-items-center gap-2 w-100 pay-method-square ${
					method === paymethod && 'pay-method-selected'
				}`}>
				<ImageWithBasePath src={icon} alt={paymethod} />
				<span>{paymethod}</span>
			</button>
		</div>
	)
}
