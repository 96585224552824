import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import SelectDevice from '../features/history-reports/cash-counts/components/SelectDevice'

export default function Settings() {
	const [device, setDevice] = useState(null)
	const [devices, setDevices] = useState([])

	useEffect(() => {
		if (devices && devices.length > 0) {
			localStorage.setItem(
				'device',
				JSON.stringify(devices.find((devi) => devi.value === device))
			)
		}
	}, [device])

	return (
		<div
			className='w-100'
			style={{
				marginLeft: '260px',
				paddingTop: '80px',
				paddingLeft: '24px',
				marginRight: '24px',
			}}>
			<h2>Configuración del punto de venta</h2>
			<Form className='pt-4'>
				<SelectDevice changeDevice={setDevice} changeDevices={setDevices} />
			</Form>
		</div>
	)
}
