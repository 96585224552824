import React from 'react'
import { ChevronLeft, ChevronRight } from 'react-feather'
import { nextPage, previousPage } from '../../../redux/catalog'
import { useDispatch, useSelector } from 'react-redux'
import { selectPagination } from '../../../redux/selectors/catalog'

export default function Pagination() {
	const dispatch = useDispatch()
	const pagination = useSelector(selectPagination)

	return (
		<div className='d-flex w-100 mt-2 gap-3 justify-content-center align-items-center'>
			<button
				className='btn btn-primary btn-icon'
				onClick={() => dispatch(previousPage())}>
				<ChevronLeft />
			</button>
			{pagination.page}
			<button
				className='btn btn-primary btn-icon'
				onClick={() => dispatch(nextPage())}>
				<ChevronRight />
			</button>
		</div>
	)
}
