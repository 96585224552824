import React, { useEffect, useState } from 'react'
import FeatherIcon from 'feather-icons-react'
import { Link } from 'react-router-dom'

export default function ClientHeader() {
	const [isFullscreen, setIsFullscreen] = useState(false)

	const toggleFullscreen = (elem) => {
		elem = elem || document.documentElement
		if (
			!document.fullscreenElement &&
			!document.mozFullScreenElement &&
			!document.webkitFullscreenElement &&
			!document.msFullscreenElement
		) {
			if (elem.requestFullscreen) {
				elem.requestFullscreen()
			} else if (elem.msRequestFullscreen) {
				elem.msRequestFullscreen()
			} else if (elem.mozRequestFullScreen) {
				elem.mozRequestFullScreen()
			} else if (elem.webkitRequestFullscreen) {
				elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)
			}
		} else {
			if (document.exitFullscreen) {
				document.exitFullscreen()
			} else if (document.msExitFullscreen) {
				document.msExitFullscreen()
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen()
			} else if (document.webkitExitFullscreen) {
				document.webkitExitFullscreen()
			}
		}
	}

	useEffect(() => {
		const handleFullscreenChange = () => {
			setIsFullscreen(
				document.fullscreenElement ||
					document.mozFullScreenElement ||
					document.webkitFullscreenElement ||
					document.msFullscreenElement
			)
		}

		document.addEventListener('fullscreenchange', handleFullscreenChange)
		document.addEventListener('mozfullscreenchange', handleFullscreenChange)
		document.addEventListener('webkitfullscreenchange', handleFullscreenChange)
		document.addEventListener('msfullscreenchange', handleFullscreenChange)

		return () => {
			document.removeEventListener('fullscreenchange', handleFullscreenChange)
			document.removeEventListener(
				'mozfullscreenchange',
				handleFullscreenChange
			)
			document.removeEventListener(
				'webkitfullscreenchange',
				handleFullscreenChange
			)
			document.removeEventListener('msfullscreenchange', handleFullscreenChange)
		}
	}, [])

	return (
		<header
			className='d-flex align-items-center justify-content-between py-2 px-4 w-100 bg-white border z-3'
			style={{ height: '8vh', position: 'fixed', top: 0, left: 0 }}>
			<div
				style={{
					height: '100%',
				}}>
				<img
					src='assets/img/login-images/lapape-logo.png'
					alt='Lapape Logo'
					style={{
						height: '100%',
						width: 'auto',
						objectFit: 'contain',
					}}
				/>
			</div>
			<div className='d-flex gap-3 align-items-center'>
				<ul className='user-menu d-flex gap-3 align-items-center'>
					<li className='nav-item nav-item-box'>
						<Link
							onClick={() => toggleFullscreen()}
							className={isFullscreen ? 'Exit Fullscreen' : 'Go Fullscreen'}>
							<FeatherIcon icon='maximize' />
						</Link>
					</li>
				</ul>
			</div>
		</header>
	)
}
