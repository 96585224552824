export const compareDates = (date1, date2) => {
	const dateFormatted1 = new Date(date1)
	let dateFormatted2
	if (date2) {
		dateFormatted2 = new Date(date2)
	} else {
		dateFormatted2 = new Date()
	}
	return dateFormatted1 > dateFormatted2
}

export const convertDateToMilitaryTimeFormat = (date) => {
	const hours = date.getHours()
	const minutes = date.getMinutes()

	if (hours === 0 && minutes === 0) {
		return 0
	}

	return parseInt(hours.toString() + minutes.toString().padStart(2, '0'))
}

export const convertMilitaryTimeToStandardTimeFormat = (militaryTime) => {
	if (militaryTime === 0) {
		return '00:00'
	}

	const hours = Math.floor(militaryTime / 100)
	const minutes = militaryTime % 100

	const time = new Date()
	time.setHours(hours, minutes, 0, 0)

	return `${time.getHours()}:${
		time.getMinutes() < 10 ? `0${time.getMinutes()}` : time.getMinutes()
	}`
}

export const getDate = (date) => {
	return date.toISOString().slice(0, 10)
}

export const formatDate = (date) => {
	const yyyy = date.getFullYear()
	const mm = String(date.getMonth() + 1).padStart(2, '0') // Meses en base 0
	const dd = String(date.getDate()).padStart(2, '0')
	return `${yyyy}-${mm}-${dd}`
}

export const formatDateToDDMMYYYY = (date) => {
	const yyyy = date.getFullYear()
	const mm = String(date.getMonth() + 1).padStart(2, '0') // Meses en base 0
	const dd = String(date.getDate()).padStart(2, '0')
	return `${dd}/${mm}/${yyyy}`
}

export const formatHHmmToDateObjectJs = (time) => {
	const date = new Date()

	const [hours, minutes] = time.split(':')

	date.setHours(parseInt(hours))
	date.setMinutes(parseInt(minutes))
	date.setSeconds(0)

	return date
}


export const formatDateTime = (date, time) => {
	// Combinar fecha y hora en un solo objeto de fecha
	const datetime = new Date(`${date}T${time}`);

	console.log(date,time)

	// Formateadores para fecha y hora
	const optionsDate = { day: 'numeric', month: 'long', year: 'numeric' };
	const optionsTime = { hour: 'numeric', minute: 'numeric', hour12: true };

	// Convertir a cadena con formato deseado
	const formattedDate = new Intl.DateTimeFormat('es-ES', optionsDate).format(datetime);
	const formattedTime = new Intl.DateTimeFormat('es-ES', optionsTime).format(datetime);

	return `${formattedDate}, ${formattedTime}`;
};