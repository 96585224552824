import React from 'react'
import PayMethodSection from './PayMethodSection'
import Cart from './Cart'
import Amount from '../../../feature-module/sales/Cart/Amount'

export default function CartSection() {
	return (
		<div>
			<Cart />
			<Amount />
			<PayMethodSection />
		</div>
	)
}
