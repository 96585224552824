import React from 'react'
import '../utils/index.css'

export default function SkeletonCatalog() {
	return (
		<div className='d-flex gap-4 flex-column'>
			<div className='d-flex w-100 gap-4'>
				<div className='overflow-hidden skeleton-catalog-white align-items-center d-flex flex-column'>
					<button
						className='spinner-border text-primary me-1 '
						role='status'
						style={{ marginTop: 60 + '%' }}></button>
					<span style={{ color: '#e71f85' }}>Cargando...</span>
				</div>
				<div className='overflow-hidden skeleton-catalog-white align-items-center d-flex flex-column'>
					<button
						className='spinner-border text-primary me-1 '
						role='status'
						style={{ marginTop: 60 + '%' }}></button>
					<span style={{ color: '#e71f85' }}>Cargando...</span>
				</div>
				<div className='overflow-hidden skeleton-catalog-white align-items-center d-flex flex-column'>
					<button
						className='spinner-border text-primary me-1 '
						role='status'
						style={{ marginTop: 60 + '%' }}></button>
					<span style={{ color: '#e71f85' }}>Cargando...</span>
				</div>
			</div>
			<div className='d-flex w-100 gap-4'>
				<div className='overflow-hidden skeleton-catalog-white align-items-center d-flex flex-column'>
					<button
						className='spinner-border text-primary me-1 '
						role='status'
						style={{ marginTop: 60 + '%' }}></button>
					<span style={{ color: '#e71f85' }}>Cargando...</span>
				</div>
				<div className='overflow-hidden skeleton-catalog-white align-items-center d-flex flex-column'>
					<button
						className='spinner-border text-primary me-1 '
						role='status'
						style={{ marginTop: 60 + '%' }}></button>
					<span style={{ color: '#e71f85' }}>Cargando...</span>
				</div>
				<div className='overflow-hidden skeleton-catalog-white align-items-center d-flex flex-column'>
					<button
						className='spinner-border text-primary me-1 '
						role='status'
						style={{ marginTop: 60 + '%' }}></button>
					<span style={{ color: '#e71f85' }}>Cargando...</span>
				</div>
			</div>
		</div>
	)
}
