import React from 'react'
import ImageWithBasePath from '../core/img/imagewithbasebath'
//import ModalOrderList from '../features/pos/components/ModalOrderList'
import ClientHeader from '../InitialPage/Sidebar/ClientHeader'
import ModalOrderClient from '../feature-module/sales/Pay/ModalOrderClient'
import { selectCart } from '../redux/selectors/cart'
import { useSelector } from 'react-redux'
import OrderListClient from '../features/pos/layouts/OrderListClient'
import OrderListClientAmounts from '../features/pos/components/OrderListClientAmounts'

export default function Client() {
	const cart = useSelector(selectCart)

	return (
		<div
			style={{
				height: '100vh',
				overflow: 'hidden',
			}}>
			<ModalOrderClient />
			<ClientHeader />
			<main
				className='d-flex gap-3 position-relative z-2'
				style={{
					marginTop: '9vh',
				}}>
				<aside className='w-25'>
					<ImageWithBasePath
						src='assets/img/media/publiThoro.gif'
						alt='publicidad'
					/>
					<ImageWithBasePath
						src='assets/img/media/publiPromo.gif'
						alt='publicidad'
					/>
				</aside>
				<aside className='w-50'>
					{cart.length > 0 ? (
						<>
							<h3 className='mb-2'>Productos</h3>
							{/* <ModalOrderList /> */}
							<OrderListClient />
						</>
					) : (
						<span className='h4'>No hay productos en el carrito</span>
					)}
				</aside>
				<aside className='w-25'>
					<OrderListClientAmounts />
					<ImageWithBasePath
						src='assets/img/media/publiThoro.gif'
						alt='publicidad'
						className='mt-3'
					/>
				</aside>
			</main>
		</div>
	)
}
