import React from 'react'
import Sidebar from '../InitialPage/Sidebar/SidebarPape'
import HeaderAdmin from '../InitialPage/Sidebar/HearderAdmin'
import { Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'

export default function HeaderSidebarLayout() {
	const data = useSelector((state) => state.toggle_header)

	return (
		<div className={`main-wrapper ${data ? 'header-collapse' : ''}`}>
			<HeaderAdmin />
			<Sidebar />
			<Outlet />
		</div>
	)
}
