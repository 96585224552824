import React from 'react'
import PropTypes from 'prop-types'

const LoginTab = ({
	isPasswordVisible,
	togglePasswordVisibility,
	handleLogin,
	setUsuario,
	setContra,
}) => {
	return (
		<div className='login-wrapper login-new' style={{ height: 'auto' }}>
			<div className='container'>
				<div className='login-content user-login'>
					<form>
						<div className='login-userset'>
							<div className='login-userheading'>
								<h3>Iniciar Sesión</h3>
								<h4>
									Validación y obtención de parámetros del usuario con CENTRO DE
									PAGOS
								</h4>
							</div>
							<div className='form-login'>
								<label className='form-label'>Usuario</label>
								<div className='form-addons'>
									<input
										type='text'
										className='form-control'
										placeholder='****'
										onChange={(e) => setUsuario(e.target.value)}
									/>
								</div>
							</div>
							<div className='form-login'>
								<label>Password</label>
								<div className='pass-group'>
									<input
										type={isPasswordVisible ? 'text' : 'password'}
										className='pass-input form-control'
										placeholder='****'
										onChange={(e) => setContra(e.target.value)}
									/>
									<span
										className={`fas toggle-password ${
											isPasswordVisible ? 'fa-eye' : 'fa-eye-slash'
										}`}
										onClick={togglePasswordVisibility}></span>
								</div>
							</div>
							<div className='form-login'>
								<button
									onClick={handleLogin}
									type='button'
									className='btn btn-login'>
									Sign In
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	)
}

LoginTab.propTypes = {
	isPasswordVisible: PropTypes.bool,
	togglePasswordVisibility: PropTypes.func,
	handleLogin: PropTypes.any,
	setUsuario: PropTypes.any,
	setContra: PropTypes.any,
}

export default LoginTab
