import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Printer } from 'feather-icons-react/build/IconComponents';

const ButtonHeader = ({handlePrintTicket}) => {
    return (
        <div className='page-header text-end justify-content-end'>
            <Link onClick={handlePrintTicket} className='btn-added btn-primary'>
                <Printer className='me-2' />
                Ejecutar impresion de prueba
            </Link>
        </div>
    );
};

ButtonHeader.propTypes = {
    handlePrintTicket: PropTypes.func.isRequired, 
};

export default ButtonHeader;