export const selectCashCountIsLoading = (state) => state['cash-count'].isLoading
export const selectCashCountError = (state) => state['cash-count'].error
export const selectCashCountIsOpen = (state) => state['cash-count'].isOpen
export const selectCashCountIdEmployee = (state) =>
	state['cash-count'].idEmployee
export const selectCashCountIdManager = (state) => state['cash-count'].idManager
export const selectCashCountIdDevice = (state) => state['cash-count'].idDevice
export const selectCashCountIsOpenModal = (state) =>
	state['cash-count'].isOpenModal

export const selectCashCountCash = (state) => state['cash-count'].cash
export const selectIsShowConfirm = (state) => state['cash-count'].isShowConfirm
export const selectIsShowFeedbackCashCount = (state) =>
	state['cash-count'].isShowFeedbackCashCount
export const selectIsLoadingExecuteCashCount = (state) =>
	state['cash-count'].isLoadingExecuteCashCount
export const selectErrorCashCount = (state) =>
	state['cash-count'].errorCashCount
export const selectFeedbackCashCount = (state) =>
	state['cash-count'].feedbackCashCount
export const selectDataCashCount = (state) => state['cash-count'].dataCashCount
export const selectDevice = (state) => state['cash-count'].device
