const deviceLocalStorage = () => localStorage.getItem('device')
// eslint-disable-next-line no-undef
export const environment = process.env.REACT_APP_NODE_ENV || 'development'
// eslint-disable-next-line no-undef
export const reactAppEnv = process.env.REACT_APP_NODE_ENV
// eslint-disable-next-line no-undef
export const pinpadEnviroment = process.env.REACT_APP_PINPAD_ENVIROMENT
// eslint-disable-next-line no-undef
export const pinpadBrand = process.env.REACT_APP_PINPAD_BRAND
// eslint-disable-next-line no-undef
export const pinpadModel = process.env.REACT_APP_PINPAD_MODEL
// eslint-disable-next-line no-undef
export const pinpadCurrency = process.env.REACT_APP_PINPAD_CURRENCY
// eslint-disable-next-line no-undef
export const pinpadCurrencyCode = process.env.REACT_APP_PINPAD_CURRENCY_CODE
// eslint-disable-next-line no-undef
export const pinpadOperationType = process.env.REACT_APP_PINPAD_OPERATION_TYPE
// eslint-disable-next-line no-undef
export const printServer = process.env.REACT_APP_PRINT_SERVER
export const apiUrl =
	environment === 'production'
		? // eslint-disable-next-line no-undef, no-mixed-spaces-and-tabs
		  process.env.REACT_APP_API_EXT
		: // eslint-disable-next-line no-undef, no-mixed-spaces-and-tabs
		  process.env.REACT_APP_API_LOC
// eslint-disable-next-line no-undef
export const manager_role = process.env.REACT_APP_MANAGER_ROL
// eslint-disable-next-line no-undef
export const cashier_role = process.env.REACT_APP_CASHIER_ROL
// eslint-disable-next-line no-undef
export const admin_role = process.env.REACT_APP_ADMIN_ROL
export const showCatalogConfig = {
	isShowCatalog: true,
	isShowWarning: false,
	isShowScanedProduct: false,
	isShowMenu: false,
}
export const showScannedProductConfig = {
	isShowCatalog: false,
	isShowWarning: false,
	isShowScanedProduct: true,
	isShowMenu: false,
}
export const showShowWarningConfig = {
	isShowCatalog: false,
	isShowWarning: true,
	isShowScanedProduct: false,
	isShowMenu: false,
}
export const showMenuConfig = {
	isShowCatalog: false,
	isShowWarning: false,
	isShowScanedProduct: false,
	isShowMenu: true,
}
export const couponConfig = {
	code: '',
	type: 'per',
	amount: 0,
	isLoadingValidate: false,
	errorValidate: null,
}
export const initialSarchCriteria = {
	filter: {
		category: '',
		subcategory: '',
	},
	sorting: {
		criteria: 'itemName',
		order: 'ASC',
	},
	pagination: {
		page: 1,
		itemsPerPage: 50,
		totalItems: 0,
		totalPages: 0,
		offset: 0,
	},
	search: '',
}
export const sortOptions = [
	{
		text: 'Nombre de la A-Z',
		query: {
			criteria: 'itemName',
			order: 'ASC',
		},
	},
	{
		text: 'Nombre de la Z-A',
		query: {
			criteria: 'itemName',
			order: 'DESC',
		},
	},
	{
		text: 'Menor precio',
		query: {
			criteria: 'AvgPrice',
			order: 'ASC',
		},
	},
	{
		text: 'Mayor precio',
		query: {
			criteria: 'AvgPrice',
			order: 'DESC',
		},
	},
]
export const OPENING_BALANCE = 500
export const MAX_MONEY_CASH_DRAWER = 5500
export const idDevice = () => {
	return deviceLocalStorage() &&
		deviceLocalStorage() !== 'undefined' &&
		deviceLocalStorage() !== 'null'
		? JSON.parse(deviceLocalStorage()).value
		: null
}

export const ROUTES = {
	HOME: '/',
	LOGIN: '/login',
	MACHINE_CONFIGURE_WARNING: '/configure-machine-warning',
	CASHIER_PURCHASE: '/cajero/compra',
	CLIENT_PURCHASE: '/cliente/compra',
	ADMIN_DASHBOARD: '/admin/dashboard',
	ADMIN_MANAGE_PRICE: '/admin/manage-price',
	ADMIN_CASH_COUNT: '/admin/corte-caja',
	ADMIN_COUPONS: '/admin/cupones',
	ADMIN_CASH_WITHDRAWAL: '/admin/retiros',
	ADMIN_SETTINGS: '/admin/configuracion',
	ADMIN_CASH_COUNT_HISTORY: '/admin/historiales/cortes',
	ADMIN_INVENTORY: '/admin/inventario',
	ADMIN_ORDERS: '/admin/orders',
	ADMIN_WITHDRAWALS: '/admin/withdrawals',
	ADMIN_MANAGE_PINPAD: '/admin/pinpad',
	ADMIN_GENERAL_MOVEMENTS: '/admin/general-movements',
	ADMIN_PINPAD_MOVEMENTS: '/admin/pinpad-movements',
	ADMIN_PRINTER_SETTINGS: '/admin/printer-settings',
	ADMIN_THERMAL_PRINTER: '/admin/thermal-printer',
	SCANNER: '/scanner',
}

export const formCreateCoupon = (updateValue = null, value = null, section) => {
	if (section === 'left') {
		return [
			{
				name: 'code',
				type: 'input',
				label: 'Código',
				attributes: { placeholder: 'Ej. AHORRO10%' },
				rules: [
					{
						required: true,
						message: 'El código del cupón es requerido.',
					},
					{
						min: 2,
						max: 30,
						message:
							'El código del cupón debe de ser de minímo 2 caracteres y máximo 30.',
					},
				],
			},
			{
				type: 'select',
				name: 'discount_type',
				label: 'Tipo de descuento',
				rules: [
					{
						required: true,
						message: 'Se debe de seleccionar un tipo de descuento',
					},
					{
						enum: ['P', 'M'],
						message: 'Solo se aceptan valores M o P.',
					},
				],
				attributes: {
					disabled: true,
					dropdownStyle: { zIndex: 2000 },
					placeholder: 'Seleccione un tipo de descuento',
					options: [
						{ value: 'P', label: 'Porcentaje' },
						{ value: 'M', label: 'Monetario' },
					],
				},
			},
			{
				type: 'input',
				name: 'discount_value',
				label: 'Cantidad',
				rules: [
					({ getFieldValue }) => ({
						validator(_, value) {
							if (getFieldValue('discount_type') === 'P' && value > 100) {
								return Promise.reject(
									new Error(
										'Si el tipo de descuento es porcentaje, la cantidad no puede exceder el 100%'
									)
								)
							} else {
								return Promise.resolve()
							}
						},
					}),
					{
						required: true,
						message: 'La cantidad a descontar es requerida.',
					},
					{
						pattern: new RegExp(
							/^(?:0*(?:0\.0[1-9]|0\.[1-9]\d?|[1-9]\d{0,4}|100000))(?:\.\d{1,2})?$/
						),
						message:
							'La cantidad a descontar debe de ser númerica del 0.01 al 100000 a dos decimales si es monetaria.',
					},
				],
				attributes: {
					prefix: value === 'M' ? '$' : undefined,
					suffix: value !== 'M' ? '%' : undefined,
				},
			},
			{
				type: 'input',
				name: 'max_usage',
				label: 'Usos',
				attributes: { style: { width: '100%' }, suffix: 'veces' },
				rules: [
					{
						required: true,
						message: 'Los usos son requeridos.',
					},
					{
						pattern: new RegExp(/^(?:[1-9]\d{0,3}|10000)(?:\.\d{1,2})?$/),
						message:
							'La usos debe de ser númerica sin decimales del 1 al 10000.',
					},
				],
			},
			{
				type: 'daterangepicker',
				name: 'start-end-date',
				label: 'Fecha Inicio / Finalización',
				rules: [
					{
						required: true,
						message: 'La fecha de inicio y de finalización es requerida.',
					},
				],
				attributes: {
					format: 'YYYY-MM-DD',
				},
			},
		]
	} else if (section === 'right') {
		return [
			{
				type: 'timerangepicker',
				name: 'start-end-time',
				label: 'Hora Inicio / Finalización',
				attributes: {
					format: 'HH:mm',
				},
				rules: [
					{
						required: true,
						message: 'La Hora Inicio / Finalización es requerida.',
					},
				],
			},
			{
				type: 'input',
				name: 'min_order_value',
				label: 'Orden mínima',
				rules: [
					{
						pattern: new RegExp(
							/^(?:0*(?:0\.0[1-9]|0\.[1-9]\d?|[1-9]\d{0,4}|100000))(?:\.\d{1,2})?$/
						),
						message:
							'La orden minima debe de ser númerica del 0.01 al 10000 a dos decimales.',
					},
				],
				attributes: {
					prefix: '$',
				},
			},
			{
				type: 'input',
				name: 'max_money_discount',
				label: 'Descuento máximo',
				rules: [
					{
						pattern: new RegExp(
							/^(?:0*(?:0\.0[1-9]|0\.[1-9]\d?|[1-9]\d{0,4}|100000))(?:\.\d{1,2})?$/
						),
						message:
							'El descuento máximo debe de ser númerico del 0.01 al 10000 a dos decimales.',
					},
				],
				attributes: { prefix: '$' },
			},
			{
				type: 'textarea',
				name: 'description',
				label: 'Descripción',
				rules: [
					{
						required: true,
						message: 'La descripción es requerida.',
					},
					{
						min: 1,
						max: 200,
						message:
							'La descripcion debe de ser de minimo 1 caracter y máximo de 200.',
					},
				],
			},
			{
				type: 'switch',
				name: 'status',
				label: 'Estatus',
				key: 'status',
				attributes: {
					checkedChildren: 'Habilitado',
					unCheckedChildren: 'Deshabilitado',
					defaultChecked: true,
				},
			},
		]
	} else {
		return [
			{
				type: 'switch',
				name: 'isSelectedBrands',
				label: 'Marcas',
				attributes: {
					value: value.brands,
					checked: value.brands,
					onChange: (valueSelected) => {
						updateValue('brands', valueSelected)
					},
				},
			},
			{
				type: 'switch',
				name: 'isSelectedLinesAndSublines',
				label: 'Lineas y Sublineas',
				attributes: {
					value: value.linesAndSublines,
					checked: value.linesAndSublines,
					onChange: (valueSelected) =>
						updateValue('linesAndSublines', valueSelected),
				},
			},
			{
				type: 'switch',
				name: 'isSelectedItems',
				label: 'Productos',
				attributes: {
					value: value.items,
					checked: value.items,
					onChange: (valueSelected) => updateValue('items', valueSelected),
				},
			},
		]
	}
}
