import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { selectSearch } from '../../../redux/selectors/catalog'
import { changeSearch } from '../../../redux/catalog'
import { useDispatch, useSelector } from 'react-redux'
import { Search } from 'react-feather'

export default function SearchProduct() {
	const dispatch = useDispatch()
	const search = useSelector(selectSearch)
	const [searchTerm, setSearchTerm] = useState('')

	const handleChange = (e) => {
		setSearchTerm(e.target.value)
	}

	const handleOnKeyDown = (e) => {
		if (e.key === 'Enter') {
			searchProduct()
		}
	}

	const searchProduct = () => {
		dispatch(changeSearch(searchTerm))
	}

	useEffect(() => {
		if (!search) {
			setSearchTerm('')
		}
	}, [search])

	return (
		<div className='d-flex flex-column gap-2'>
			<h5>Buscar</h5>

			<div className='d-flex gap-1'>
				<Form.Control
					className='w-auto'
					placeholder='Buscar...'
					onChange={(e) => handleChange(e)}
					onKeyDown={(e) => handleOnKeyDown(e)}
					onBlur={(event) => {
						if (event.target !== document.activeElement) {
							searchProduct()
						}
					}}
					value={searchTerm}
				/>
				<button
					style={{ borderRadius: '6px' }}
					onClick={() => searchProduct()}
					className='btn btn-primary btn-icon'>
					<Search style={{ width: '50px' }} />
				</button>
			</div>
		</div>
	)
}
