import React from 'react'
import { useDispatch } from 'react-redux'
import { addItem } from '../redux/cart'
import { PropTypes } from 'prop-types'
import { formatCurrency } from '../../../utils/functions/money'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { apiUrl } from '../../../utils/var'

CardItem.propTypes = {
	product: PropTypes.object,
	ItemCode: PropTypes.string,
	ItemName: PropTypes.string,
	AvgPrice: PropTypes.string,
	AvgPriceLiquidation: PropTypes.string,
	ItemQuantity: PropTypes.number,
	PictureName: PropTypes.string,
	item: PropTypes.object,
}

export default function CardItem({ product }) {
	const dispatch = useDispatch()

	return (
		<div className='col-sm-3 col-lg-12 col-xl-6 col-xxl-4'>
			<div className='card p-3 position-relative overflow-hidden'>
				{product.AvgPriceLiquidation ? (
					<div className='ribbone ribbone-top-left text-danger'>
						<span className='bg-danger'>Liquidación</span>
					</div>
				) : (
					<></>
				)}

				<img
					className='object-fit-contain card-image'
					src={
						product.PicturName
							? `${apiUrl}/proxy/empleados/imagenes/ITEMS/${product.PicturName}`
							: 'assets/img/media/Lapy.jpg'
					}
					alt='Products'
				/>
				<h6 className='product-name'>
					<OverlayTrigger
						placement='top'
						overlay={<Tooltip id='tooltip-dark'>{product.ItemName}</Tooltip>}
						offset={[0, 15]}>
						<p
							data-bs-toggle='tooltip'
							data-bs-custom-class='tooltip-primary'
							style={{
								width: 100 + '%',
								overflow: 'hidden',
								display: '-webkit-box',
								WebkitBoxOrient: 'vertical',
								WebkitLineClamp: '2',
							}}>
							{product.ItemName}
						</p>
					</OverlayTrigger>
				</h6>
				<h6 className='1'>
					<p
						style={{
							color: 'white',
							background: '#e71f85',
							display: 'inline-block',
							padding: '5px 10px',
							borderRadius: 10 + 'px',
						}}>
						{product.ItemCode}
					</p>
				</h6>
				<div className='d-flex flex-column align-items-end justify-content-between price'>
					<span style={{ fontSize: 18 + 'px', color: '#17a2b8' }}>
						{parseInt(product.OnHand)} piezas
					</span>
					<div>
						{product.AvgPriceLiquidation ? (
							<div className='d-flex flex-column justify-content-end align-items-end'>
								<span
									className='text-decoration-line-through'
									style={{ fontSize: 16 + 'px', fontWeight: 'bold' }}>
									{formatCurrency(product.AvgPrice)}
								</span>
								<span style={{ fontSize: 20 + 'px', fontWeight: 'bold' }}>
									Precio: {formatCurrency(product.AvgPriceLiquidation)}
								</span>
							</div>
						) : (
							<div className='d-flex flex-column justify-content-end align-items-end'>
								<span
									className='text-white'
									style={{ fontSize: 16 + 'px', fontWeight: 'bold' }}>
									&nbsp;
								</span>
								<span style={{ fontSize: 20 + 'px', fontWeight: 'bold' }}>
									Precio: {formatCurrency(product.AvgPrice)}
								</span>
							</div>
						)}
					</div>
				</div>
				<div style={{ display: 'flex', width: '100%' }}>
					<button
						type='button'
						className='btn btn-primary btn-icon flex-fill'
						onClick={() => dispatch(addItem(product))}>
						<span className='me-1 d-flex align-items-center'>
							<i className='fas fa-plus' />
						</span>
						&nbsp;Agregar
					</button>
				</div>
			</div>
		</div>
	)
}
