import axios from 'axios'
import { persistor } from '../../redux/store'
import Cookies from 'js-cookie'
import { apiUrl } from '../../utils/var'

axios.defaults.withCredentials = true

export const product_list = () => ({ type: 'Product_list' })
export const set_product_list = (payload) => ({
	type: 'Product_list',
	payload,
})

export const dashboard_recentproduct = () => ({
	type: 'Dashbaord_RecentProduct',
})
export const setdashboard_recentproduct = (payload) => ({
	type: 'Dashbaord_RecentProduct',
	payload,
})
export const dashboard_expiredproduct = () => ({
	type: 'Dashbaord_ExpiredProduct',
})
export const setdashboard_expiredproduct = (payload) => ({
	type: 'Dashbaord_ExpiredProduct',
	payload,
})
export const saleshdashboard_recenttransaction = () => ({
	type: 'Salesdashbaord_ExpiredProduct',
})
export const setsaleshdashboard_recenttransaction = (payload) => ({
	type: 'Salesdashbaord_ExpiredProduct',
	payload,
})
export const brand_list = () => ({ type: 'Brand_list' })
export const setbrand_list = (payload) => ({
	type: 'Brand_list',
	payload,
})
export const unit_data = () => ({ type: 'Unit_Data' })
export const setunit_data = (payload) => ({
	type: 'Unit_Data',
	payload,
})
export const variantattributes_data = () => ({ type: 'Variantattribute_Data' })
export const setvariantattributes_data = (payload) => ({
	type: 'Variantattribute_Data',
	payload,
})
export const warranty_data = () => ({ type: 'Warranty_Data' })
export const setwarranty_data = (payload) => ({
	type: 'Warranty_Data',
	payload,
})
export const barcode_data = () => ({ type: 'Barcode_Data' })
export const setbarcode_data = (payload) => ({
	type: 'Barcode_Data',
	payload,
})
export const departmentlist_data = () => ({ type: 'Department_Data' })
export const setdepartmentlist_data = (payload) => ({
	type: 'Department_Data',
	payload,
})
export const designation_data = () => ({ type: 'Designation_Data' })
export const setdesignation_data = (payload) => ({
	type: 'Designation_Data',
	payload,
})
export const shiftlist_data = () => ({ type: 'Shiftlist_Data' })
export const setshiftlist_data = (payload) => ({
	type: 'Shiftlist_Data',
	payload,
})
export const attendenceemployee_data = () => ({
	type: 'Attendenceemployee_Data',
})
export const setattendenceemployee_data = (payload) => ({
	type: 'Attendenceemployee_Data',
	payload,
})
export const toogleHeader_data = () => ({ type: 'toggle_header' })
export const setToogleHeader = (payload) => ({
	type: 'toggle_header',
	payload,
})
export const invoicereport_data = () => ({ type: 'Invoicereport_Data' })
export const setinvoicereport_data = (payload) => ({
	type: 'Invoicereport_Data',
	payload,
})
export const salesreturns_data = () => ({ type: 'Salesreturns_Data' })
export const setsalesreturns_data = (payload) => ({
	type: 'Salesreturns_Data',
	payload,
})
export const quotationlist_data = () => ({ type: 'Quatation_Data' })
export const setquotationlist_data = (payload) => ({
	type: 'Quatation_Data',
	payload,
})
export const customer_data = () => ({ type: 'customer_data' })
export const setcustomer_data = (payload) => ({
	type: 'customer_data',
	payload,
})
export const userlist_data = () => ({ type: 'Userlist_data' })
export const setuserlist_data = (payload) => ({
	type: 'Userlist_data',
	payload,
})
export const rolesandpermission_data = () => ({
	type: 'Rolesandpermission_data',
})
export const setrolesandpermission_data = (payload) => ({
	type: 'Rolesandpermission_data',
	payload,
})
export const deleteaccount_data = () => ({ type: 'Deleteaccount_data' })
export const setdeleteaccount_data = (payload) => ({
	type: 'Deleteaccount_data',
	payload,
})
export const attendanceadmin_data = () => ({ type: 'Attendenceadmin_data' })
export const setattendanceadmin_data = (payload) => ({
	type: 'Attendenceadmin_data',
	payload,
})
export const leavesadmin_data = () => ({ type: 'Leavesadmin_data' })
export const setleavesadmin_data = (payload) => ({
	type: 'Leavesadmin_data',
	payload,
})
export const leavetypes_data = () => ({ type: 'Leavestype_data' })
export const setleavetypes_data = (payload) => ({
	type: 'Leavestype_data',
	payload,
})
export const holiday_data = () => ({ type: 'Holiday_data' })
export const setholiday_data = (payload) => ({
	type: 'Holiday_data',
	payload,
})
export const expiredproduct_data = () => ({ type: 'Expiredproduct_data' })
export const setexpiredproduct_data = (payload) => ({
	type: 'Expiredproduct_data',
	payload,
})
export const lowstock_data = () => ({ type: 'Lowstock_data' })
export const setlowstock_data = (payload) => ({
	type: 'Lowstock_data',
	payload,
})
export const categotylist_data = () => ({ type: 'Categotylist_data' })
export const setcategotylist_data = (payload) => ({
	type: 'Categotylist_data',
	payload,
})
export const setLayoutChange = (payload) => ({
	type: 'Layoutstyle_data',
	payload,
})

/**-
 * NUEVAS FUNCIONES
 */
export const getItems = () => {
	return (dispatch) => {
		axios
			.get(`${apiUrl}/api/items/getItems`)
			.then((response) => {
				console.log(response)
				dispatch(setItems(response.data)) // Despachar acción para actualizar el estado con los datos obtenidos
			})
			.catch((error) => {
				console.log(error)
				// Manejo de errores si es necesario
			})
	}
}

/**
 * ITEMS
 */
const setItems = (items) => ({
	type: 'SET_ITEMS',
	payload: items,
})

/**
 * LOGIN
 */

export const login = (userId, userPass, lastname) => {
	return (dispatch) => {
		dispatch(loginRequest())

		return axios
			.post(
				`${apiUrl}/api/auth/login`,
				{ userId, userPass, lastname },
				{ withCredentials: true }
			)
			.then((response) => {
				const { userData, sessionId } = response.data

				// Leer el token de la cookie
				const token = Cookies.get('jwt_token')
				console.log('JWT Token:', token)

				dispatch(loginSuccess(userData, sessionId))
				return { userData, sessionId } // Devolver los datos relevantes aquí
			})
			.catch((error) => {
				dispatch(loginFailure(error.message))
				throw error // Lanzar el error para que pueda ser manejado externamente
			})
	}
}

export const loginRequest = () => ({
	type: 'LOGIN_REQUEST',
})

export const loginSuccess = (user, token) => ({
	type: 'LOGIN_SUCCESS',
	payload: { user, token },
})

export const loginFailure = (error) => ({
	type: 'LOGIN_FAILURE',
	payload: { error },
})

export const logout = () => {
	return async (dispatch) => {
		await axios
			.post(`${apiUrl}/api/auth/logout`, {}, { withCredentials: true })
			.then((response) => {
				console.log('Logout exitoso:', response.data.message)
				// Redirigir al usuario a la página de inicio o de login
				persistor.purge() // Purgar el almacenamiento persistente
				dispatch({ type: 'LOGOUT' })
			})
			.catch((error) => {
				console.error('Error en el logout:', error.message)
			})
	}
}
