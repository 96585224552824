import React, { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import { Link } from 'react-router-dom'
import { formatCurrency } from '../../../utils/functions/money'
import ImageWithBasePath from '../../../core/img/imagewithbasebath'
import ItemOfert from '../components/itemOfert'
import { useDispatch } from 'react-redux'
import { apiUrl } from '../../../utils/var'
import { fetchItems } from '../../../redux/scanner'

Item.propTypes = {
	ItemCode: PropTypes.string,
	ItemName: PropTypes.string,
	AvgPrice: PropTypes.string,
	AvgPriceLiquidation: PropTypes.string,
	PicturName: PropTypes.string,
	OnHand: PropTypes.string,
}

export default function Item({
	ItemCode,
	ItemName, 
	AvgPrice,
	AvgPriceLiquidation,
	PicturName,
	OnHand,
}) {
	const dispatch = useDispatch()
	const [className, setClassName] = useState('lapy hithere')
	const [itemOfert, setItemOfert] = useState(null)
	const [error, setError] = useState(null)

	const getScannedProductOfert = async () => {
		try {
			const url = `${apiUrl}/api/items-without-auth/getItemsLiquidationWithoutAuth`
			const scannedProductResponse = await fetch(url, {
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
				},
				credentials: 'include',
			});
	
			if (!scannedProductResponse.ok) {
				const errorBody = await scannedProductResponse.json();
				throw new Error(errorBody.message || 'Error desconocido');
			}
	
			const scannedProduct = (await scannedProductResponse.json()).items;
			setItemOfert(scannedProduct);
			console.log(scannedProduct)
		} catch (error) {
			console.error(error)
			let errorMessage

			try {
				const errorInfo = JSON.parse(error.message)
				errorMessage = errorInfo.message
			} catch {
				errorMessage =
					'Ocurrió un error al traer los productos, recargue el punto de venta o contacte a un encargado.'
			}
			setError(errorMessage)
		}
	};

	useEffect(() => {
		const timer = setTimeout(() => {
			setClassName('lapy')
		}, 750)

		return () => clearTimeout(timer) // Limpiar el timeout si el componente se desmonta
	}, [])

	useEffect(() => {
		dispatch(fetchItems())
		getScannedProductOfert()
	}, [dispatch])

	if (error) {
		return (
			<div className='pantalla-error d-flex flex-row align-items-center justify-content-center'>
				<div className='d-flex lapy-escaner'>
					<ImageWithBasePath src='assets/img/lapi.png' />
				</div>
				<h3 className='d-flex align-items-center justify-content-center'>
					Error: {error}
				</h3>
			</div>
		)
	}
	if (!itemOfert) return <div>No hay producto escaneado</div>

	return (
		<>
			<div className={className}>
				<ImageWithBasePath src='assets/img/lapi.png' />
			</div>
			<div className='escaneado-card product-list d-flex align-items-start justify-content-between'>
				<div
					className='d-flex align-items-center product-info'
					style={{ padding: 5 + 'px' }}>
					<img
						className='card-image-scanner'
						src={
							PicturName
								? `http://thoro-ent.dyndns.info/empleados/imagenes/ITEMS/${PicturName}`
								: 'assets/img/media/Lapy.jpg'
						}
						alt='Productos'
					/>
				</div>
				<div
					className='info flex-column align-items-center'
					style={{ marginTop: 10 + '%' }}>
					<span
						style={{
							color: 'white',
							background: '#e71f85',
							display: 'inline-block',
							padding: '5px 10px',
							borderRadius: 10 + 'px',
						}}>
						{ItemCode}
					</span>
					<h2 style={{ paddingTop: 10 + 'px' }}>
						<Link to='#'>{ItemName}</Link>
					</h2>
					<div className='d-flex flex-row'>
						{AvgPriceLiquidation ? (
							<h2 style={{ paddingTop: 10 + 'px', color: '#e71f85' }}>
								Precio:{' '}
								<span
									className='text-decoration-line-through'
									style={{ color: 'gray', fontSize: '20px' }}>
									{formatCurrency(AvgPrice)}
								</span>{' '}
								<span>{formatCurrency(AvgPriceLiquidation)}</span>
							</h2>
						) : (
							<h2 style={{ paddingTop: 10 + 'px', color: '#e71f85' }}>
								Precio: {formatCurrency(AvgPrice)}
							</h2>
						)}

						<h5
							style={{
								paddingLeft: 5 + 'px',
								paddingTop: 20 + 'px',
								color: 'gray',
							}}>
							(incluye IVA)
						</h5>
					</div>
					<h3 style={{ paddingTop: 10 + 'px', color: '#17a2b8' }}>
						Stock disponible: {parseInt(OnHand)} piezas.
					</h3>
				</div>
				<div className='card'>
					{AvgPriceLiquidation ? (
						<div className='d-flex ribbone-escaner ribbone-top-right text-danger align-items-end justify-content-end'>
							<span className='bg-danger' style={{ margin: '18px' }}>
								Liquidación
							</span>
						</div>
					) : (
						<></>
					)}
				</div>
			</div>
				{itemOfert && itemOfert.length > 0 && (
					<ItemOfert
						ItemCode={itemOfert[0].ItemCode}
						ItemName={itemOfert[0].ItemName}
						AvgPrice={itemOfert[0].AvgPrice}
						AvgPriceLiquidation={itemOfert[0].AvgPriceLiquidation}
					/> 
				)}
		</>
	)
}
