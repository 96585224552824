import React, { useEffect } from 'react'
import Category from '../components/Category'
import Subcategory from '../components/Subcategory'
import OrderBy from '../components/OrderBy'
import {
	selectFilters,
	selectSorting,
	selectSearch,
	selectPagination,
} from '../../../redux/selectors/catalog'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCatalog } from '../../../redux/catalog'
import SearchProduct from '../components/SearchProduct'
import Switch from '../components/Switch'

export default function Search() {
	const filters = useSelector(selectFilters)
	const sorting = useSelector(selectSorting)
	const search = useSelector(selectSearch)
	const pagination = useSelector(selectPagination)
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(fetchCatalog())
	}, [filters, sorting, search, pagination])

	return (
		<>
			<div className='d-flex flex-row justify-content-evenly'>
				<div className='d-flex gap-2 flex-wrap'>
					<SearchProduct />
					<OrderBy />
					<Category />
					<Subcategory />
				</div>
				<div className='d-flex flex-column align-items-end justify-content-end'>
					<Switch />
				</div>
			</div>
		</>
	)
}
