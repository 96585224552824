import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectWithdrawIsInWithdraw } from '../../redux/selectors/withdraw'
import { initWithdraw } from '../../redux/withdraw'

export default function Retiro() {
	const dispatch = useDispatch()
	const isInWithdraw = useSelector(selectWithdrawIsInWithdraw)

	const generateWithdraw = () => {
		dispatch(initWithdraw())
	}
	return (
		<>
			{/* Retiro */}
			<div className='page-wrapper'>
				<div className='col-lg-12'>
					<div className='view-btn'>
						<button
							disabled={isInWithdraw}
							type='submit'
							className='btn btn-save'
							onClick={() => generateWithdraw()}>
							{isInWithdraw ? 'Generando' : 'Generar retiro'}
							{isInWithdraw && (
								<div
									className='spinner-border spinner-border-sm ms'
									role='status'>
									<span className='sr-only'>Loading...</span>
								</div>
							)}
						</button>
					</div>
				</div>{' '}
			</div>
			{/* /Retiro */}
		</>
	)
}
