import React from 'react';
import CountUp from 'react-countup';
import PropTypes from 'prop-types';
import ImageWithBasePath from '../../../core/img/imagewithbasebath';

const DashCountWidget = ({ classN,iconSrc, value, duration, prefix = "$", decimals = 0, label }) => (
    <div className="col-xl-3 col-sm-6 col-12 d-flex">
        <div className={`dash-widget ${classN} w-100`}>
            <div className="dash-widgetimg">
                <span>
                    <ImageWithBasePath src={iconSrc} alt="icon" />
                </span>
            </div>
            <div className="dash-widgetcontent">
                <h5>
                    {prefix}
                    <CountUp start={0} end={value} duration={duration} decimals={decimals} />
                </h5>
                <h6>{label}</h6>
            </div>
        </div>
    </div>
);
DashCountWidget.propTypes = {
    classN: PropTypes.string.isRequired,
    iconSrc: PropTypes.string.isRequired,   // `iconSrc` debe ser una cadena obligatoria
    value: PropTypes.number.isRequired,    // `value` debe ser un número obligatorio
    duration: PropTypes.number.isRequired, // `duration` debe ser un número obligatorio
    prefix: PropTypes.string,              // `prefix` es opcional y debe ser una cadena
    decimals: PropTypes.number,            // `decimals` es opcional y debe ser un número
    label: PropTypes.string.isRequired,    // `label` debe ser una cadena obligatoria
};


export default DashCountWidget;
