import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'antd';

const SettingSwitch = ({ label, description, checked, onChange }) => {
    return (
        <div className="row align-items-center">
            <div className="col-sm-4">
                <div className="setting-info">
                    <h6>{label}</h6>
                    <p>{description}</p>
                </div>
            </div>
            <div className="col-sm-4">
                <div className="localization-select d-flex align-items-center">
                    <div className="status-toggle modal-status d-flex justify-content-between align-items-center me-3">
                        <Switch
                            checked={checked}
                            onChange={onChange}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

// Definición de PropTypes para validar las props del componente
SettingSwitch.propTypes = {
    label: PropTypes.string.isRequired,
    description: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
};

export default SettingSwitch;
