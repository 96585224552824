import React from 'react'
import PropTypes from 'prop-types'
import CouponNewSale from '../../pos/components/CouponNewSale'
import { Descriptions } from 'antd'
import { useSelector } from 'react-redux'
import { selectItemsCouponAmount, selectItemsCouponCode, selectItemsCouponType, selectItemsDiscount, selectItemsDiscountLiquidation, selectItemsSubtotal, selectItemsTotal } from '../redux/selectors/new-sale'
import { formatCurrency } from '../../../utils/functions/money'

const SaleDetails = () => {
	const total = useSelector(selectItemsTotal)
	const subtotal = useSelector(selectItemsSubtotal)
	const discount = useSelector(selectItemsDiscount)
	const couponAmount = useSelector(selectItemsCouponAmount)
	const couponCode = useSelector(selectItemsCouponCode)
	const couponType = useSelector(selectItemsCouponType)
	const discountLiquidation = useSelector(selectItemsDiscountLiquidation)

	return (
		<div className='row'>
			<div className='col-lg-4'>
				<CouponNewSale />
			</div>
			<div className='col-lg-6 ms-auto'>
			<Descriptions
				size='small'
				style={{
					width: '100%',
				}}
				bordered={true}
				items={[
					...(discountLiquidation && discountLiquidation > 0
						? [
								{
									key: 'liquidation_save',
									label: 'Ahorro por liquidación',
									children: formatCurrency(discountLiquidation),
									span: 5,
								},
						]
						: []),
					{
						key: 'subtotal',
						label: 'Subtotal',
						children: formatCurrency(subtotal),
						span: 5,
					},
					...(couponCode
						? [
								{
									key: 'coupon_code',
									label: 'Cupón aplicado',
									children: couponCode,
									span: 5,
								},
								{
									key: 'discount_amount',
									label: 'Descuento',
									children:
										couponType === 'per'
											? `${couponAmount}%`
											: `${formatCurrency(couponAmount)}`,
									span: 5,
								},
								{
									key: 'discount_value',
									label: 'Cantidad descontada',
									children: formatCurrency(discount),
									span: 5,
								},
						]
						: []),
					
					{
						key: 'total',
						label: 'Total',
						children: formatCurrency(total),
						span: 5,
					}
				]}
			/>
			</div>
		</div>
	)
}

SaleDetails.propTypes = {
	couponPlaceholder: PropTypes.string,
	couponButtonText: PropTypes.string,
	isLoading: PropTypes.bool,
}

export default SaleDetails
