import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'
import ImageWithBasePath from '../../../core/img/imagewithbasebath'
import {
	selectIsShowCatalog,
	selectIsShowScanedProduct,
	selectIsShowWarning,
} from '../../../redux/selectors/catalog'
import {
	showShowWarningConfig,
	showMenuConfig,
	showCatalogConfig,
} from '../../../utils/var'
import { changeComponentShow } from '../../../redux/catalog'

export default function Switch() {
	const dispatch = useDispatch()
	const isShowWarning = useSelector(selectIsShowWarning)
	const isShowCatalog = useSelector(selectIsShowCatalog)
	const isShowScanedProduct = useSelector(selectIsShowScanedProduct)

	const getScanner = async () => {
		dispatch(
			changeComponentShow(
				isShowWarning ? showMenuConfig : showShowWarningConfig
			)
		)
	}

	const getCatalog = async () => {
		dispatch(
			changeComponentShow(
				isShowCatalog ? showShowWarningConfig : showCatalogConfig
			)
		)
	}

	return (
		<>
			{isShowWarning ? (
				<Button
					id='mostrarWarning'
					className='btn-primary'
					onClick={getCatalog}>
					<ImageWithBasePath
						src='assets/img/icons/dash7.svg'
						alt='Scanner'
						width={40 + 'px'}
					/>
				</Button>
			) : isShowScanedProduct ? (
				<Button
					id='mostrarWarning'
					className='btn-primary'
					onClick={getCatalog}>
					<ImageWithBasePath
						src='assets/img/icons/dash7.svg'
						alt='Scanner'
						width={40 + 'px'}
					/>
				</Button>
			) : (
				<Button id='mostrarWarning' className='btn-info' onClick={getScanner}>
					<ImageWithBasePath
						src='assets/img/icons/scanner1.svg'
						alt='Scanner'
						width={40 + 'px'}
					/>
				</Button>
			)}
		</>
	)
}
