import React, { useState, useEffect
} from 'react';
import { apiUrl } from '../../../utils/var'
import NewSaleModal from './NewSaleModal';
import DashCountWidget from './DashCountWidget';
import DashCount from './DashCount';

export default function CashCountInfoSales(id) {
    const [dataTotal, setDataTotal] = useState([])
    const [dataTotalWithdraw, setDataTotalWithdraw] = useState([])
    const [errorTotalSelled, setErrorTotalSelled] = useState(null)
    const [errorTotalWithdraw, setErrorTotalWithdraw] = useState(null)

    const [modalVisible, setModalVisible] = useState(false);

    const handleShowModal = (record) => {
        console.log(record)
        setModalVisible(true);
    };

    const handleCloseModal = () => {
        setModalVisible(false);
    };

    useEffect(() => {
        if (id.id) {
            getTotalSelled(id.id)
            getTotalWithdrawals(id.id)
        }
    }, [id.id]);

    useEffect(() => {
    console.log('Data total actualizado:', dataTotal);
}, [dataTotal]);

    const getTotalSelled = async (id) => {
		const apiEndpoint = `${apiUrl}/api/Orders/totalSelled/${id}`

		try {
			const requestGetTotalSelled = await fetch(apiEndpoint, {
				method: 'GET',
				credentials: 'include',
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Alllow-Origin': '*',
				},
			});

			const responseGetTotalSelled = await requestGetTotalSelled.json()
			if (!requestGetTotalSelled.ok) {
				console.error(responseGetTotalSelled.message)
			}else {
				setDataTotal(responseGetTotalSelled?.dataTotalSell || [])
				setErrorTotalSelled(null)
			}
		} catch (error) {
			setErrorTotalSelled({
				status: 'Network error',
				message: error.message,
			})
            console.error(errorTotalSelled)
		}
	}

    const getTotalWithdrawals = async (id) => {
        const apiEndpoint = `${apiUrl}/api/withdraw/withdrawalsTotal/${id}`

        try {
            const requestGetTotalWithdraws = await fetch(apiEndpoint, {
				method: 'GET',
				credentials: 'include',
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Alllow-Origin': '*',
				},
			});

			const responseGetTotalWithdraws = await requestGetTotalWithdraws.json()
			if (!requestGetTotalWithdraws.ok) {
				console.error(responseGetTotalWithdraws.message)
			}else {
				setDataTotalWithdraw(responseGetTotalWithdraws.data || [])
				setErrorTotalWithdraw(null)
			}
        } catch (error) {
            setErrorTotalWithdraw({
				status: 'Network error',
				message: error.message,
			})
            console.error(errorTotalWithdraw)
        }
    }

    return (
        <div className="row">
            <DashCountWidget 
                classN=''
                iconSrc={'assets/img/icons/dash1.svg'}
                value={dataTotal?.data?.selled?.[0]?.SumaTotal ?? 'Cargando...'}
                duration={3}
                label={'Total Ventas'} />
            <DashCountWidget
                classN='dash1'
                iconSrc={'assets/img/icons/dash2.svg'}
                value={dataTotal?.data?.selledC?.[0]?.SumaTotal ?? 'Cargando...'}
                duration={3}
                label={'Total Ventas Tarjeta'} />
            <DashCountWidget
                classN='dash1'
                iconSrc={'assets/img/icons/dash2.svg'}
                value={dataTotal?.data?.selledE?.[0]?.SumaTotal ?? 'Cargando...'}
                duration={3}
                label={'Total Ventas Efectivo'} />
            <DashCountWidget
                classN='dash2'
                iconSrc={'assets/img/icons/dash3.svg'}
                value={dataTotalWithdraw?.withdraw?.[0]?.TOTAL ?? 'Cargando...'}
                duration={3}
                label={'Total Expense Amount'} />
            <DashCount
                title={'Crear'}
                subtitle={'Venta Manual'}
                iconType={'button'}
                className={'das3'}
                onButtonClick={handleShowModal}
            />
            <DashCount
                title={dataTotal?.data?.quantityC?.[0]?.Quantity ?? 'Cargando...'}
                subtitle={'Ventas con tarjeta'}
                iconType={'user'}
                className={''}
            />
            <DashCount
                title={dataTotal?.data?.quantityE?.[0]?.Quantity ?? 'Cargando...'}
                subtitle={'Ventas con efectivo'}
                iconType={'userCheck'}
                className={'das1'}
            />
            <DashCount
                title={dataTotalWithdraw?.quantity?.[0]?.Quantity ?? 'Cargando...'}
                subtitle={'Retiros'}
                iconType={'image'}
                iconSrc={'assets/img/icons/file-text-icon-01.svg'}
                className={'das2'}
            />
            <NewSaleModal visible={modalVisible} onClose={handleCloseModal} />
        </div>
    );
}


//export default CashCountInfoSales;