import React, { useState, useEffect } from "react";
import Breadcrumbs from "../../core/breadcrumbs";
import { Filter, Search, Sliders } from "react-feather";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import Select from "react-select";
import { Link } from "react-router-dom";
//import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//import { Archive, Box, Calendar, User } from "react-feather";
import { Archive, Box } from "react-feather";
import ManageStockModal from "../../core/modals/stocks/managestockModal";
import { Edit, Trash2 } from "react-feather";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Table from "../../core/pagination/datatable";
import { useSelector, useDispatch } from "react-redux";
import { getItems } from "../../core/redux/action";

const environment = process.env.REACT_APP_NODE_ENV || 'development'
const apiUrl = environment === 'production' ? process.env.REACT_APP_API_EXT : process.env.REACT_APP_API_LOC;

const Manageprice = () => { 
  // const data = useSelector((state) => state.managestockdata);


  const dispatch = useDispatch();
  const data = useSelector((state) => state.items); // Acceder a los datos desde el estado global

  const getItemsFromServer = () => {
    dispatch(getItems()); // Llamar a la acción para obtener los ítems
  };

  useEffect(() => {
    // Esta función se ejecutará cuando el componente se monte por primera vez
    getItemsFromServer();
  }, []); // El arreglo vacío asegura que este efecto se ejecute solo una vez al montar el componente


  const [isFilterVisible, setIsFilterVisible] = useState(false);
//  const [selectedDate, setSelectedDate] = useState(null);

  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };
  // const handleDateChange = (date) => {
  //   setSelectedDate(date);
  // };

  // const getItems = () => {
  //   console.log('vamos a imprimir')
  //   axios.get('http://localhost:3001/getItems')
  //     .then(function (response) {
  //       // handle success
  //       console.log(response);
  //     })
  //     .catch(function (error) {
  //       // handle error
  //       console.log(error);
  //     })
  //     .finally(function () {
  //       // always executed
  //     });
  // }

  // useEffect(() => {
  //   getItems(); // Llamar a la API cuando el componente se monte
  // }, []);

  const options = [
    { value: "sortByDate", label: "Sort by Date" },
    { value: "140923", label: "14 09 23" },
    { value: "110923", label: "11 09 23" },
  ];

  const warehouseOptions = [
    { value: "Choose Warehouse", label: "Choose Warehouse" },
    { value: "Lobar Handy", label: "Lobar Handy" },
    { value: "Quaint Warehouse", label: "Quaint Warehouse" },
    { value: "Traditional Warehouse", label: "Traditional Warehouse" },
    { value: "Cool Warehouse", label: "Cool Warehouse" },
  ];

  const productOptions = [
    { value: "Choose Product", label: "Choose Product" },
    { value: "Nike Jordan", label: "Nike Jordan" },
    { value: "Apple Series 5 Watch", label: "Apple Series 5 Watch" },
    { value: "Amazon Echo Dot", label: "Amazon Echo Dot" },
    { value: "Lobar Handy", label: "Lobar Handy" },
  ];

  // const personOptions = [
  //   { value: "Choose Person", label: "Choose Person" },
  //   { value: "Steven", label: "Steven" },
  //   { value: "Gravely", label: "Gravely" },
  // ];
  //const baseUrl = 'http://thoro-ent.dyndns.info/empleados/imagenes/ITEMS/';
  //const imageUrl = record.PicturName;
  
  // Asegurarse de que no haya barras diagonales extra al concatenar
  //const fullUrl = `${baseUrl}${imageUrl}`;
  
  const columns = [
    {
      title: "Código",
      dataIndex: "ItemCode",
      sorter: (a, b) => a.ItemCode.length - b.ItemCode.length,
    },
    {
      title: "Imagen",
      dataIndex: "PicturName",
      render: (text, record) => (
        <span className="userimgname" style={{width:100 + 'px'}}>
          <Link to={`${apiUrl}/proxy/empleados/imagenes/ITEMS/${record.PicturName}`} className="product-img">
            <img alt="img" src={`${apiUrl}/proxy/empleados/imagenes/ITEMS/${record.PicturName}`}/>
          </Link> 
        </span>
      ),
      sorter: (a, b) => a.PicturName.length - b.PicturName.length,
    },
    {
      title: "Producto",
      dataIndex: "Product",
      render: (text, record) => (
        <span className="userimgname">
          <Link to="#">{record.ItemName}</Link>
        </span>
      ),
      sorter: (a, b) => a.ItemName.length - b.ItemName.length,
    },

    {
      title: "Stock",
      dataIndex: "OnHand",
      render: (text, record) => (
        <span>
          {parseInt(record.OnHand)}
        </span>
      ),
      sorter: (a, b) => a.OnHand.length - b.OnHand.length,
    },
    {
      title: "Código de barras",
      dataIndex: "CodeBars",
      sorter: (a, b) => a.CodeBars.length - b.CodeBars.length,
    },

    {
      title: "Precio",
      dataIndex: "AvgPrice",
      render: (text, record) => (
        <span>
          {parseFloat(record.AvgPrice).toFixed(2)}
        </span>
      ),
      sorter: (a, b) => a.AvgPrice.length - b.AvgPrice.length,
    },

    {
      title: "Costo",
      dataIndex: "AvgPrice",
      render: (text, record) => (
        <span>
          {(parseFloat(record.AvgPrice * 1.16).toFixed(2))}
        </span>
      ),
      sorter: (a, b) => a.AvgPrice.length - b.AvgPrice.length,
    },

    {
      title: "Acción",
      dataIndex: "action",
      render: () => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <div className="input-block add-lists"></div>

            <Link
              className="me-2 p-2"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#edit-units"
            >
              <Edit className="feather-edit" />
            </Link>

            <Link
              className="confirm-text p-2"
              to="#"
              onClick={showConfirmationAlert}
            >
              <Trash2 className="feather-trash-2" />
            </Link>
          </div>
        </div>
      ),
      sorter: (a, b) => a.createdby.length - b.createdby.length,
    },
  ];

  const MySwal = withReactContent(Swal);

  const showConfirmationAlert = () => {
    MySwal.fire({
      title: "¿Estas seguro?",
      text: "¡No podras revertir el cambio!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Si",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        MySwal.fire({
          title: "¡Borrado!",
          text: "El producto fue borrado.",
          className: "btn btn-success",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      } else {
        MySwal.close();
      }
    });
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <Breadcrumbs
          maintitle="Administrador de stock"
          subtitle="Consulta y edita el stock"
          addButton="Agregar nuevo producto"
        />
        {/* /product list */}
        <div className="card table-list-card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-input">
                  <input
                    type="text"
                    placeholder="Buscar"
                    className="form-control form-control-sm formsearch"
                  />
                  <Link to className="btn btn-searchset">
                    <Search className="feather-search" />
                  </Link>
                </div>
              </div>
              <div className="search-path">
                <Link
                  className={`btn btn-filter ${isFilterVisible ? "setclose" : ""
                    }`}
                  id="filter_search"
                >
                  <Filter
                    className="filter-icon"
                    onClick={toggleFilterVisibility}
                  />
                  <span onClick={toggleFilterVisibility}>
                    <ImageWithBasePath
                      src="assets/img/icons/closes.svg"
                      alt="img"
                    />
                  </span>
                </Link>
              </div>
              <div className="form-sort stylewidth" style={{width:200 + 'px'}}>
                <Sliders className="info-img" />
                <Select
                  classNamePrefix="react-select"
                  className="img-select"
                  options={options}
                  placeholder="Filtrar por fecha"
                />
              </div>

            </div>
            {/* /Filter */}
            <div
              className={`card${isFilterVisible ? " visible" : ""}`}
              id="filter_inputs"
              style={{ display: isFilterVisible ? "block" : "none" }}
            >
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="input-blocks">
                      <Archive className="info-img" />
                      <Select
                        className="img-select"
                        classNamePrefix="react-select"
                        options={warehouseOptions}
                        placeholder="Filtrar codigo"
                      />
                    </div>
                  </div>
                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="input-blocks">
                      <Box className="info-img" />
                      <Select
                        className="img-select"
                        classNamePrefix="react-select"
                        options={productOptions}
                        placeholder="Filtrar producto"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-6 col-12 ms-auto">
                    <div className="input-blocks">
                      <a className="btn btn-filters ms-auto">
                        {" "}
                        <i
                          data-feather="search"
                          className="feather-search"
                        />{" "}
                        Buscar{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Filter */}
            <div className="table-responsive">
              <Table
                className="table datanew"
                columns={columns}
                dataSource={data}
                rowKey={(record) => record.id}
              // pagination={true}
              />
            </div>
          </div>
        </div>
        {/* /product list */}
      </div>
      <ManageStockModal />
    </div>
  );
};

export default Manageprice;
