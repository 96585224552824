import { Result } from 'antd'
import React from 'react'

export default function Ready() {
	return (
		<Result
			status='success'
			title='¡Terminal configurada exitosamente!'
			subTitle='Login y obtención de llaves RSA exitoso'
		/>
	)
}
