import React, { useContext } from 'react'
import { CouponsContext } from '../provider/Coupons'
import CouponsFormTransferApplicability from '../components/CouponsFormTransferApplicability'

export default function CouponsFormApplicabilitiesItems() {
	const {
		getItems,
		items,
		applicabilityItems,
		updateApplicabilityItems,
		dataApplicabilityItems,
		updateDataApplicabilityItems,
		isLoadingItems,
		errorItems,
		isApplicableItems,
		updateIsApplicableItems,
		dataUpdateFormatCoupon,
	} = useContext(CouponsContext)

	return (
		<CouponsFormTransferApplicability
			key={'itemTransfer'}
			get={getItems}
			list={items}
			title='Productos'
			name='items'
			keyProp='ItemCode'
			titleProp='Title'
			entityText='producto'
			applicability={applicabilityItems}
			updateApplicability={updateApplicabilityItems}
			dataApplicability={dataApplicabilityItems}
			updateDataApplicability={updateDataApplicabilityItems}
			isLoading={isLoadingItems}
			error={errorItems}
			isApplicable={isApplicableItems}
			updateIsApplicable={updateIsApplicableItems}
			dataUpdateFormatCoupon={dataUpdateFormatCoupon}
			keyList='items'
		/>
	)
}
