import React from 'react'
import { useSelector } from 'react-redux'
import { selectCart } from '../../../redux/selectors/cart'
import CartItem from '../components/CartItem'

export default function Cart() {
	const cart = useSelector(selectCart)

	return (
		<div className='product-wrap'>
			{cart.map((item, index) => (
				<CartItem
					key={index}
					Index={index}
					ItemCode={item.ItemCode}
					ItemName={item.ItemName}
					AvgPrice={item.AvgPrice}
					AvgPriceLiquidation={item.AvgPriceLiquidation}
					ItemQuantity={item.quantity}
					PictureName={item.PicturName}
					item={item}
				/>
			))}
		</div>
	)
}
